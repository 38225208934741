import {
  Button,
  Card,
  CardActions,
  CardContent,
  Alert,
  Divider,
  Grid,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import React, { useState } from "react";
import { Add, Dangerous, Edit, Search } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import Dialogs from "../controller/Dialogs";
import ErrorConnection from "../../outher/ErrorConnection";
import InputAchat from "./InputAchat";
import InputFraude from "./InputFraude";
import useAchat from "../hooks/achat/useAchat";
var Spink = require("react-spinkit");

function Achat() {
  document.title = "Achat marchandise| EFFICACITY SYSTEM";
  const [page, setPage] = useState(1);
  const itemsPerPage = 2; // Nombre d'éléments par page
  const {
    data,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
    setDataUpdate,
    opendialiguex,
    setOpenDialoguex,
    etat,
    etatcheckconn,
    getDataVenteProduct,
  } = useAchat();

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Achat");
  };

  const nouveauVictiomex = () => {
    setDataUpdate("");
    setOpenDialoguex(!opendialigue);
    setTitle("Enregitrement");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculer les données à afficher pour la page courante
  const paginatedData = data.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Patienter</Typography>
        </Stack>
      ) : (
        <div>
          <Stack
            sx={{
              bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <div>
                <Boutons
                  onClick={() => nouveauVictiomex()}
                  icons={
                    <Add
                      sx={{
                        mr: 2,
                      }}
                    />
                  }
                  title="Réception du colis"
                />
              </div>
              <div>
                <Alert
                  icon={false}
                  severity="info"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Total : {data.length}
                </Alert>
              </div>
            </Stack>
            <Stack>
              <div style={{ margin: 10 }}>
                <TextFields
                  value={searchQuery}
                  onChange={(e) => Recherche(e)}
                  icons={
                    <Search
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                    />
                  }
                  placeholder="Rechercher"
                />
              </div>
            </Stack>
            {/**
             * <Mtables title="Agent du gouvernement" data={data} columns={column} />
             */}
            <Grid container justifyContent={"center"}>
              {!(data || []).length ? (
                <div>
                  <Typography align="center">
                    aucune activité n'a été enregistrée pour instant
                  </Typography>
                </div>
              ) : (
                paginatedData
                  .filter((itemx) =>
                    searchQuery.toUpperCase() === null ||
                    searchQuery.toUpperCase() === "" ||
                    searchQuery.toUpperCase() === undefined
                      ? itemx
                      : itemx?.tproduction?.tpontsiteminetypemine?.tminerai?.designation
                          .toUpperCase()
                          .toUpperCase()
                          .indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.tproduction?.tpontsiteminetypemine?.tminerai?.designation
                          .toUpperCase()
                          .indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.tages.indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.tages.indexOf(searchQuery.toUpperCase()) > -1
                  )
                  .map((e) => (
                    <Card sx={{ minWidth: 400, m: 1 }}>
                      <Stack flexDirection={"row"} justifyContent={"end"}>
                        {e?.alertediplication ? (
                          <Alert
                            icon={
                              <Dangerous
                                sx={{
                                  // fontSize: 50,
                                  color: "red",
                                }}
                              />
                            }
                            severity="error"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Fraude
                          </Alert>
                        ) : (
                          ""
                        )}
                      </Stack>
                      <CardContent>
                        <Stack>
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              marginTop: -10,
                              textDecoration: "underLine",
                            }}
                          >
                            Site minier:
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textAlign: "Justify",
                              color: "blue",
                            }}
                          >
                            {e?.tproduction?.tpontsiteminetypemine?.templacement?.designationsite.toUpperCase()}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textAlign: "justify",
                            }}
                          >
                            Date:{`${e?.datacreate}`}
                          </Typography>
                          <Divider style={{ margin: 10 }} />
                        </Stack>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 18,
                                fontWeight: "bolder",
                                // textAlign: "center",
                                textDecoration: "underLine",
                              }}
                            >
                              Minerais:
                            </Typography>
                            <Typography
                              fontWeight={"bolder"}
                              fontSize={16}
                              color={"Blue"}
                              marginBottom={2}
                            >
                              {e?.tproduction?.tpontsiteminetypemine?.tminerai?.designation.toUpperCase()}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 16,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                textDecoration: "underLine",
                              }}
                            >
                              Nom du puit:
                            </Typography>
                            <Typography
                              fontWeight={"bolder"}
                              gutterBottom
                              sx={{
                                color: "blue",
                                fontSize: 14,
                                // mt: 1,
                              }}
                            >
                              {e?.tproduction?.nompuit}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Divider />
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: "bolder",
                            textAlign: "justify",
                            textDecoration: "underLine",
                          }}
                        >
                          Tag Negociant:
                        </Typography>
                        <Typography
                          textAlign={"justify"}
                          fontWeight={"bolder"}
                          color={"Blue"}
                          // mt={1}
                          fontSize={15}
                        >
                          {e?.tages}
                        </Typography>

                        <Stack
                          flexDirection={"column"}
                          sx={{
                            bgcolor: "#F0F0F0",
                            p: 0.5,
                            borderRadius: 1,
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              textDecoration: "underLine",
                            }}
                          >
                            Quantité Vendue:
                          </Typography>
                          <Typography
                            textAlign={"justify"}
                            fontWeight={"bolder"}
                            color={"Blue"}
                            // mt={1}
                            fontSize={15}
                          >
                            {e?.quantitevendu}Kg
                          </Typography>
                        </Stack>
                        {/*
                          <Typography
                          sx={{
                            color: "red",
                            p: 0.5,
                            borderRadius: 1,
                            fontWeight: "bolder",
                            fontSize: 16,
                          }}
                        >
                          {e?.prixvente}USD
                        </Typography>
                          */}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => Update(e)}
                        >
                          Vendre cette Production
                        </Button>
                      </CardActions>
                    </Card>
                  ))
              )}
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Stack>
        </div>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="sm"
      >
        <InputAchat
          title={title}
          dataUpdate={dataupdate}
          foncget={getDataVenteProduct}
        />
      </Dialogs>
      <Dialogs
        title={title}
        open={opendialiguex}
        setOpen={setOpenDialoguex}
        size="sm"
      >
        <InputFraude
          title={title}
          dataUpdate={dataupdate}
          label={"Entité de traitement"}
        />
      </Dialogs>
    </div>
  );
}

export default Achat;
