import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
import UploadAndDisplayImage from "../controller/UploadImage";
import { requestFD } from "../url/requesFD";
import UploadAndDisplayImage2 from "../controller/UploaderImageDeux";
var Spinkit = require("react-spinkit");

function InputVente({ foncget, dataUpdate, title, foncget2 }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { UploadImage: UploadImage2, selectedImage: selectedImage2 } =
    UploadAndDisplayImage2();
  const [datasite, setDataSite] = React.useState([]);
  const [labelSite, setLabelSite] = React.useState("");
  const [imgageseamap, setImageSAMAP] = React.useState(false);
  const [imgagedivimine, setDivimine] = React.useState(false);

  const datax = JSON.parse(localStorage.getItem("data"));
  //   console.log(datax.agent.idagent);

  //choix de la selection
  const getRadioImageSEAMAP = () => {
    setImageSAMAP(!imgageseamap);
    // console.log(e.target.value);
  };
  const getRadioImageDivimine = () => {
    setDivimine(!imgagedivimine);
  };

  useEffect(() => {}, [imgageseamap]);

  //get emplacement
  const getNegociant = () => {
    request
      .get("negociant")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idnegociant,
            label: ex?.tpersonne?.prenom + " " + ex?.tpersonne?.postnom,
          };
        });
        setDataSite(itm);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          // setEtat(false);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getNegociant();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      idemplacement: labelSite.value
        ? labelSite.value
        : dataUpdate?.templacement?.idemplacement,
      //    dataUpdate?.templacement?.idemplacement,

      nompuit: title === "Modification" ? dataUpdate?.prixvente : "",
      quantitebruite: title === "Modification" ? dataUpdate?.quantitevendu : "",
      taget: title === "Modification" ? dataUpdate?.tages : "",
      idpersonne: "",
    },

    validationSchema: Yup.object({
      taget: Yup.string().required("la taget est obligatoire"),
      quantitebruite: Yup.string().required("la quantité est obligatoire"),
      nompuit: Yup.string().required("le prix est obligatoire"),
    }),
    onSubmit: (values) => {
      const formdata = new FormData();
      if (title === "Modification") {
        // console.log("je passe a la modification");
        //modification
        if (labelSite === "" || labelSite === null || labelSite === undefined) {
          NotifyError("selectionee le negociant");
        } else {
          setEtat(true);
          formdata.append("idnegociant", labelSite.value);
          formdata.append("tages", values.taget);
          formdata.append("prixvente", values.nompuit);
          formdata.append("quantitevendu", values.quantitebruite);
          formdata.append("file", selectedImage);
          formdata.append("file", selectedImage2);
          formdata.append("idcentrenegoce", dataUpdate?.idcentrenegoce);

          requestFD
            .put("vente/update", formdata)
            .then((e) => {
              if (e.status === 200) {
                foncget2();
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        }
      } else {
        // enregistrement;
        console.log(dataUpdate?.idproduction);
        if (labelSite === "" || labelSite === null || labelSite === undefined) {
          NotifyError("selectionee le negociant");
        } else {
          setEtat(true);
          formdata.append("idpersonne", datax.agent.idagent); //datax.agent.idagent
          formdata.append("idproduction", dataUpdate?.idproduction);
          formdata.append("idnegociant", labelSite.value);
          formdata.append("tages", values.taget);
          formdata.append(
            "nommine",
            dataUpdate?.tpontsiteminetypemine?.tminerai?.designation
          );
          formdata.append(
            "nomsitemine",
            dataUpdate?.tpontsiteminetypemine?.templacement?.designationsite
          );
          formdata.append("prixvente", values.nompuit);
          formdata.append("quantitevendu", values.quantitebruite);
          formdata.append("file", selectedImage);
          formdata.append("file", selectedImage2);
          requestFD
            .post("vente/save", formdata)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Grid container m={2}>
          <Grid item xs={"auto"} md={6} sm={"auto"} lg={6} mr={3}>
            <Comboboxs
              data={datasite}
              value={labelSite}
              onChange={(e) => setLabelSite(e)}
              placeholder="Négociant"
            />

            <div>
              <TextFields
                // label="Nom"
                placeholder="Tag Negociant"
                id={"taget"}
                onBlur={validation.handleBlur}
                value={validation.values.taget}
                onChange={validation.handleChange}
                error={
                  validation.touched.taget && validation.errors.taget
                    ? true
                    : false
                }
              />
              {validation.touched.taget && validation.errors.taget ? (
                <h6
                  style={{
                    marginTop: -2,
                    color: "red",
                    // marginLeft: "30%",
                    marginBottom: -5,
                  }}
                >
                  {validation.errors.taget}
                </h6>
              ) : null}
            </div>

            <div>
              <TextFields
                type={"number"}
                placeholder="Poids du colis"
                id={"quantitebruite"}
                onBlur={validation.handleBlur}
                value={validation.values.quantitebruite}
                onChange={validation.handleChange}
                error={
                  validation.touched.quantitebruite &&
                  validation.errors.quantitebruite
                    ? true
                    : false
                }
              />
              {validation.touched.quantitebruite &&
              validation.errors.quantitebruite ? (
                <h6
                  style={{
                    marginTop: -2,
                    color: "red",
                    // marginLeft: "30%",
                    marginBottom: -5,
                  }}
                >
                  {validation.errors.quantitebruite}
                </h6>
              ) : null}
            </div>
            <div>
              <TextFields
                type={"number"}
                placeholder="Prix de vente en USD $"
                id={"nompuit"}
                onBlur={validation.handleBlur}
                value={validation.values.nompuit}
                onChange={validation.handleChange}
                error={
                  validation.touched.nompuit && validation.errors.nompuit
                    ? true
                    : false
                }
              />
              {validation.touched.nompuit && validation.errors.nompuit ? (
                <h6
                  style={{
                    marginTop: -2,
                    color: "red",
                    // marginLeft: "30%",
                    marginBottom: -5,
                  }}
                >
                  {validation.errors.nompuit}
                </h6>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={"auto"} md={5} sm={"auto"} lg={5} mt={-1}>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Typography>Avez-vous le document delivré par le CEEC?</Typography>
              <FormControl
                component={"fieldset"}
                sx={{
                  flexDirection: "row",
                }}
              >
                <RadioGroup
                  sx={{
                    flexDirection: "row",
                  }}
                  value={imgageseamap}
                  onChange={getRadioImageSEAMAP}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
              {!imgageseamap === true ? (
                ""
              ) : (
                <Stack
                  sx={{
                    mt: 5,
                    mb: 1,
                  }}
                  // justifyContent={"center"}
                  // alignItems={"center"}
                >
                  {UploadImage()}
                </Stack>
              )}

              <div style={{ marginTop: 10 }}>
                <Typography>Avez-vous le document delivré par le SAEMAPE</Typography>
                <FormControl component={"fieldset"}>
                  <RadioGroup
                    value={imgagedivimine}
                    onChange={getRadioImageDivimine}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Non"
                    />
                  </RadioGroup>
                </FormControl>
                {imgagedivimine === false ? (
                  ""
                ) : (
                  <Stack
                    sx={{
                      mt: 5,
                      mb: 1,
                    }}
                    // justifyContent={"center"}
                    // alignItems={"center"}
                  >
                    {UploadImage2()}
                  </Stack>
                )}
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                width: "100%",
              }}
            >
              <Boutons
                type={"submit"}
                width={"100%"}
                icons={
                  etat === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title={title === "Vente" ? "Enregistrer" : "Modification"}
                disable={etat}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default InputVente;
