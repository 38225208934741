import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MiniDrawer from "../navigation/Drawers";
import Boutons from "../controller/Boutons";
import { useReactToPrint } from "react-to-print";

function ImprimerFraude() {
  const { item } = useLocation().state;
  const nav = useNavigate();
  const prints = useRef();
  const datex = new Date();

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: "liste victime",
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div>
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
              width: "98%",
            }}
          >
            Fraude
          </h4>
        </div>

        <Box ref={prints} sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

          <div
            style={{
              borderWidth: 2,
              borderColor: "blue",
              margin: 10,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Structure de production
            </Typography>
            <div
              style={{
                padding: 10,
                background: "red",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "bold",
                }}
              >
                {`${item?.typedaction.toUpperCase()} : ${item?.structure.toUpperCase()}`}
              </Typography>
            </div>
          </div>

          <div
            style={{
              borderWidth: 2,
              borderColor: "blue",
              margin: 10,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Apporteur de la marchandise
            </Typography>
            <div
              style={{
                padding: 10,
                background: "#819830",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                {`${item?.typedesignation.toUpperCase()} : ${item?.designation.toUpperCase()}`}
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "bold",
                }}
              >
                {`Minerai : ${item?.minerai.toUpperCase()}`}
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "bold",
                }}
              >
                {`Poinds : ${item?.quantite} KG`}
              </Typography>
            </div>
          </div>
          <Typography
            sx={{
              fontSize: 18,
              textDecoration: "underline",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Fraude Attestant
          </Typography>

          <Typography
            sx={{
              m: 5,
              textAlign: "justify",
            }}
          >
            Nous Equipe de fraude, nous attestons que les information ci-dessus
            sont approuvés est validé
          </Typography>

          <Stack
            direction={"row"}
            sx={{
              m: 5,
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Typography
                fontWeight={"bold"}
                mt={5}
                sx={{
                  textDecoration: "underline",
                }}
              >
                Charge de Fraude
              </Typography>
              <Typography
                sx={{
                  mt: 10,
                  fontWeight: "bold",
                }}
              >
                Fait à Goma, le{" "}
                {`${datex.getDate()}-${
                  datex.getMonth() + 1
                }-${datex.getFullYear()}  ${datex.getHours()}:${datex.getMinutes()} `}
              </Typography>
            </div>
          </Stack>
        </Box>
        <Stack
          direction={"row"}
          sx={{
            m: 5,
            justifyContent: "flex-end",
          }}
        >
          <Boutons title={"Imprimer"} onClick={() => Imprimer()} />
        </Stack>
      </Box>
    </Box>
  );
}

export default ImprimerFraude;
