import {
  Alert,
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Edit,
  Error,
  ModeOfTravelOutlined,
  Search,
  UpdateOutlined,
  Verified,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import InputVictime from "./InputVictime";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Identité
      </Typography>
    ),
    selector: (row) => row.identite,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Genre
      </Typography>
    ),
    selector: (row) => row.genre,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Téléphone
      </Typography>
    ),
    selector: (row) => row.phone,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Validiter
      </Typography>
    ),
    // selector: (row) => row.etat_vulnerable,
    sortable: true,
    cell: (row) => {
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            p: 1,
          }}
        >
          {row.etat === true ? (
            <Alert icon={<Verified />} severity="success">
              Verifier
            </Alert>
          ) : (
            <Alert icon={<Error />} severity="warning">
              Non verifier
            </Alert>
          )}
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Victime() {
  document.title = "Victime | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();

  const [etatv, setEtatV] = React.useState(false);
  const [etat, setEtat] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
    // console.log(e);
    // getDataVunerable(e.target.value);
  };

  const getDataVunerable = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("vulnerable")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.map((e) => {
          // console.log(e);
          return {
            identite:
              e?.tpersonne?.nom.toUpperCase() +
              " " +
              e?.tpersonne?.prenom.toUpperCase() +
              " " +
              e?.tpersonne?.postnom.toUpperCase(),
            genre: e?.tpersonne?.genre.toUpperCase(),
            phone: e?.tpersonne?.phone,
            // typevlne: e.ttypevulnerabilite.designationv,
            // description: e.designationvul,
            // emplacement: e.templacement.designationsite,
            etat: e?.etat_vulnerable,
            date: e?.tpersonne?.datecreate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => Update(e)}
                >
                  <Edit fontSize="medium" />
                </IconButton>
                <IconButton
                  size="medium"
                  color="success"
                  onClick={() =>
                    nav("/biographie", {
                      state: {
                        itemv: e,
                      },
                    })
                  }
                >
                  <Visibility fontSize="medium" />
                </IconButton>

                <IconButton
                  size="medium"
                  color="warning"
                  onClick={() => ConfirmInformation(e)}
                >
                  <UpdateOutlined fontSize="medium" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  const dataAfterFielter = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.identite &&
        itemx.identite.toUpperCase().includes(searchQuery.toUpperCase())
  );
  React.useEffect(() => {
    getDataVunerable();
  }, []);

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Nouvel enregistrement");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification du risque associé");
  };

  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("vulnerable/valider", {
        idvulnerable: dataupdate.idvulnerable,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getDataVunerable();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          // right: 10,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
            width: "98%",
          }}
        >
          Individus
        </h4>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Patientez</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              // bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <div
              style={{
                marginLeft: -20,
                // position: "absolute",
                background: "#FFF",
                marginTop: -20,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  padding: 20,
                }}
              >
                <Boutons
                  onClick={() => nouveauVictiome()}
                  icons={
                    <Add
                      sx={{
                        mr: 2,
                      }}
                    />
                  }
                  title="Nouveau"
                />
              </div>
              {etatv === false ? (
                ""
              ) : (
                <Stack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E3EAEF",
                    marginTop: 20,
                    borderRadius: 5,
                    margin: 10,
                  }}
                >
                  <Typography fontWeight={"bold"} fontSize={20}>
                    Information
                  </Typography>
                  <Divider />
                  <Typography fontWeight={"bold"} fontSize={16}>
                    Etes-vous sur de valider ?
                  </Typography>
                  <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                    <IconButton
                      sx={{
                        bgcolor: "#013266",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => ValidationDataUpdateEtat()}
                    >
                      <Check
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: "red",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => setEtatV(!etatv)}
                    >
                      <Close
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  mt: -10,
                  mb: -2,
                }}
              >
                <h1></h1>
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  <TextFields
                    value={searchQuery}
                    onChange={(e) => {
                      Recherche(e);
                    }}
                    icons={
                      <Search
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                      />
                    }
                    placeholder="search..."
                  />
                </div>
              </Stack>

              <Mtables
                title="Liste des individus"
                data={dataAfterFielter}
                columns={column}
              />
            </div>
          </Stack>
        )}

        <Dialogs
          title={title}
          open={opendialigue}
          setOpen={setOpenDialogue}
          size="md"
        >
          <InputVictime dataUpdate={dataupdate} fonct={getDataVunerable} />
        </Dialogs>
      </Box>
    </Box>
  );
}

export default Victime;
