import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MiniDrawer from "../navigation/Drawers";
import NotifySuccess from "../Notification/NotifySuccess";
import { DataThresholding, Person } from "@mui/icons-material";
import { request, request2 } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { Chart } from "react-google-charts";
import Mtables from "../controller/Mtables";
import ErrorConnection from "../../outher/ErrorConnection";
import Comboboxs from "../controller/Comboboxs";
import StatProduction from "./StatProduction";
import StatMinerai from "./StatMinerai";
import StatFraude from "./StatFraude";
var Spinkit = require("react-spinkit");

const data1 = [
  // ["Evenement", "Mesure"],
  ["Description", "Totalité"],
  ["Evenement", 3],
  ["Mesure", 15],
];

const dataSelectDay = [
  {
    value: "o",
    label: "Aucune",
  },
  {
    value: "m",
    label: "Mensuel",
  },
  {
    value: "i",
    label: "Période",
  },
  {
    value: "a",
    label: "Annuel",
  },
];

const options1 = {
  title: "Analyse sur la totalité des Mesures et d'Evenements associés aux risques",
  chartArea: { width: "50%" },
  colors: ["#3366CC", "#409EFF"],
  hAxis: {
    title: "Evènements et mesures d'attenuations",
    minValue: 0,
  },
  vAxis: {
    title: "Evenements et Mesures",
  },
};

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Identité
      </Typography>
    ),
    selector: (row) => row.identite,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Genre
      </Typography>
    ),
    selector: (row) => row.genre,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Phone
      </Typography>
    ),
    selector: (row) => row.phone,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Image
      </Typography>
    ),
    // selector: (row) => row.etat_vulnerable,
    sortable: true,
    cell: (row) => {
      let img = "http://localhost:8888/" + row.image;
      return (
        <Stack direction={"row"} spacing={1}>
          <Avatar src={img} />
        </Stack>
      );
    },
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
];

function DashBoard() {
  document.title = "Dashbaord | EFFICACITY SYSTEM";
  const [valuueNomberEvement, setValuueNomberEvenement] = React.useState("");
  const [valuueNomberEvementActive, setValuueNomberEvenementActive] =
    React.useState("");
  const [valueAgent, setValueAgen] = React.useState("");
  const [valuueVulnerable, setValueVulnerable] = React.useState("");
  const [valuueVulnerableactive, setValueVulnerableActive] = React.useState("");
  const [valuueMesure, setValuMesure] = React.useState("");
  const [valuueMesureactive, setValuMesureActive] = React.useState("");
  const [etat, setEtat] = React.useState(false);
  const [data2, setData] = React.useState([]);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [totalMine, setTotalMine] = React.useState(0);
  const [charAn, setCharAn] = React.useState(data1);
  const [check, setCheck] = useState(false);
  const [select, setSelect] = useState("");
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [date4, setDate4] = useState();
  const [dataevenement, setDataEvenemet] = React.useState([]);
  const [valueEvenement, setValueEvenemet] = React.useState("");


const actionSelect = (e) => {
  const updatedValueEvenement = e; // Stocker la nouvelle valeur avant de la définir dans l'état
  setCharAn([
    ["Description", "Totalité"],
    ["Evenement", 0],
    ["Mesure", 0],
  ]);

  // Met à jour immédiatement la valeur de l'événement
  setValueEvenemet(updatedValueEvenement);

  if (select.value === "a") {
    const newDate = new Date(date1?.target.value);
    
    request
      .post("/states", {
        periode: select.value,
        year1: newDate.getFullYear(),
        fk_idtypevulnerables: updatedValueEvenement.value, // Utilise la valeur mise à jour
      })
      .then((response) => {
        setCharAn([
          ["Description", "Totalité", { role: "style" }],
          ["Evenement", response.data?.dataev, "red"],
          ["Mesure", response.data?.datarisque, "#61020F"],
        ]);
      })
      .catch((error) => {
        console.log(error.response);
        NotifyError(error.response?.data?.message || error.message);
      });
  }
  
  if (select.value === "m") {
    const newDate = new Date(date2?.target.value);
    
    request
      .post("/states", {
        periode: select.value,
        datex: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
        fk_idtypevulnerables: updatedValueEvenement.value, // Utilise la valeur mise à jour
      })
      .then((response) => {
        setCharAn([
          ["Description", "Totalité", { role: "style" }],
          ["Evenement", response.data?.dataev, "red"],
          ["Mesure", response.data?.datarisque, "#61020F"],
        ]);
      })
      .catch((error) => {
        console.log(error.response?.data);
        NotifyError(error.response?.data?.message || error.message);
      });
  }
  
  if (select.value === "i") {
    request
      .post("/states", {
        periode: select.value,
        date1: date3?.target.value,
        date2: date4?.target.value,
        fk_idtypevulnerables: updatedValueEvenement.value, // Utilise la valeur mise à jour
      })
      .then((response) => {
        setCharAn([
          ["Description", "Totalité", { role: "style" }],
          ["Evenement", response.data?.dataev, "red"],
          ["Mesure", response.data?.datarisque, "#61020F"],
        ]);
      })
      .catch((error) => {
        console.log(error);
        NotifyError(error.response?.data?.message || error.message);
      });
  }
};

  const getEvenement = () => {
    request
      .get("typevunerabilite/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          return {
            value: ex.idtypevulnerabilite,
            label: ex.designationv,
          };
        });
        setDataEvenemet(itm);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          // setEtat(false);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  //liste en pourcentage
  const options = {
    title: "Les proportions des types des données dans le Systeme ",
  };
  const data = [
    ["Task", "Hours per Day"],
    ["Individus", parseInt(valuueVulnerableactive)],
    ["Evenements", parseInt(valuueNomberEvementActive)],
    ["Mesures", parseInt(valuueMesureactive)],
  ];

  //get personne active
  const getDataVunerable = () => {
    // setEtat(false);
    request
      .get("vulnerable/active")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.slice(0, 10).map((e) => {
          // console.log(e);
          return {
            identite: e.tpersonne.prenom + " " + e.tpersonne.postnom,
            genre: e.tpersonne.genre,
            phone: e.tpersonne.phone,
            date: e.tpersonne.datecreate,
            image: e.tpersonne.image,
          };
        });
        setData(itm);
        // setEtat(true);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  //getTotal mine
  const getTotalMine = () => {
    // setEtat(false);
    request
      .get("mine/count")
      .then((e) => {
        setTotalMine(e.data.data);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  const getValueEvenemt = () => {
    request
      .get("evenement/count")
      .then((e) => {
        if (e.status === 200) {
          setValuueNomberEvenement(e.data.data);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };

  const getValueEvenemtActive = () => {
    request
      .get("evenement/count_active")
      .then((e) => {
        if (e.status === 200) {
          setValuueNomberEvenementActive(e.data.data);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };

  const getMesure = () => {
    // setEtat(false);
    request
      .get("mesure/count")
      .then((e) => {
        if (e.status === 200) {
          setValuMesure(e.data.data);
          // setEtat(true);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };

  const getMesureActive = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("mesure/count_active")
      .then((e) => {
        if (e.status === 200) {
          setValuMesureActive(e.data.data);
          setEtat(true);
          setEtatChecConn(false);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          setEtatChecConn(false);
        }
      });
  };

  const getVulnerable = () => {
    request
      .get("vulnerable/count")
      .then((e) => {
        if (e.status === 200) {
          setValueVulnerable(e.data.data);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };
  const getVulnerableActive = () => {
    request
      .get("vulnerable/count_active")
      .then((e) => {
        if (e.status === 200) {
          setValueVulnerableActive(e.data.data);
        }
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };

  React.useEffect(() => {
    // setEtat(false);
    getAgent();
    getMesure();
    getValueEvenemt();
    getVulnerable();
    getDataVunerable();
    getVulnerableActive();
    getValueEvenemtActive();
    getMesureActive();
    getTotalMine();
    getEvenement();
  }, []);

  const getAgent = () => {
    request
      .get("emplacement/count")
      .then((e) => {
        setValueAgen(e.data.data);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          NotifyError("Error de la connection au serveur");
          // setDisable(false);
        } else {
          NotifyError(error.response.data.message);
          // setDisable(false);
        }
      });
  };

  // NotifySuccess("connection reussie");

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        // height: "100vh",
        minHeight: "100vh",
        // flexWrap: "wrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Dashboard | IFEDD  EFFICACITY SYSTEM FRAUDE Version V.0.1
        </h4>
        <Stack
          direction={{
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          }}
          sx={{
            ml: -1,
          }}
        >
          {etatcheckconn === true ? (
            <ErrorConnection />
          ) : etat === false ? (
            <Stack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              m={2}
            >
              <Spinkit color="#409EFF" name="wave" size={50} />
              <Typography>Patientez</Typography>
            </Stack>
          ) : (
            <div style={{ width: "100%", marginTop: -20 }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    width: "100%",
                    bgcolor: "#0288D1",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#013266",
                    },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Person fontSize="large" sx={{ color: "#FFF" }} />
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#FFF",
                      }}
                    >
                     Individus
                    </Typography>
                  </Stack>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 2,
                      color: "#FFF",
                    }}
                  >
                    {valuueVulnerable}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    width: "100%",
                    bgcolor: "red",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#F16745",
                    },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Person
                      fontSize="large"
                      sx={{
                        color: "#FFF",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#FFF",
                      }}
                    >
                    Emplacements
                    </Typography>
                  </Stack>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 2,
                      color: "#FFF",
                    }}
                  >
                    {valueAgent}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    width: "100%",
                    bgcolor: "#013266",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#409EFF",
                    },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <DataThresholding fontSize="large" sx={{ color: "#FFF" }} />
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#FFF",
                      }}
                    >
                      Incidents
                    </Typography>
                  </Stack>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 2,
                      color: "#FFF",
                    }}
                  >
                    {valuueNomberEvement}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    width: "100%",
                    bgcolor: "#008000",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#C4E8AF",
                    },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <DataThresholding
                      fontSize="large"
                      sx={{
                        color: "#FFF",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#FFF",
                      }}
                    >
                      Mesures d'attenuations
                    </Typography>
                  </Stack>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 2,
                      color: "#FFF",
                    }}
                  >
                    {valuueMesure}
                  </Typography>
                </Card>

                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    width: "100%",
                    bgcolor: "#0288D1",
                    cursor: "pointer",
                    ":hover": {
                      bgcolor: "#013266",
                    },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Person fontSize="large" sx={{ color: "#FFF" }} />
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#FFF",
                      }}
                    >
                      Minerais
                    </Typography>
                  </Stack>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 30,
                      fontWeight: "bold",
                      textAlign: "center",
                      mt: 2,
                      color: "#FFF",
                    }}
                  >
                    {totalMine}
                  </Typography>
                </Card>
              </div>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // m: 3,
                  m: 2,
                }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                    }}
                  >
                    Analyse des évènements
                  </Typography>
                </div>
                <div
                // style={{
                //   width: "50%",
                // }}
                >
                  <Stack flexDirection={"row"}>
                    <input
                      type="checkbox"
                      value={check}
                      onChange={() => setCheck(!check)}
                    />
                    Filtrer
                  </Stack>
                  {check === false ? (
                    ""
                  ) : (
                    <Comboboxs
                      data={dataSelectDay}
                      value={select}
                      onChange={(e) => setSelect(e)}
                      placeholder="Selectionner"
                    />
                  )}
                  {check === false ? (
                    ""
                  ) : select.value === "i" ? (
                    <div>
                      <Stack flexDirection={"row"}>
                        <div style={{ marginRight: 10 }}>
                          <p>Date du début</p>
                          <input
                            type="date"
                            // value={data1}
                            onChange={(e) => setDate3(e)}
                            style={{
                              height: 38,
                              borderRadius: 5,
                            }}
                          />
                        </div>
                        <div style={{ marginRight: 10 }}>
                          <p>Date de Fin</p>
                          <input
                            type="date"
                            // value={data1}
                            onChange={(e) => setDate4(e)}
                            style={{
                              height: 38,
                              borderRadius: 5,
                            }}
                          />
                        </div>
                        <div>
                          <div>
                            <p
                              style={{
                                marginBottom: 8,
                              }}
                            >
                              Risques
                            </p>
                            <Comboboxs
                              data={dataevenement}
                              value={valueEvenement}
                              onChange={(e) => actionSelect(e)}
                              placeholder="Risques"
                            />
                          </div>
                        </div>
                      </Stack>
                    </div>
                  ) : select.value === "a" ? (
                    <div>
                      <Stack>
                        <div>
                          <p>Date</p>
                          <input
                            type="date"
                            // value={data1}
                            onChange={(e) => setDate1(e)}
                            style={{
                              height: 38,
                              borderRadius: 5,
                            }}
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              marginBottom: 8,
                            }}
                          >
                            Risque
                          </p>
                          <Comboboxs
                            data={dataevenement}
                            value={valueEvenement}
                            onChange={(e) => actionSelect(e)}
                            placeholder="Risque"
                          />
                        </div>
                      </Stack>
                    </div>
                  ) : select.value === "m" ? (
                    <div>
                      <Stack flexDirection={"row"}>
                        <div style={{ marginRight: 10 }}>
                          <p>Mois</p>
                          <input
                            type="date"
                            // value={data1}
                            onChange={(e) => setDate2(e)}
                            style={{
                              height: 38,
                              borderRadius: 5,
                            }}
                          />
                        </div>
                        <div>
                          <div>
                            <p
                              style={{
                                marginBottom: 8,
                              }}
                            >
                              Risques
                            </p>
                            <Comboboxs
                              data={dataevenement}
                              value={valueEvenement}
                              onChange={(e) => actionSelect(e)}
                              placeholder="Risque"
                            />
                          </div>
                        </div>
                      </Stack>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Stack>
              <Grid container spacing={2} mt={2} mb={5}>
                <Grid item sx={12} md={6} sm={12} lg={6}>
                  <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                  />
                </Grid>
                <Grid item sx={12} md={6} sm={12} lg={6}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="400px"
                    data={charAn}
                    options={options1}
                  />
                </Grid>
                <Grid container spacing={2} mt={2} mb={5}>
                  <StatProduction />
                </Grid>
                <Grid container spacing={2} mt={2} mb={5}>
                  <StatMinerai />
                </Grid>

                <Grid container spacing={2} mt={2} mb={5}>
                  <StatFraude />
                </Grid>
                <Grid
                  item
                  sx={12}
                  md={12}
                  sm={12}
                  lg={12}
                  // borderRadius={5}
                  // p={2}
                >
                  <Mtables
                    title="Liste des derniers individus enregistrés"
                    data={data2}
                    columns={column}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Stack>
      </Box>
      
    </Box>
  );
 
}

export default DashBoard;
