import React, { useState } from "react";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import NotifySuccess from "../Notification/NotifySuccess";
import { useFormik } from "formik";
import * as Yup from "yup";
import { request } from "../url/request";
import useProduction from "../hooks/production/useProduction";

function InputValiderArriveProduit({ datax, getDataVunerable }) {
  const { validationArriveProduction, etat } = useProduction();
  // console.log("==>:)", datax.taget);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantitearrive: "",
    },
    validationSchema: Yup.object({
      quantitearrive: Yup.string().required("la quantite est obligatoire"),
    }),
    onSubmit: (values) => {
      const dataxx = JSON.parse(localStorage.getItem("data"));
      const dts = {
        quantitearrive: values.quantitearrive,
        taget: datax.idproduction,
        idpersonne: dataxx.agent.idagent,
        nommine: datax?.tpontsiteminetypemine?.tminerai?.designation,
        nomsitemine: datax.nompuit,
        tag: datax.taget,
      };
      validationArriveProduction({ data: dts });
    },
  });

  return (
    <div>
      <div
        style={{
          margin: 10,
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextFields
            // label="Nom"
            placeholder="Quantite recu"
            id={"quantitearrive"}
            onBlur={validation.handleBlur}
            value={validation.values.quantitearrive}
            onChange={validation.handleChange}
            error={
              validation.touched.quantitearrive &&
              validation.errors.quantitearrive
                ? true
                : false
            }
          />
          {validation.touched.quantitearrive &&
          validation.errors.quantitearrive ? (
            <h6
              style={{
                marginTop: -2,
                color: "red",
                // marginLeft: "30%",
                marginBottom: -5,
              }}
            >
              {validation.errors.quantitearrive}
            </h6>
          ) : null}
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Boutons
              type={validation.handleSubmit}
              width={"100%"}
              title={"Enregistrer"}
              disable={etat}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default InputValiderArriveProduit;
