import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MiniDrawer from "../navigation/Drawers";
import { useLocation, useNavigate } from "react-router-dom";
import { request2 } from "../url/request";
import { ArrowBack } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import { useReactToPrint } from "react-to-print";
import Info from "../../outher/Info";

function BiographieVulne() {
  const [aff, setAff] = useState("contents");
  const localrouter = useLocation();
  const nav = useNavigate();
  const prints = useRef();

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: localrouter.state.itemv.tpersonne.nom.toUpperCase(),
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>

        <div ref={prints}>
          <Info />
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            Information de la Victime
          </h4>
          <Divider sx={{ m: 2 }} />
          <Grid
            container
            //   justifyContent={"center"}
            //   alignItems={"center"}
            sx={{
              bgcolor: "#FFF",
              p: 2,
              m: 3,
              borderRadius: 5,
              ml: -1,
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <Stack direction={"row"} spacing={5}>
                <div>
                  <Typography textAlign={"center"}>
                    <img
                      src={
                        localrouter.state.itemv.tpersonne.image
                          ? `${request2}${localrouter.state.itemv.tpersonne.image}`
                          : require("../assets/loginimg.png")
                      }
                      style={{
                        width: 250,
                        height: 250,
                        borderRadius: "100%",
                        borderColor: "black",
                        borderWidth: 5,
                      }}
                      onResize={"contain"}
                      alt="imgaeuse"
                    />
                  </Typography>
                </div>
                <Divider
                  sx={{
                    borderWidth: 2,
                  }}
                />
                <div
                  style={{
                    marginTop: 50,
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography
                      textAlign={"justify"}
                      fontSize={20}
                      fontWeight={"bold"}
                    >
                      {`${localrouter.state.itemv.tpersonne.nom.toUpperCase()} ${localrouter.state.itemv.tpersonne.postnom.toUpperCase()} ${localrouter.state.itemv.tpersonne.prenom.toUpperCase()}`}
                    </Typography>
                    {/*
                      <Stack>
                      <Typography>Etat</Typography>
                    </Stack>
                      */}
                  </Stack>

                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpersonne.genre}`}
                  </Typography>
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpersonne.phone}`}
                  </Typography>
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpersonne.datenaiss}`}
                  </Typography>
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpersonne.adresse}`}
                  </Typography>
                </div>
              </Stack>
              <Divider sx={{ mt: 1 }} />
              <Stack
                containe
                direction={"row"}
                spacing={2}
                //   bgcolor={"red"}
                //   width={"100%"}
              >
                <Grid item lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Risque
                  </Typography>
                  <Divider />
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.ttypevulnerabilite.designationv}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={12}
                  lg={12}
                  marginLeft={10}
                  marginRight={10}
                >
                  <Typography
                    textAlign={"justify"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Description
                  </Typography>
                  <Divider />
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.designationvul}`}
                  </Typography>
                </Grid>
                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Emplacement
                  </Typography>
                  <Divider />
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.templacement.designationsite}`}
                  </Typography>
                </Grid>
                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Origine
                  </Typography>
                  <Divider />
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.torigine.designationor}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"right"} mb={2}>
          <Boutons title="Imprimer" onClick={() => Imprimer()} />
        </Grid>
      </Box>
    </Box>
  );
}

export default BiographieVulne;
