import { Grid } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputTerritoire({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  //declaretion type province
  const [dataprovince, setDataProvince] = React.useState([]);
  const [valueprovince, setValueProvince] = React.useState();

  //get province
  const getProvince = () => {
    request
      .get("province/active")
      .then((e) => {
        let item = e.data.data.map((ex) => {
          return {
            value: ex.idprovince,
            label: ex.designationprv,
          };
        });
        setDataProvince(item);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };
  React.useEffect(() => {
    getProvince();
  }, []);
  //
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designationter: dataUpdate === "" ? "" : dataUpdate.designationter,
      idteritoire: dataUpdate === "" ? "" : dataUpdate.idteritoire,
      idprovince: valueprovince ? valueprovince.value : "",
    },

    validationSchema: Yup.object({
      designationter: Yup.string().required("is required"),
    }),
    onSubmit: (values) => {
      //   console.log(values);
      if (valueprovince === undefined) {
        NotifyError("la province doivent etre séléctionnée");
      } else {
        if (dataUpdate === "") {
          //enregistrement
          setEtat(true);
          request
            .post("territoire/save", values)
            .then((e) => {
              // console.log(e.data);
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          //modification
          setEtat(true);
          request
            .put("territoire/update", values)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container minWidth={"50vw"}>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div>
            <Comboboxs
              value={valueprovince}
              onChange={(e) => setValueProvince(e)}
              data={dataprovince}
              placeholder="province"
            />
          </div>
          <TextFields
            value={validation.values.designationter}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"designationter"}
            error={
              validation.touched.designationter &&
              validation.errors.designationter
                ? true
                : false
            }
            placeholder="Province"
          />

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputTerritoire;
