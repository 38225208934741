import { Divider, IconButton, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import InputEmplacement from "./InputEmplacement";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Nom du lieu
      </Typography>
    ),
    selector: (row) => row.emplacement,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Longitude
      </Typography>
    ),
    selector: (row) => row.logitude,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Lattitude
      </Typography>
    ),
    selector: (row) => row.latitude,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Status
      </Typography>
    ),
    // selector: (row) => row.latitude,
    sortable: true,
    cell: (row) => {
      return (
        <Stack direction={"row"} spacing={1}>
          <Switch
            checked={row.status.etatemplacements === true ? true : false}
            // onChange={() => ChangeTable()}
            inputProps={{
              "aria-label": "controlled",
            }}
            color={row.status.etatemplacements === true ? "primary" : "warning"}
          />
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Emplacement() {
  document.title = "Emplacement | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataEmplacement = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("emplacement")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.map((e) => {
          return {
            emplacement: e?.designationsite,
            logitude: e?.logitude,
            latitude: e?.latitule,
            date: e?.datecreate,
            // date2: e.dateupdate,
            status: e,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => Update(e)}>
                  <Edit color="info" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    nav("/emplacemant-detaille", {
                      state: {
                        itemv: e,
                      },
                    })
                  }
                >
                  <Visibility color="disabled" />
                </IconButton>

                <IconButton onClick={() => ConfirmInformation(e)}>
                  <Delete color="error" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  React.useEffect(() => {
    getDataEmplacement();
  }, []);

  const dataFiltre = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.emplacement &&
        itemx.emplacement.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Nouvel enregistrement");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification vulnerable");
  };

  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("emplacement/delete", {
        idemplacement: dataupdate.idemplacement,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getDataEmplacement();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Veuillez patiente</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: 2,
            // ml: -1,
          }}
        >
          <div>
            <Boutons
              onClick={() => nouveauVictiome()}
              icons={
                <Add
                  sx={{
                    mr: 2,
                  }}
                />
              }
              title="Nouvel emplacement"
            />
          </div>
          {etatv === false ? (
            ""
          ) : (
            <Stack
              style={{
                justifyContent: "center",
                alignItems: "center",
                background: "#E3EAEF",
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              <Typography fontWeight={"bold"} fontSize={20}>
                Information
              </Typography>
              <Divider />
              <Typography fontWeight={"bold"} fontSize={16}>
                Etes-vous sur d'effectuer cette action
              </Typography>
              <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                <IconButton
                  sx={{
                    bgcolor: "#013266",
                    ":hover": {
                      bgcolor: "#1976D2",
                    },
                  }}
                  onClick={() => ValidationDataUpdateEtat()}
                >
                  <Check
                    sx={{
                      color: "#FFF",
                    }}
                    fontSize="large"
                  />
                </IconButton>
                <IconButton
                  sx={{
                    bgcolor: "red",
                    ":hover": {
                      bgcolor: "#1976D2",
                    },
                  }}
                  onClick={() => setEtatV(!etatv)}
                >
                  <Close
                    sx={{
                      color: "#FFF",
                    }}
                    fontSize="large"
                  />
                </IconButton>
              </Stack>
            </Stack>
          )}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <h1></h1>
            <div>
              <TextFields
                value={searchQuery}
                onChange={(e) => {
                  Recherche(e.target.value);
                }}
                icons={
                  <Search
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                  />
                }
                placeholder="search..."
              />
            </div>
          </Stack>
          <Mtables
            title="Liste des emplacements identifiés"
            data={dataFiltre}
            columns={column}
          />
        </Stack>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputEmplacement dataUpdate={dataupdate} fonct={getDataEmplacement} />
      </Dialogs>
    </div>
  );
}

export default Emplacement;
