import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFields from "../controller/TextFields";
import Boutons from "../controller/Boutons";
import { AccountCircle, Key, LoginOutlined, Save } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
var Spinkit = require("react-spinkit");

function Login() {
  const nav = useNavigate();
  const [disable, setDisable] = React.useState(false);
  const [etat, setEtat] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });
  const [visiblepassword, setVisiblePassword] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      login: login.username != "" ? login.username : "",
      password: login.password !== "" ? login.password : "",
    },
    validationSchema: Yup.object({
      login: Yup.string().required("Ce champs est requis"),
      password: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      setDisable(true);
      setEtat(true);
      if (rememberMe) {
        localStorage.setItem("username", values.login);
        localStorage.setItem("password", values.password);
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      }
      request
        .post("auth/login", values)
        .then((e) => {
          // console.log(e.data.data);
          localStorage.setItem("isAuth", true);
          localStorage.setItem("data", JSON.stringify(e.data.data));
          nav("/dashboard");
          setDisable(false);
          setEtat(false);
        })
        .catch((error) => {
          // console.log(error);
          if (error.request) {
            NotifyError(error.request);
            setEtat(false);
          } else if (error.response) {
            NotifyError(error.response.data.message);
            setEtat(false);
          } else if (error.config) {
            NotifyError(error.config);
            setEtat(false);
          } else {
            NotifyError(error.message);
            setEtat(false);
          }
        });
    },
  });

  useEffect(() => {
    const rememberedUsername = localStorage.getItem("username");
    const rememberedPassword = localStorage.getItem("password");

    if (rememberedUsername && rememberedPassword) {
      //  setUsername(rememberedUsername);
      //  setPassword(rememberedPassword);
      setRememberMe(true);
      setLogin({
        username: rememberedUsername,
        password: rememberedPassword,
      });
    }
  }, []);

  const visiblePasswords = () => {
    setVisiblePassword(!visiblepassword);
  };

  return (
    <Grid
      container
      sx={{
        // backgroundImage: `url(${bgimage})`,
        // backgroundSize: "cover",
        minHeight: "100vh",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        item
        xs={0}
        md={6}
        sm={0}
        lg={6}
        justifyContent={"center"}
        alignItems={"center"}
        // display={"flex"}
        display={{
          xs: "none",
          lg: "flex",
          md: "flex",
          sm: "none",
        }}
        sx={{
          background: "#013266",
        }}
      >
        <img
          src={require("../assets/ESB.png")}
          style={{
            width: "40%",
            height: "40%",
            // marginTop: "20%",
          }}
          onResize="contain"
          alt="photo"
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={5}
        sm={12}
        lg={5}
        // sx={{
        //   ml: 15,
        // }}
      >
        <Stack
          sx={{
            mt: 5,
            width: "100%",
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={require("../assets/ES.png")}
            style={{
              width: 100,
              height: 100,
              // borderRadius: "100%",
              objectFit: "contain",
            }}
            onResize="contain"
            alt="photo"
          />

          <Typography
            sx={{
              // m: 3,
              fontWeight: "bold",
              fontSize: 18,
              // fontFamily: "roboto",
              color: "#000",
              mb: 3,
              mt: 1,
            }}
          >
            Se connecter
          </Typography>
          <Stack
            sx={{
              width: "90%",
            }}
            padding={2}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <TextFields
                label={"Nom d'utilisateur"}
                id={"login"}
                onBlur={validation.handleBlur}
                value={validation.values.login}
                onChange={validation.handleChange}
                error={
                  validation.touched.login && validation.errors.login
                    ? true
                    : false
                }
                labelError={
                  validation.touched.login &&
                  validation.errors.login &&
                  validation.errors.login
                }
                placeholder={"Nom d'utilisateur"}
                // icons={
                //   <AccountCircle
                //     fontSize="large"
                //     sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                //   />
                // }
              />
              <TextFields
                label={"Mot de passe"}
                id={"password"}
                placeholder={"Mot de passe"}
                onBlur={validation.handleBlur}
                value={validation.values.password}
                onChange={validation.handleChange}
                error={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
                labelError={
                  validation.touched.password &&
                  validation.errors.password &&
                  validation.errors.password
                }
                type={visiblepassword === false ? "password" : "text"}
                visi={true}
                onChangeVisibility={() => visiblePasswords()}
              />
              <label
                style={{
                  fontFamily: "Montserrat",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                Remember me
              </label>
              <Stack
                sx={{
                  // width: "93%",
                  mt: 2,
                  // ml: 5,
                }}
              >
                <Boutons
                  type={"submit"}
                  icons={
                    etat === true ? (
                      <Spinkit color="#FFF" name="ball-beat" size={18} />
                    ) : (
                      <LoginOutlined
                        sx={{
                          mr: 2,
                        }}
                      />
                    )
                  }
                  disable={disable}
                  title="Se connecter"
                  // onClick={() => Login()}
                />
              </Stack>
            </form>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Login;
