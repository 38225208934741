import Agent from "../page/Agent";
import BiographieVulne from "../page/BiographieVulne";
import DashBoard from "../page/DashBoard";
import DetailMessage from "../page/DetailMessage";
import Emplacement from "../page/Emplacement";
import EmplacementDetial from "../page/EmplacementDetial";
import Entreprise from "../page/Entreprise";
import Errors from "../page/Errors";
import EtatDeSortie from "../page/EtatDeSortie";
import EvenemenetDetaille from "../page/EvenemenetDetaille";
import Evenement from "../page/Evenement";
import ImprimerFraude from "../page/ImprimerFraude";
import Login from "../page/Login";
import Message from "../page/Message";
import Mesure from "../page/Mesure";
import MesureDetail from "../page/MesureDetail";
import Origine from "../page/Origine";
import Profil from "../page/Profil";
import Region from "../page/Region";
import TabsCooperative from "../page/TabCooperative";
import TabsSiteMiner from "../page/TabSiteMiner";
import TabsAchat from "../page/TabsAchat";
import TabsAgentGouv from "../page/TabsAgenGouv";
import TabsEntiteDeTraitemetn from "../page/TabsEntiteDeTraitement";
import TabsFraude from "../page/TabsFraude";
import TabsProduction from "../page/TabsProduction";
import TypeEmplacement from "../page/TypeEmplacement";
import TypeEvenement from "../page/TypeEvenement";
import TypeVulnerabilte from "../page/TypeVulnerabilite";
import Victime from "../page/Victime";
import VisibilityProduction from "../page/VisibilityProduction";

export const protectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <DashBoard /> },
  { path: "/victime", component: <Victime /> },
  { path: "/evenement", component: <Evenement /> },
  { path: "/mesure", component: <Mesure /> },
  { path: "/mesure-detail", component: <MesureDetail /> },
  { path: "/biographie", component: <BiographieVulne /> },
  { path: "/evenement-detaille", component: <EvenemenetDetaille /> },
  { path: "/agent", component: <Agent /> },
  { path: "/partie-prenate", component: <TabsCooperative /> },
  { path: "/type-emplacement", component: <TypeEmplacement /> },
  { path: "/type-evenement", component: <TypeEvenement /> },
  { path: "/type-Vulnerabilite", component: <TypeVulnerabilte /> },
  { path: "/region", component: <Region /> },
  { path: "/origine", component: <Origine /> },
  { path: "/emplacemant", component: <TabsSiteMiner /> },
  { path: "/emplacemant-detaille", component: <EmplacementDetial /> },
  { path: "/Profil", component: <Profil /> },
  { path: "/imprimer", component: <EtatDeSortie /> },
  { path: "/agent-gouv", component: <TabsAgentGouv /> },
  { path: "/production", component: <TabsProduction /> },
  { path: "/message", component: <Message /> },
  { path: "/entite-de-traitement", component: <TabsEntiteDeTraitemetn /> },
  { path: "/achat", component: <TabsAchat /> },
  { path: "/chiane-de-la-production", component: <VisibilityProduction /> },
  { path: "/detail-message", component: <DetailMessage /> },
  { path: "/fraude", component: <TabsFraude /> },
  { path: "/fraude-imprimer", component: <ImprimerFraude /> },
];

export const publicRoutes = [
  //login
  { path: "/", component: <Login />, exact: true },
  { path: "/error", component: <Errors /> },
];
