import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

const datasexe = [
  { value: "Site Minier", label: "Site Minier" },
  { value: "Négociant", label: "Négociant" },
];

function InputFraude({ foncget, dataUpdate, label }) {
  const [etat, setEtat] = React.useState(false);
  const [genre, setGenre] = React.useState("");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      minerai: "",
      //   idminerai: dataUpdate?.idminerai,
      quantite: "",
      typedesignation: "",
      designation: "",
      typedaction: "",
      structure: "",
    },

    validationSchema: Yup.object({
      minerai: Yup.string().required("le minerai est obligatoire"),
      quantite: Yup.string().required("la quantite est obligatoire"),
      designation: Yup.string().required(
        "le negociant ou la designation du site est obligatoire"
      ),
      structure: Yup.string().required("la structure est obligatoire"),
    }),
    onSubmit: (values) => {
      //   console.log("====>", values);
      if (dataUpdate === "") {
        //enregistrement
        if (genre === "" || genre === undefined || genre === null) {
          NotifyError("la provenance est obligatoire");
        } else {
          setEtat(true);
          const datas = {
            minerai: values.minerai,
            quantite: values.quantite,
            typedesignation: genre.value,
            designation: values.designation,
            typedaction: label,
            structure: values.structure,
          };
          //   console.log("datas===>: ", datas);
          request
            .post("fraude/save", datas)
            .then((e) => {
              if (e.status === 200) {
                NotifySuccess(e.data.message);
                setEtat(false);
                foncget();
              }
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error?.message);
                setEtat(false);
              }
            });
        }
      } else {
        //modification
        // setEtat(true);
        // request
        //   .put("mine/update", values)
        //   .then((e) => {
        //     if (e.status === 200) {
        //       foncget();
        //       NotifySuccess(e.data.message);
        //       setEtat(false);
        //     }
        //   })
        //   .catch((error) => {
        //     if (error.code) {
        //       NotifyError(error.response.data.message);
        //       // setDisable(false);
        //       setEtat(false);
        //     } else {
        //       NotifyError(error.response.data.message);
        //       setEtat(false);
        //     }
        //   });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextFields
            value={validation.values.minerai}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"minerai"}
            error={
              validation.touched.minerai && validation.errors.minerai
                ? true
                : false
            }
            placeholder="Minerai"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.minerai && validation.errors.minerai
              ? validation.errors.minerai
              : false}
          </p>

          <TextFields
            value={validation.values.quantite}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"quantite"}
            type={"number"}
            error={
              validation.touched.quantite && validation.errors.quantite
                ? true
                : false
            }
            placeholder="Quantité en Kg"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.quantite && validation.errors.quantite
              ? validation.errors.quantite
              : false}
          </p>
          <div>
            <Comboboxs
              value={genre}
              onChange={(e) => setGenre(e)}
              data={datasexe}
              placeholder="Provenance"
            />
          </div>

          <TextFields
            value={validation.values.designation}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"designation"}
            error={
              validation.touched.designation && validation.errors.designation
                ? true
                : false
            }
            placeholder="Nom du Négociant"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.designation && validation.errors.designation
              ? validation.errors.designation
              : false}
          </p>
          <TextFields
            value={validation.values.structure}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"structure"}
            error={
              validation.touched.structure && validation.errors.structure
                ? true
                : false
            }
            placeholder="Centre de Negoce"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.structure && validation.errors.structure
              ? validation.errors.structure
              : false}
          </p>
          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputFraude;
