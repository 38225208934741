import { Box, Stack } from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import ImprimerVictimer from "./ImprimerVictimer";
import Comboboxs from "../controller/Comboboxs";
import ImprimerEvenement from "./ImprimerEvement";
import ImprimerMesure from "./ImprimerMesure";

const dataFilter = [
  { value: "victime", label: "Victime" },
  { value: "mesure", label: "Mesure" },
  { value: "evenement", label: "Evenement" },
  // { value: "date", label: "Date" },
];

function EtatDeSortie() {
  const [valuex, setValue] = React.useState("");
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // maxWidth: "100%",
        // flexWrap: "wrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 3,
        }}
      >
        <Stack
          direction={"row"}
          bgcolor={"#FFF"}
          justifyContent={"space-between"}
          mb={2}
          p={2}
          borderRadius={2}
        >
          <div
            style={
              {
                // marginTop: -40,
              }
            }
          >
            <h4
              style={{
                // background: "white",
                // padding: 20,
                // borderRadius: 5,
                fontWeight: "bold",
                fontSize: 25,
                //  marginTop: -5,
              }}
            >
              Etat de sortie
            </h4>
          </div>
          <div style={{ width: "20%", marginTop: -10 }}>
            <Comboboxs
              value={valuex}
              onChange={(e) => setValue(e)}
              data={dataFilter}
              placeholder="Filter"
            />
          </div>
        </Stack>
        {valuex.value === "victime" ? (
          <ImprimerVictimer />
        ) : valuex.value === "evenement" ? (
          <ImprimerEvenement />
        ) : valuex.value === "mesure" ? (
          <ImprimerMesure />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default EtatDeSortie;
