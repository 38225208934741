import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { request } from "../url/request";
import Comboboxs from "../controller/Comboboxs";
import NotifyError from "../Notification/NotifyError";

const data1 = [["Description", "Totalité"]];

const dataSelectDay = [
  {
    value: "o",
    label: "Aucune",
  },
  {
    value: "m",
    label: "Mensuel",
  },
  {
    value: "i",
    label: "Période",
  },
  {
    value: "a",
    label: "Annuel",
  },
];

const options1 = {
  title: "Ananlyse statistique",
  chartArea: { width: "50%" },
  colors: ["#3366CC", "red", "blue"],
  hAxis: {
    title: "Description",
    minValue: 0,
  },
  vAxis: {
    title: "Echelle",
  },
};

function StatFraude() {
  const [check, setCheck] = useState(false);
  const [charAn, setCharAn] = React.useState(data1);
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [date4, setDate4] = useState();
  const [select, setSelect] = useState("");

  const actionSelect = () => {
    setCharAn([
      ["Description", "Totalité", { role: "style" }],
      ["Production", 0],
      ["Centre de negoce", 0],
      ["Entité de traitement", 0],
    ]);
    if (select.value === "a") {
      //   setValueEvenemet(e);
      const newDate = new Date(date1?.target.value);
      console.log("yarn", newDate.getFullYear());
      request
        .post("/states/fraude", {
          periode: select.value,
          year1: newDate.getFullYear(),
          //   fk_idtypevulnerables: valueEvenement.value,
        })
        .then((e) => {
          // console.log("annuel");
          setCharAn([
            ["Description", "Totalité", { role: "style" }],
            ["Centre de negoce", e.data?.datacentrenego, "blue"],
            ["Entité de traitement", e.data?.dataenttrait, "#DC3912"],
          ]);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.code) {
            NotifyError(error.message);
          } else {
            NotifyError(error.response.data.message);
          }
        });
    }
    if (select.value === "m") {
      //   setValueEvenemet(e);
      const newDate = new Date(date2?.target.value);
      console.log("mois", newDate);
      request
        .post("/states/fraude", {
          periode: select.value,
          datex: newDate.getMonth() + 1,
          year: newDate.getFullYear(),
          //   fk_idtypevulnerables: valueEvenement.value,
        })
        .then((e) => {
          setCharAn([
            ["Description", "Totalité", { role: "style" }],
            ["Centre de negoce", e.data?.datacentrenego, "blue"],
            ["Entité de traitement", e.data?.dataenttrait, "#DC3912"],
          ]);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.code) {
            NotifyError(error.message);
          } else {
            NotifyError(error.response.data.message);
          }
        });
    }
    if (select.value === "i") {
      //   setValueEvenemet(e);
      request
        .post("/states/fraude", {
          periode: select.value,
          date1: date3?.target.value,
          date2: date4?.target.value,
          //   fk_idtypevulnerables: valueEvenement.value,
        })
        .then((e) => {
          // console.log("===>zozo:===>", e.data);
          //   NotifyError("je suis entre");
          setCharAn([
            ["Description", "Totalité", { role: "style" }],
            ["Centre de negoce", e.data?.datacentrenego, "blue"],
            ["Entité de traitement", e.data?.dataenttrait, "#DC3912"],
          ]);
        })
        .catch((error) => {
          //   console.log(error.response);
          if (error.code) {
            NotifyError(error.message);
            // setDisable(false);
            // setEtat(false);
          } else {
            NotifyError(error.response.data.message);
          }
        });
    } else {
      //   setValueEvenemet(e);
      request
        .post("/states/fraude", {
          periode: select.value,
          //   date1: date3.target.value,
          //   date2: date4.target.value,
          //   fk_idtypevulnerables: valueEvenement.value,
        })
        .then((e) => {
          // console.log("===>zozo:===>", e.data);
          setCharAn([
            ["Description", "Totalité", { role: "style" }],
            ["Centre de negoce", e.data?.datacentrenego, "blue"],
            ["Entité de traitement", e.data?.dataenttrait, "#DC3912"],
          ]);
        })
        .catch((error) => {
          //   console.log(error.response);
          if (error.code) {
            NotifyError(error.message);
            // setDisable(false);
            // setEtat(false);
          } else {
            NotifyError(error.response.data.message);
          }
        });
    }
  };

  const getInitaile = () => {
    request
      .post("/states/fraude", {
        periode: "x",
      })
      .then((e) => {
        setCharAn([
          ["Description", "Totalité", { role: "style" }],
          ["Centre de negoce", e.data?.datacentrenego, "blue"],
          ["Entité de traitement", e.data?.dataenttrait, "#DC3912"],
        ]);
      })
      .catch((error) => {
        //   console.log(error.response);
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          // setEtat(false);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getInitaile();
  }, []);

  return (
    <div
      style={{
        margin: 5,
        marginLeft: 30,
        // background: "red",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          // m: 3,
          m: 2,
        }}
      >
        <div>
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: "bolder",
              textDecoration: "underline",
            }}
          >
            Évaluation statistique des fraudes
          </Typography>
        </div>
        <div
        // style={{
        //   width: "50%",
        // }}
        >
          <Stack flexDirection={"row"}>
            <input
              type="checkbox"
              value={check}
              onChange={() => setCheck(!check)}
            />
            Filtrer
          </Stack>
          {check === false ? (
            ""
          ) : (
            <Comboboxs
              data={dataSelectDay}
              value={select}
              onChange={(e) => setSelect(e)}
              placeholder="Selectionner"
            />
          )}
          {check === false ? (
            ""
          ) : select.value === "i" ? (
            <div>
              <Stack flexDirection={"row"}>
                <div style={{ marginRight: 10 }}>
                  <p>Date du début</p>
                  <input
                    type="date"
                    // value={data1}
                    onChange={(e) => setDate3(e)}
                    style={{
                      height: 38,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <p>Date de Fin</p>
                  <input
                    type="date"
                    // value={data1}
                    onChange={(e) => setDate4(e)}
                    style={{
                      height: 38,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div>
                  <button
                    style={{
                      marginTop: 65,
                    }}
                    onClick={() => actionSelect()}
                  >
                    Valider
                  </button>
                </div>
              </Stack>
            </div>
          ) : select.value === "a" ? (
            <div>
              <Stack>
                <div>
                  <p>Date</p>
                  <input
                    type="date"
                    // value={data1}
                    onChange={(e) => setDate1(e)}
                    style={{
                      height: 38,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div>
                  <button
                    style={{
                      marginTop: 65,
                    }}
                    onClick={() => actionSelect()}
                  >
                    Valider
                  </button>
                </div>
              </Stack>
            </div>
          ) : select.value === "m" ? (
            <div>
              <Stack flexDirection={"row"}>
                <div style={{ marginRight: 10 }}>
                  <p>Mois</p>
                  <input
                    type="date"
                    // value={data1}
                    onChange={(e) => setDate2(e)}
                    style={{
                      height: 38,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div>
                  <div>
                    <button
                      style={{
                        marginTop: 65,
                      }}
                      onClick={() => actionSelect()}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </Stack>
            </div>
          ) : (
            ""
          )}
        </div>
      </Stack>
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"end"}
        // alignItems={"center"}
      >
        <Typography textAlign={"center"} width={"100%"}>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={charAn}
            options={options1}
          />
        </Typography>
      </Grid>
    </div>
  );
}

export default StatFraude;
