import { Button } from "@mui/material";
import React from "react";

function Boutons({
  type,
  onClick,
  disabled,
  icons,
  title,
  width,
  color,
  bgcolor,
}) {
  return (
    <Button
      type={type}
      sx={{
        // mt: 2,
        background: bgcolor ? bgcolor : "#013266",
        color: color ? color : "#FFF",
        width: width,
        "&:hover": {
          background: "#013266",
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icons}
      {title}
    </Button>
  );
}

export default Boutons;
