import React, { useEffect } from "react";
import { request } from "../../url/request";
import NotifyError from "../../Notification/NotifyError";

function useEmplacement() {
  const [datasite, setDataSite] = React.useState([]);

  //get emplacement
  const getEmplacement = () => {
    request
      .get("emplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idemplacement,
            label: ex.designationsite,
          };
        });
        setDataSite(itm);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          // setEtat(false);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getEmplacement();
  }, []);

  return {
    datasite,
    setDataSite,
  };
}

export default useEmplacement;
