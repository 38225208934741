import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { request } from "../url/request";

function ChecBoxEntite({ data, choix, setChoix }) {
  // État pour stocker les valeurs des cases cochées
  const [checkboxValues, setCheckboxValues] = useState([]);

  // Fonction pour gérer le changement d'état des cases à cocher
  const handleCheckboxChange = (event) => {
    const checkedValue = event.target.value;
    if (event.target.checked) {
      // Ajoute la valeur si la case est cochée
      setCheckboxValues([...checkboxValues, checkedValue]);
    } else {
      // Retire la valeur si la case est décochée
      const updatedValues = checkboxValues.filter(
        (value) => value !== checkedValue
      );
      setCheckboxValues(updatedValues);
    }
  };

  return {
    checkbx: (
      <div>
        {data.map((checkboxItem) => (
          <FormControlLabel
            key={checkboxItem.idroleentiteprodu}
            control={
              <Checkbox
                checked={checkboxValues.includes(
                  checkboxItem.idroleentiteprodu
                )}
                onChange={handleCheckboxChange}
                value={checkboxItem.idroleentiteprodu}
              />
            }
            label={checkboxItem.designation}
          />
        ))}
        {/* Affichage des valeurs des cases cochées */}
        <p>Valeurs sélectionnées : {checkboxValues.join(", ")}</p>
      </div>
    ),
    checkboxValues: checkboxValues,
  };
}

export default ChecBoxEntite;
