import { Alert, Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import MiniDrawer from "../navigation/Drawers";
import { useLocation, useNavigate } from "react-router-dom";
import { request, request2 } from "../url/request";
import { ArrowBack } from "@mui/icons-material";
import bg from "../assets/ES.png";
import NotifyError from "../Notification/NotifyError";
import NotifySuccess from "../Notification/NotifySuccess";

function DetailMessage() {
  const { item } = useLocation().state;
  const nav = useNavigate();
  console.log(item);

  const updateMessage = () => {
    request
      .put("production/updatemessage", {
        idmessage: item.idmessage,
      })
      .then((e) => {
        // NotifySuccess("Bienvenu");
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    updateMessage();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div
          style={{
            background: "white",
            borderRadius: 5,
            padding: 10,
            marginTop: 10,
          }}
        >
          <Typography variant="h5" fontWeight={"Bolder"}>
            Message
          </Typography>
          <Typography variant="h5" fontSize={14}>
            Détail du Message
          </Typography>
        </div>
        <div
          style={{
            margin: 20,
            background: "white",
            borderRadius: 5,
            padding: 10,
            marginTop: 50,
          }}
        >
          <div
            style={{
              position: "absolute",
              marginTop: -45,
              width: "25%",
            }}
          >
            <Alert
              icon={false}
              severity="info"
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Informations sur l'utilisateur
            </Alert>
          </div>
          <Stack flexDirection={"row"}>
            <div style={{ marginTop: 50 }}>
              <img
                src={
                  item.tpersonne.image === ""
                    ? bg
                    : `${request2}${item.tpersonne.image}`
                }
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: "100%",
                  // borderColor: "black",
                  borderWidth: 5,
                }}
                onResize={"contain"}
                alt
              />
            </div>
            <div style={{ marginTop: 50, marginLeft: 30 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  // fontSize: 18,
                }}
              >
                {item.tpersonne.nom.toUpperCase()}{" "}
                {item.tpersonne.postnom.toUpperCase()}
                {item.tpersonne.prenom.toUpperCase()}
              </Typography>
              <Typography>{item.tpersonne.genre.toUpperCase()}</Typography>
              <Typography>{item.tpersonne.phone.toUpperCase()}</Typography>
              <Typography
                sx={{
                  //   fontWeight: "bolder",
                  fontSize: 18,
                }}
              >
                {item.tpersonne.adresse.toUpperCase()}
              </Typography>
            </div>
          </Stack>
        </div>
        <div
          style={{
            margin: 20,
            background: "white",
            borderRadius: 5,
            padding: 10,
            marginTop: 50,
            minHeight: "20%",
          }}
        >
          <div
            style={{
              position: "absolute",
              marginTop: -45,
              width: "25%",
            }}
          >
            <Alert
              icon={false}
              severity="info"
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Message
            </Alert>
          </div>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              textAlign: "center",
              marginTop: 5,
              textDecoration: "underLine",
              color: "#013266",
            }}
          >
            Objet: {item?.typemessage}
          </Typography>

          <Typography
            sx={{
              textAlign: "justify",
              margin: 5,
            }}
          >
            {item?.textmessage}
          </Typography>
        </div>
      </Box>
    </Box>
  );
}

export default DetailMessage;
