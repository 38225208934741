import React, { useEffect, useState } from "react";
import { request } from "../../url/request";
import NotifyError from "../../Notification/NotifyError";
import { IconButton, Stack } from "@mui/material";
import { Edit } from "@mui/icons-material";
import NotifySuccess from "../../Notification/NotifySuccess";

function useAchat() {
  const [data, setData] = React.useState([]);
  const [data2, setData2] = useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const [opendialiguex, setOpenDialoguex] = React.useState(false);
  const [etat, setEtat] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  /**
   * recuperation des marchandise deja vendu en fin de les affiche dans l'interface
   * d'entite de traitement
   */
  const getDataVenteProduct = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("vente/listeachat")
      .then((e) => {
        // console.log("pima==>:)", e.data.data);
        const uniqueData = e.data.data.reduce((acc, current) => {
          if (
            !acc.find((item) => item.idcentrenegoce === current.idcentrenegoce)
          ) {
            acc.push(current);
          }
          return acc;
        }, []);
        setData(uniqueData);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  // get liste des achat
  const getDataAchatList = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("achat")
      .then((e) => {
        let itm = e.data.data.map((e) => {
          return {
            minerai:
              e?.tcentrenegoce?.tproduction?.tpontsiteminetypemine?.tminerai
                ?.designation,
            quantitevenu: e?.tcentrenegoce?.quantitevendu,
            quantiterecu: e?.quantiterecu,
            prixachat: e?.prixachat,
            datecreate: e?.datecreate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => modification(e)}>
                  <Edit color="info" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData2(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //enregistrement save achat marchandise
  const saveAchat = ({ data, foncget }) => {
    request
      .post("achat/save", data)
      .then((e) => {
        if (e.status === 200) {
          foncget();
          NotifySuccess(e.data.message);
          setEtat(false);
        }
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //save fraude
  const saveFraude = ({ data, foncget }) => {
    request
      .post("fraude/save", data)
      .then((e) => {
        if (e.status === 200) {
          NotifySuccess(e.data.message);
          setEtat(false);
          foncget();
        }
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //Ajout de prix de l'achat

  const SavePrixAchat = ({ data, foncget }) => {
    request
      .put("achat/update", data)
      .then((e) => {
        if (e.status === 200) {
          foncget();
          NotifySuccess(e.data.message);
          setEtat(false);
        }
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  const dataFiltre = data2.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.minerai &&
        itemx.minerai.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const modification = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  React.useEffect(() => {
    getDataVenteProduct();
  }, []);

  useEffect(() => {
    getDataAchatList();
  }, []);

  return {
    data,
    setData,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
    setDataUpdate,
    opendialiguex,
    setOpenDialoguex,
    etat,
    setEtat,
    etatcheckconn,
    setEtatChecConn,
    getDataVenteProduct,
    getDataAchatList,
    dataFiltre,
    saveAchat,
    saveFraude,
    SavePrixAchat,
  };
}

export default useAchat;
