import { Grid } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function MesureInput({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const [dataevenement, setDataEvenemet] = React.useState([]);
  const [valueEvenement, setValueEvenemet] = React.useState("");

  const [dataepartieprenate, setDataPartiePrenata] = React.useState([]);
  const [valuepartieprenate, setValuePartiepRenate] = React.useState("");

  let iduser = JSON.parse(localStorage.getItem("data"));
  //   console.log(iduser);

  const getEvenement = () => {
    request
      .get("typevunerabilite/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          return {
            value: ex.idtypevulnerabilite,
            label: ex.designationv,
          };
        });
        setDataEvenemet(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };
  const getPartiePrenate = () => {
    request
      .get("partieprenante")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          return {
            value: ex.idpartieprenante,
            label: ex.designaionp,
          };
        });
        setDataPartiePrenata(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };
  React.useEffect(() => {
    getEvenement();
    getPartiePrenate();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      titrem: dataUpdate === "" ? "" : dataUpdate.titrem,
      descryptionm: dataUpdate === "" ? "" : dataUpdate.descryptionm,
      nomagent: iduser.identite.postnom + " " + iduser.identite.prenom,
      idtypevulnerabilite: valueEvenement ? valueEvenement.value : "",
      idpartieprenante: valuepartieprenate ? valuepartieprenate.value : "",
      idmesure: dataUpdate.idmesure,
    },

    validationSchema: Yup.object({
      titrem: Yup.string().required("is required"),
      // descryptionm: Yup.string().required("is required"),
    }),
    onSubmit: (values) => {
      //   console.log(values);
      if (valueEvenement === "") {
        NotifyError("type de vulnerabilte ne doit etre selectinner");
      } else if (valuepartieprenate === "") {
        NotifyError("l apartie prenante ne doit etre selectinner");
      } else {
        if (dataUpdate === "") {
          //enregistrement
          setEtat(true);
          request
            .post("mesure/save", values)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          //modification
          //   console.log(values);
          setEtat(true);
          request
            .put("mesure/update", values)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Comboboxs
            data={dataevenement}
            value={valueEvenement}
            onChange={(e) => setValueEvenemet(e)}
            placeholder="Risque à attenuer"
          />
          <Comboboxs
            data={dataepartieprenate}
            value={valuepartieprenate}
            onChange={(e) => setValuePartiepRenate(e)}
            placeholder="Responsable de la mesure"
          />
          <TextFields
            value={validation.values.titrem}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"titrem"}
            error={
              validation.touched.titrem && validation.errors.titrem
                ? true
                : false
            }
            placeholder="Titre de la mesure"
          />
          <TextFields
            value={validation.values.descryptionm}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"descryptionm"}
            error={
              validation.touched.descryptionm && validation.errors.descryptionm
                ? true
                : false
            }
            multiline={true}
            placeholder="Description de la mesure d'attenuation"
          />
          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default MesureInput;
