import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import MiniDrawer from "../navigation/Drawers";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import Info from "../../outher/Info";
import { useReactToPrint } from "react-to-print";
import Boutons from "../controller/Boutons";
// import { request2 } from "../url/request";

function EmplacementDetial() {
  const localrouter = useLocation();
  // console.log(localrouter);
  const nav = useNavigate();
  const prints = useRef();

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: "emplacement",
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div ref={prints}>
          <Info />
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            Détails de l'emplacement
          </h4>
          <Grid
            container
            //   justifyContent={"center"}
            //   alignItems={"center"}
            sx={{
              bgcolor: "#FFF",
              p: 2,
              m: 3,
              borderRadius: 5,
              ml: -1,
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <Stack direction={"column"}>
                <Typography
                  textAlign={"justify"}
                  fontSize={20}
                  fontWeight={"bold"}
                >
                  Emplacement
                </Typography>
                <Typography
                  textAlign={"justify"}
                  fontSize={16}
                  // fontWeight={"bold"}
                  mb={2}
                >
                  {`${localrouter.state.itemv?.designationsite.toUpperCase()}`}
                </Typography>
                <Typography
                  textAlign={"justify"}
                  fontSize={20}
                  fontWeight={"bold"}
                >
                  Coordonnées GPS
                </Typography>
                <Stack direction={"row"} spacing={2} mb={2}>
                  <Typography textAlign={"justify"} fontSize={14}>
                    Longitude : {`${localrouter.state.itemv?.logitude}`}
                  </Typography>
                  <Typography textAlign={"justify"} fontSize={14}>
                    Lattitude : {`${localrouter.state.itemv?.latitule}`}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={5}>
                  <div>
                    <Typography
                      textAlign={"justify"}
                      fontSize={20}
                      fontWeight={"bold"}
                    >
                      Origine
                    </Typography>
                    <Typography
                      textAlign={"justify"}
                      // fontWeight={"bold"}
                      fontSize={16}
                      mb={2}
                    >
                      {`${localrouter.state.itemv?.ttypeemplacement?.designationtype.toUpperCase()}`}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      textAlign={"justify"}
                      fontSize={20}
                      fontWeight={"bold"}
                    >
                      Région
                    </Typography>
                    <Typography
                      textAlign={"justify"}
                      // fontWeight={"bold"}
                      fontSize={16}
                      mb={2}
                    >
                      {`${localrouter.state.itemv?.tteritoire?.designationter.toUpperCase()}`}
                    </Typography>
                  </div>
                </Stack>
                <Typography
                  textAlign={"justify"}
                  fontSize={20}
                  fontWeight={"bold"}
                >
                  Partie responsable
                </Typography>
                <Typography
                  textAlign={"justify"}
                  // fontWeight={"bold"}
                  fontSize={16}
                  mb={2}
                >
                  {`${localrouter.state.itemv?.tpartiprenante?.designaionp.toUpperCase()}`}
                </Typography>
              </Stack>

              {/*
           <Divider />
          */}
              <Stack
                containe
                direction={"column"}
                spacing={2}
                //   bgcolor={"red"}
                //   width={"100%"}
              >
                <Grid item lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Minerais extraits
                  </Typography>
                  <Typography textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv?.type_mine}`}
                  </Typography>
                </Grid>
                <Stack direction={"row"} spacing={5}>
                  <div>
                    <Typography
                      textAlign={"center"}
                      fontSize={20}
                      fontWeight={"bold"}
                    >
                      Nombre des creuseurs
                    </Typography>
                    <Typography textAlign={"center"} fontSize={16}>
                      {`${localrouter.state.itemv?.nombre_creuseur}`}
                    </Typography>
                  </div>

                  <div>
                    <Typography
                      textAlign={"center"}
                      fontSize={20}
                      fontWeight={"bold"}
                    >
                      Nombre des travailleurs
                    </Typography>
                    <Typography textAlign={"center"} fontSize={16}>
                      {`${localrouter.state.itemv?.nobre_travailleur}`}
                    </Typography>
                  </div>
                </Stack>

                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"end"}
                    fontSize={20}
                    fontWeight={"bold"}
                    mr={5}
                  >
                    Date
                  </Typography>
                  <Typography textAlign={"end"} fontSize={16}>
                    {`${localrouter.state.itemv?.datecreate}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"right"} mb={2}>
          <Boutons title="Imprimer" onClick={() => Imprimer()} />
        </Grid>
      </Box>
    </Box>
  );
}

export default EmplacementDetial;
