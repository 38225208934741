import React, { useEffect, useState } from "react";
import { request } from "../../url/request";

function useMessage() {
  const [data, setData] = useState();
  const [datar, setDatar] = useState();
  const [datac, setDatac] = useState();

  const getTotalMessageProduction = () => {
    request
      .post("production/messageproduction")
      .then((e) => {
        // console.log("akdkasdk:>)", e.data);
        setData(e.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getTotalMessageProductionReception = () => {
    request
      .post("production/messageproductionreception")
      .then((e) => {
        // console.log("akdkasdk:>)", e.data);
        setDatar(e.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getTotalMessageCentreNegoce = () => {
    request
      .post("production/messagecentrenegoce")
      .then((e) => {
        // console.log("akdkasdk:>)", e.data);
        setDatac(e.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    getTotalMessageProduction();
    getTotalMessageProductionReception();
    getTotalMessageCentreNegoce();
  }, []);
  return {
    data,
    datar,
    datac,
  };
}

export default useMessage;
