import React, { useEffect, useState } from "react";
import Mtables from "../controller/Mtables";
import { Grid, Typography, Stack } from "@mui/material";
import { Edit, Search } from "@mui/icons-material";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Plaigniant
      </Typography>
    ),
    selector: (row) => row.personnesave,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Designation Taxe
      </Typography>
    ),
    selector: (row) => row.titretaxe,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Montant
      </Typography>
    ),
    selector: (row) => row.montant,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Service
      </Typography>
    ),
    selector: (row) => row.service,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
];

function ListeTypeTaxe() {
  const [data2, setData] = React.useState([]);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [etat, setEtat] = useState(false);
  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataFraudeCentreNegoce = () => {
    // setEtat(false);
    request
      .get("taxe")
      .then((e) => {
        let itm = e.data.data.map((e) => {
          return {
            personnesave: e?.personnesave,
            titretaxe: e?.titretaxe,
            service: e?.service,
            montant: e?.montant,
            date: e?.datecreate,
          };
        });
        setData(itm);
        // setEtat(true);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  useEffect(() => {
    getDataFraudeCentreNegoce();
  }, []);

  const dataFiltre = data2.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.service &&
        itemx.service.toUpperCase().includes(searchQuery.toUpperCase())
  );

  return (
    <div
      style={{
        minHeight: "100%",
      }}
    >
      <Grid container flexDirection={"column"} minHeight={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <h1></h1>
          <div>
            <TextFields
              value={searchQuery}
              onChange={(e) => {
                Recherche(e.target.value);
              }}
              icons={
                <Search
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
              placeholder="search..."
            />
          </div>
        </Stack>
        <Mtables
          title="Liste des taxes demandés dans les structures de service"
          data={dataFiltre}
          columns={column}
        />
      </Grid>
    </div>
  );
}

export default ListeTypeTaxe;
