import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import {
  Dangerous,
  Description,
  Man2,
  Map,
  Person,
  Save,
} from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneNumbers from "../controller/PhoneNumber";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import UploadAndDisplayImage from "../controller/UploadImage";
import NotifySuccess from "../Notification/NotifySuccess";
import { requestFD } from "../url/requesFD";
import ChecBoxEntite from "../controller/chaecBoxEntite";
var Spinkit = require("react-spinkit");

const datasexe = [
  { value: "Masculin", label: "Masculin" },
  { value: "Féminin", label: "Féminin" },
];

function InputAgentEntiteDeTraitement({ fonct, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [phone, setPhone] = React.useState(
    dataUpdate?.tagententiteproduction?.tpersonne.phone
  );
  const [genre, setGenre] = React.useState("");
  const [datarole, setDataRole] = React.useState([]);
  const { checkbx, checkboxValues } = ChecBoxEntite({ data: datarole });
  const [datacooperative, setDataCooperative] = React.useState([]);
  const [labelcooperative, setLabelCooperative] = React.useState("");

  //get all cooperative
  const getEntitetriatement = () => {
    request
      .get("entitedetraitement")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          console.log(ex);
          return {
            value: ex.identite,
            label: ex.designatio,
          };
        });
        setDataCooperative(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get all cooperative
  const getCooperative = () => {
    request
      .get("roleentiteproduction")
      .then((e) => {
        setDataRole(e.data.data);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  useEffect(() => {
    getCooperative();
    getEntitetriatement();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: dataUpdate?.tagententiteproduction?.tpersonne.nom,
      postnom: dataUpdate?.tagententiteproduction?.tpersonne.postnom,
      prenom: dataUpdate?.tagententiteproduction?.tpersonne.prenom,
      adresse: dataUpdate?.tagententiteproduction?.tpersonne.adresse,
      xdt: dataUpdate?.tagententiteproduction?.tpersonne.datenaiss,
      //   description: dataUpdate === "" ? "" : dataUpdate.fonction,
      //   pwd: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Ce champs est requis"),
      postnom: Yup.string().required("Ce champs est requis"),
    }),

    onSubmit: (values) => {
      //   console.log("==>:", checkboxValues);
      const personefdata = new FormData();
      if (genre === "" || genre === undefined || genre === null) {
        NotifyError("genre est obligatoire");
      } else if (!checkboxValues.length) {
        NotifyError("selection au moin un role");
      } else if (
        labelcooperative === "" ||
        labelcooperative === undefined ||
        labelcooperative === null
      ) {
        NotifyError("l'entité de traitement est obligatoire");
      } else {
        if (dataUpdate === "") {
          //nouvelle enregistrement
          personefdata.append("nom", values.nom);
          personefdata.append("postnom", values.postnom);
          personefdata.append("prenom", values.prenom);
          personefdata.append("adresse", values.adresse);
          personefdata.append("datenaiss", values.xdt);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          setEtat(true);
          requestFD
            .post("personne/save", personefdata)
            .then((e) => {
              //   console.log("====> personne:====>:", e.data.data);
              let jsonPerson = {
                idpersonne: e.data.data.idpersonne,
                password: values.pwd,
                dataitempont: checkboxValues,
                fkentiteprodus: labelcooperative.value,
              };
              request
                .post("agententiteproduction/save", jsonPerson)
                .then((ex) => {
                  NotifySuccess(ex.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.request) {
                    NotifyError(error.request);
                    setEtat(false);
                  } else if (error.response) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else if (error.config) {
                    NotifyError(error.config);
                    setEtat(false);
                  } else {
                    NotifyError(error.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.response.data.message);
                // setDisable(false);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        } else {
          // modification;
          let idperso = dataUpdate?.tagentgouvernement?.tpersonne?.idpersonne;
          console.log("======>", idperso);

          personefdata.append("nom", values.nom);
          personefdata.append("postnom", values.postnom);
          personefdata.append("prenom", values.prenom);
          personefdata.append("adresse", values.adresse);
          personefdata.append("datenaiss", values.xdt);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          personefdata.append("idpersonne", idperso);
          setEtat(true);
          requestFD
            .put("personne/update", personefdata)
            .then((e) => {
              // console.log(e.data.data);
              let jsonPerson = {
                idpersonne: idperso,
                password: values.pwd,
                dataitempont: checkboxValues,
                fkentiteprodus: labelcooperative.value,
              };
              request
                .put("agententiteproduction/update", jsonPerson)
                .then((ex) => {
                  NotifySuccess(ex.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.request) {
                    NotifyError(error.request);
                    setEtat(false);
                  } else if (error.response) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else if (error.config) {
                    NotifyError(error.config);
                    setEtat(false);
                  } else {
                    NotifyError(error.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.message);
                // setDisable(false);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Stack
            sx={{
              mt: 5,
              mb: 1,
            }}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            {UploadImage()}
          </Stack>
          <Stack direction={"row"} spacing={5}>
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                Cordonne de l'agent
              </Typography>
              <Divider
                sx={{
                  m: 1,
                }}
              />
              <Stack direction={"row"} spacing={2}>
                <div>
                  <TextFields
                    // label="Nom"
                    placeholder="nom"
                    id={"nom"}
                    onBlur={validation.handleBlur}
                    value={validation.values.nom}
                    onChange={validation.handleChange}
                    error={
                      validation.touched.nom && validation.errors.nom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.nom && validation.errors.nom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.nom}
                    </h6>
                  ) : null}
                </div>
                <div>
                  <TextFields
                    // label="Postnom"
                    placeholder="postnom"
                    id={"postnom"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.postnom}
                    error={
                      validation.touched.postnom &&
                      validation.errors.postnom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.postnom && validation.errors.postnom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.postnom}
                    </h6>
                  ) : null}
                </div>
              </Stack>
              <div>
                <TextFields
                  // label="Prenom"
                  placeholder="prenom"
                  id={"prenom"}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.prenom}
                  error={
                    validation.touched.prenom && validation.errors.prenom
                      ? true
                      : false
                  }
                  icons={
                    <Person
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.prenom && validation.errors.prenom ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.prenom}
                  </h6>
                ) : null}
              </div>
              <div>
                <Comboboxs
                  value={genre}
                  onChange={(e) => setGenre(e)}
                  itcos={
                    <Man2
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  data={datasexe}
                  placeholder="genre"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <PhoneNumbers value={phone} onChange={(e) => setPhone(e)} />
              </div>
              <div>
                <TextFields
                  id={"xdt"}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.xdt}
                  error={
                    validation.touched.xdt && validation.errors.xdt
                      ? true
                      : false
                  }
                  placeholder="Date de naissance"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.xdt && validation.errors.xdt ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.xdt}
                  </h6>
                ) : null}
              </div>
              <div>
                <TextFields
                  // label="Adresse"
                  id="adresse"
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.adresse}
                  error={
                    validation.touched.adresse && validation.errors.adresse
                      ? true
                      : false
                  }
                  placeholder="adresse"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.adresse && validation.errors.adresse ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.adresse}
                  </h6>
                ) : null}
              </div>
            </div>
            <Divider
              sx={{
                borderWidth: 1,
              }}
            />
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                Connexions
              </Typography>
              <Divider
                sx={{
                  m: 1,
                }}
              />
              <Comboboxs
                itcos={
                  <Dangerous
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
                placeholder="Cooperative"
                value={labelcooperative}
                onChange={(e) => setLabelCooperative(e)}
                data={datacooperative}
              />

              {checkbx}
              <div>
                <TextFields
                  type={"password"}
                  placeholder="mot de passe"
                  id={"pwd"}
                  //   multiline={true}
                  value={validation.values.pwd}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  error={
                    validation.touched.pwd && validation.errors.pwd
                      ? true
                      : false
                  }
                  icons={
                    <Description
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.pwd && validation.errors.pwd ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.pwd}
                  </h6>
                ) : null}
              </div>
              <Stack
                sx={{
                  // width: "93%",
                  mt: 2,
                  // ml: 5,
                }}
              >
                {dataUpdate === "" ? (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Enregistrer"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                ) : (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Update"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                )}
              </Stack>
            </div>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputAgentEntiteDeTraitement;
