import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import { Save } from "@mui/icons-material";
import useProduction from "../hooks/production/useProduction";
import useMinerai from "../hooks/Minerai/useMinerai";
import useEmplacement from "../hooks/Emplacement/useEmplacement";
var Spinkit = require("react-spinkit");

function InputProduction({ foncget, dataUpdate }) {
  const { datasite } = useEmplacement();
  const { datamine } = useMinerai();
  const {
    saveProduction,
    upDateProduction,
    UploadImage,
    selectedImage,
    UploadImage2,
    selectedImage2,
    labelSite,
    setLabelSite,
    labelmine,
    setLabelMine,
    imgageseamap,
    setImageSAMAP,
    imgagedivimine,
    setDivimine,
    disable,
  } = useProduction();
  const datax = JSON.parse(localStorage.getItem("data"));

  //choix de la selection
  const getRadioImageSEAMAP = (e) => {
    setImageSAMAP(!imgageseamap);
    // console.log(e.target.value);
  };
  const getRadioImageDivimine = (e) => {
    setDivimine(!imgagedivimine);
  };

  useEffect(() => {}, [imgageseamap]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      idemplacement: labelSite.value
        ? labelSite.value
        : dataUpdate?.templacement?.idemplacement,
      //    dataUpdate?.templacement?.idemplacement,
      idminerai: labelmine.value
        ? labelmine.value
        : dataUpdate?.tminerai?.idminerai,
      nompuit: dataUpdate?.nompuit,
      quantitebruite: dataUpdate?.quantitebruite,
      taget: dataUpdate?.taget,
      embale: dataUpdate?.embale,
      idpersonne: "",
    },

    validationSchema: Yup.object({
      taget: Yup.string().required("la taget est obligatoire"),
      quantitebruite: Yup.string().required("la quantité est obligatoire"),
      embale: Yup.string().required("Emballage est obligatoire"),
      nompuit: Yup.string().required("Nom du puit est obligatoire"),
    }),
    onSubmit: (values) => {
      const formdata = new FormData();
      if (dataUpdate === "") {
        if (labelSite === "" || labelSite === null || labelSite === undefined) {
          NotifyError("L'emplacementest obligatoire");
        } else if (
          labelmine === "" ||
          labelmine === null ||
          labelmine === undefined
        ) {
          NotifyError("selectionner le minerai");
        } else {
          //enregistrement
          formdata.append("idpersonne", datax.agent.idagent); //datax.agent.idagent
          formdata.append("taget", values.taget);
          formdata.append("nommine", labelmine.label);
          formdata.append("nomsitemine", labelSite.label);
          formdata.append("idmine", labelmine.value);
          formdata.append("idemplacementsx", labelSite.value);
          formdata.append("nompuit", values.nompuit);
          formdata.append("quantitebruite", values.quantitebruite);
          formdata.append("embale", values.embale);
          formdata.append("file", selectedImage);
          formdata.append("file", selectedImage2);
          saveProduction({ data: formdata, foncget: foncget });
        }
      } else {
        //modification
        formdata.append("idpersonne", datax.agent.idagent); //datax.agent.idagent
        formdata.append("taget", values.taget);
        formdata.append("nommine", labelmine.label);
        formdata.append("nomsitemine", labelSite.label);
        formdata.append("idmine", labelmine.value);
        formdata.append("idemplacementsx", labelSite.value);
        formdata.append("nompuit", values.nompuit);
        formdata.append("quantitebruite", values.quantitebruite);
        formdata.append("embale", values.embale);
        formdata.append("file", selectedImage);
        formdata.append("file", selectedImage2);
        formdata.append("idproduction", dataUpdate?.idproduction);
        upDateProduction({ data: formdata, foncget: foncget });
      }
    },
  });
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Grid container m={2}>
          <Grid item xs={"auto"} md={6} sm={"auto"} lg={6} mr={3}>
            <Comboboxs
              data={datasite}
              value={labelSite}
              onChange={(e) => setLabelSite(e)}
              placeholder="Site Minier"
              label={"Site Minier"}
            />
            <Comboboxs
              data={datamine}
              value={labelmine}
              onChange={(e) => setLabelMine(e)}
              placeholder="Minerais"
              label={"Minerais"}
            />
            <div>
              <TextFields
                label="Tag"
                placeholder="Tag mine"
                id={"taget"}
                onBlur={validation.handleBlur}
                value={validation.values.taget}
                onChange={validation.handleChange}
                error={
                  validation.touched.taget && validation.errors.taget
                    ? true
                    : false
                }
                labelError={
                  validation.touched.taget &&
                  validation.errors.taget &&
                  validation.errors.taget
                }
              />
            </div>
            <div>
              <TextFields
                label="Nom du puit"
                placeholder="Nom du puit"
                id={"nompuit"}
                onBlur={validation.handleBlur}
                value={validation.values.nompuit}
                onChange={validation.handleChange}
                error={
                  validation.touched.nompuit && validation.errors.nompuit
                    ? true
                    : false
                }
                labelError={
                  validation.touched.nompuit &&
                  validation.errors.nompuit &&
                  validation.errors.nompuit
                }
              />
            </div>
            <div>
              <TextFields
                type={"number"}
                label={"Poids du colis"}
                placeholder="Poids du colis"
                id={"quantitebruite"}
                onBlur={validation.handleBlur}
                value={validation.values.quantitebruite}
                onChange={validation.handleChange}
                error={
                  validation.touched.quantitebruite &&
                  validation.errors.quantitebruite
                    ? true
                    : false
                }
                labelError={
                  validation.touched.quantitebruite &&
                  validation.errors.quantitebruite &&
                  validation.errors.quantitebruite
                }
              />
            </div>
          </Grid>
          <Grid item xs={"auto"} md={5} sm={"auto"} lg={5} mt={-1}>
            <div>
              <TextFields
                label="Type d'emballage"
                placeholder="Type d'emballage"
                id={"embale"}
                onBlur={validation.handleBlur}
                value={validation.values.embale}
                onChange={validation.handleChange}
                error={
                  validation.touched.embale && validation.errors.embale
                    ? true
                    : false
                }
                labelError={
                  validation.touched.embale &&
                  validation.errors.embale &&
                  validation.errors.embale
                }
              />
            </div>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Typography>
                Avez-vous le document delivré par le SAEMAPE?
              </Typography>
              <FormControl
                component={"fieldset"}
                sx={{
                  flexDirection: "row",
                }}
              >
                <RadioGroup
                  sx={{
                    flexDirection: "row",
                  }}
                  value={imgageseamap}
                  onChange={getRadioImageSEAMAP}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
              {!imgageseamap === true ? (
                ""
              ) : (
                <Stack
                  sx={{
                    mt: 5,
                    mb: 1,
                  }}
                  // justifyContent={"center"}
                  // alignItems={"center"}
                >
                  {UploadImage()}
                </Stack>
              )}

              <div style={{ marginTop: 10 }}>
                <Typography>
                  Avez-vous le document delivré par la DIVIMINES?
                </Typography>
                <FormControl component={"fieldset"}>
                  <RadioGroup
                    value={imgagedivimine}
                    onChange={getRadioImageDivimine}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Non"
                    />
                  </RadioGroup>
                </FormControl>
                {imgagedivimine === false ? (
                  ""
                ) : (
                  <Stack
                    sx={{
                      mt: 5,
                      mb: 1,
                    }}
                    // justifyContent={"center"}
                    // alignItems={"center"}
                  >
                    {UploadImage2()}
                  </Stack>
                )}
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                width: "100%",
              }}
            >
              <Boutons
                type={"submit"}
                width={"100%"}
                icons={
                  disable === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title={dataUpdate === "" ? "Enregistrer" : "Modification"}
                disable={disable}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default InputProduction;
