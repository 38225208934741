import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../navigation/Drawers";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack, Check, Dangerous } from "@mui/icons-material";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import Boutons from "../controller/Boutons";
import { useReactToPrint } from "react-to-print";

function VisibilityProduction() {
  const [dataProduction, setDataProduction] = useState("");
  const { itemv } = useLocation().state;
  const nav = useNavigate();
  const prints = useRef();
  // console.log(itemv);

  //get le information de la production pour l'evaluation de tous le coorder si ca existe

  const getProduction = (x) => {
    request
      .get(`production/${x}`)
      .then((e) => {
        console.log("tstxs====>", e.data.data);
        setDataProduction(e.data.data);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
        } else {
          NotifyError("veillez actualiser la page");
        }
      });
  };

  useEffect(() => {
    getProduction(itemv?.tproduction?.idproduction);
  }, []);

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: "liste victime",
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />

      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          // right: 10,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div ref={prints}>
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
              width: "98%",
            }}
          >
            Chaine de production
          </h4>
          <div
            style={{
              background: "#013062",

              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
              width: "98%",
              color: "white",
            }}
          >
            <h4
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "white",
                padding: 20,
              }}
            >
              ID de la production :{" "}
              {itemv?.tages}
            </h4>
            <h4
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "white",
                marginTop: -50,
                paddingLeft: 20,
                paddingBottom: 20,
              }}
            >
              Nom du Négociant :{" "}
              {`${itemv.tnegocieant?.tpersonne?.nom.toUpperCase()} - ${itemv.tnegocieant?.tpersonne?.postnom.toUpperCase()}`}
            </h4>
          </div>
          <Grid
            container
            direction={"row"}
            sx={{
              bgcolor: "white",
              p: 2,
              mt: -3,
              width: "98%",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={{
                borderColor: "#013062",
                borderWidth: 3,
                borderStyle: "solid",
                padding: 2,
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <Typography>
                Date production : {itemv?.tproduction?.datecreate}{" "}
              </Typography>
              <Typography>
                Site de provenance :{" "}
                {itemv?.tproduction?.tpontsiteminetypemine?.templacement?.designationsite.toUpperCase()}
              </Typography>
              <Typography>
                Puit de provenance : {itemv?.tproduction?.nompuit.toUpperCase()}
              </Typography>
              <Typography>
                Quantité produite : {itemv?.tproduction?.quantitebruite}KG
              </Typography>
              <Typography>
                Quantité Arrivée : {itemv?.tproduction?.quantitearrive}KG
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              ml={5}
              sx={{
                borderColor: "#013062",
                borderWidth: 3,
                borderStyle: "solid",
                padding: 2,
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <Typography>
                Minerai :{" "}
                {itemv?.tproduction?.tpontsiteminetypemine?.tminerai?.designation.toUpperCase()}
              </Typography>
              {/*
                <Typography>Cette production est-elle conforme :</Typography>
              <Typography>
                Risque liés à la fraude et à la corruption :
              </Typography>
                */}
              <Typography>
                Coopérative :{" "}
                {itemv?.tproduction?.tpontsiteminetypemine?.templacement?.tpartiprenante?.designaionp.toUpperCase()}{" "}
              </Typography>
              <Typography
                sx={{
                  color: dataProduction?.alertediplication ? "red" : "",
                  fontWeight: dataProduction?.alertediplication ? "bolder" : "",
                }}
              >
                Etat du minerai:
                {dataProduction?.alertediplication
                  ? "Cette marchandise est en Etat de Fraude"
                  : "Cette marchandise n'est pas en Etat de Fraude"}
              </Typography>
            </Grid>
          </Grid>
          <Stack flexDirection={"row"} m={5}>
            <Tooltip
              arrow
              title={
                dataProduction?.photodivimine && dataProduction?.photoseamap
                  ? "Conforme"
                  : dataProduction?.photodivimine || dataProduction?.photoseamap
                  ? "Un composant manquant"
                  : "Non conforme, plusieurs documents de ventes sont manquants"
              }
            >
              <Card
                sx={{
                  p: 5,
                  bgcolor:
                    dataProduction?.photodivimine && dataProduction?.photoseamap
                      ? "green"
                      : dataProduction?.photodivimine ||
                        dataProduction?.photoseamap
                      ? "yellow"
                      : "red",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    fontSize: 16,
                    color: "#FFF",
                  }}
                >
                  Site minier d'origine
                </Typography>
              </Card>
            </Tooltip>
            <Typography
              sx={{
                fontWeight: "bolder",
                fontSize: 50,
                m: 2,
              }}
            >
              ---
            </Typography>
            <Tooltip
              arrow
              title={
                itemv?.photobonachatceec && itemv?.photobonachatseamep
                  ? "Conforme"
                  : itemv?.photobonachatceec || itemv?.photobonachatseamep
                  ? "Un composant manquante"
                  : "Non conforme, plusieurs documents de ventes sont manquants"
              }
            >
              <Card
                sx={{
                  p: 5,
                  bgcolor:
                    itemv?.photobonachatceec && itemv?.photobonachatseamep
                      ? "green"
                      : itemv?.photobonachatceec || itemv?.photobonachatseamep
                      ? "yellow"
                      : "red",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    fontSize: 16,
                    color: "#FFF",
                  }}
                >
                  Point d'achat
                </Typography>
              </Card>
            </Tooltip>
            <Typography
              sx={{
                fontWeight: "bolder",
                fontSize: 50,
                m: 2,
              }}
            >
              ---
            </Typography>
            <Tooltip
              arrow
              title={itemv?.titretat ? "Conforme" : "Non conforme p"}
            >
              <Card
                sx={{
                  p: 5,
                  bgcolor: itemv?.titretat === "achat" ? "green" : "red",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    fontSize: 16,
                    color: "#FFF",
                  }}
                >
                  Entité de traitement
                </Typography>
              </Card>
            </Tooltip>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              m: 5,
              justifyContent: "flex-end",
            }}
          >
            <Boutons title={"Imprimer"} onClick={() => Imprimer()} />
          </Stack>
        </div>
      </Box>
    </Box>
  );
}

export default VisibilityProduction;
