import { Grid, Stack } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import { Dangerous, Description, Map, Person, Save } from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { useFormik } from "formik";
import NotifySuccess from "../Notification/NotifySuccess";
import UploadAndDisplayImage from "../controller/UploadImage";
import { requestFD } from "../url/requesFD";
var Spinkit = require("react-spinkit");

const dataSource = [
  {
    value: 1,
    label: "Propre",
  },
  {
    value: 2,
    label: "Indirect",
  },
];

function InputEvenement({ fonct, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  //avariable de datasorce
  const [datasorces, setDataSorces] = React.useState();
  //declaretion type vulnerabilte
  const [datatypevulnerable, setDataTypevulnerable] = React.useState([]);
  const [valutypevulnerabilite, setTypevulnerabilte] = React.useState();
  //declareation emplacement
  const [dataemplacement, setDataemplacement] = React.useState([]);
  const [valuteemlacement, setValueemplacement] = React.useState();

  //declaration de la partie prenante
  const [datapartieprenante, setDataPartiePrenante] = React.useState([]);
  const [valuepartieprenant, setValuePartiePrenante] = React.useState("");

  //declaration de type evenemt
  const [datatypeevenemt, setDataTypeEvenement] = React.useState([]);
  const [valuetypeevenement, setValueTYpeEvenement] = React.useState("");

  let imtmdatalogin = JSON.parse(localStorage.getItem("data"));

  // console.log("itmdata: ", dataUpdate);

  //get partie type evenemt
  const getTypeEvenement = () => {
    request
      .get("typeevenement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idtypeevenement,
            label: ex.designationtypeev,
          };
        });
        setDataTypeEvenement(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get partie prenante
  const getPartiePrenante = () => {
    request
      .get("partieprenante")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idpartieprenante,
            label: ex.designaionp,
          };
        });
        setDataPartiePrenante(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get emplacement
  const getEmplacement = () => {
    request
      .get("emplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idemplacement,
            label: ex.designationsite,
          };
        });
        setDataemplacement(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get type type vulnerabilite
  const getTypeVulnerable = () => {
    request
      .get("typevunerabilite/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          return {
            value: ex.idtypevulnerabilite,
            label: ex.designationv,
          };
        });
        // console.log(itm);
        setDataTypevulnerable(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };
  React.useEffect(() => {
    getTypeVulnerable();
    getEmplacement();
    getPartiePrenante();
    getTypeEvenement();
    // console.log("itmdata: ", imtmdatalogin);
  }, []);

  const valudation = useFormik({
    enableReinitialize: true,

    initialValues: {
      titreev: dataUpdate?.titreev,
      descryptionev: dataUpdate?.descryptionev,
      idtypevulnerabilite: valutypevulnerabilite
        ? valutypevulnerabilite.value
        : "",
      idemplacement: valuteemlacement ? valuteemlacement.value : "",
      idpartieprenante: valuepartieprenant ? valuepartieprenant.value : "",
      idtypeevenement: valuetypeevenement ? valuetypeevenement.value : "",
      personnevunerable: dataUpdate === "" ? "" : dataUpdate.personnevunerable,
      idagent: imtmdatalogin.agent.idagent,
      idevenement: dataUpdate === "" ? "" : dataUpdate.idevenement,
      nomporteurInsident: dataUpdate?.nomduhoteuricident,
      date: dataUpdate?.date,
    },
    validationSchema: Yup.object({
      titreev: Yup.string().required("Le titre est obligatoire"),
    }),
    onSubmit: (value) => {
      let dataformat = new FormData();
      if (
        valutypevulnerabilite === "" ||
        valutypevulnerabilite === null ||
        valutypevulnerabilite === undefined
      ) {
        NotifyError("Type de vulnerabilite est obligatoire");
      } else if (
        valuteemlacement === "" ||
        valuteemlacement === null ||
        valuteemlacement === undefined
      ) {
        NotifyError("L'emplacement est obligatoire");
      } else if (
        valuepartieprenant === "" ||
        valuepartieprenant === null ||
        valuepartieprenant === undefined
      ) {
        NotifyError("La partie prenante est obligatoire");
      } else if (
        valuetypeevenement === "" ||
        valuetypeevenement === null ||
        valuetypeevenement === undefined
      ) {
        NotifyError("Type evenement est obligatoire");
      } else {
        // enregistrement
        setEtat(true);
        if (dataUpdate === "") {
          console.log(datasorces.label);
          request
            .post("evenement/save1", {
              titreev: value.titreev,
              descryptionev: value.descryptionev,
              idtypevulnerabilite: valutypevulnerabilite
                ? valutypevulnerabilite.value
                : "",
              idemplacement: valuteemlacement ? valuteemlacement.value : "",
              idpartieprenante: valuepartieprenant
                ? valuepartieprenant.value
                : "",
              idtypeevenement: valuetypeevenement
                ? valuetypeevenement.value
                : "",
              personnevunerable: value.personnevunerable,
              idagent: imtmdatalogin.agent.idagent,
              idevenement: dataUpdate === "" ? "" : dataUpdate.idevenement,
              nomduhoteuricidents: value.nomporteurInsident,
              soucre: datasorces.value,
              date: value.date,
            })
            .then((e) => {
              dataformat.append("idevenement", e.data.data.idevenement);
              dataformat.append("file", selectedImage);
              requestFD
                .post("image/save1", dataformat)
                .then((eim) => {
                  NotifySuccess(eim.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.request) {
                    NotifyError(error.request);
                    setEtat(false);
                  } else if (error.response) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else if (error.config) {
                    NotifyError(error.config);
                    setEtat(false);
                  } else {
                    NotifyError(error.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          //modification
          // console.log(value);
          request
            .put("evenement/update", {
              titreev: value.titreev,
              descryptionev: value.descryptionev,
              idtypevulnerabilite: valutypevulnerabilite
                ? valutypevulnerabilite.value
                : "",
              idemplacement: valuteemlacement ? valuteemlacement.value : "",
              idpartieprenante: valuepartieprenant
                ? valuepartieprenant.value
                : "",
              idtypeevenement: valuetypeevenement
                ? valuetypeevenement.value
                : "",
              personnevunerable: value.personnevunerable,
              idagent: imtmdatalogin.agent.idagent,
              idevenement: dataUpdate === "" ? "" : dataUpdate.idevenement,
              nomduhoteuricidents: value.nomporteurInsident,
              date: value.date,
              soucre: datasorces.value,
            })
            .then((e) => {
              // console.log("id image ===> ", dataUpdate.timage.idimage);
              if (dataUpdate.timage === null) {
                //insertion dans la table image
                dataformat.append("idevenement", dataUpdate.idevenement);
                dataformat.append("file", selectedImage);
                requestFD
                  .post("image/save", dataformat)
                  .then((eim) => {
                    NotifySuccess(eim.data.message);
                    fonct();
                    setEtat(false);
                  })
                  .catch((error) => {
                    if (error.request) {
                      NotifyError(error.request);
                      setEtat(false);
                    } else if (error.response) {
                      NotifyError(error.response.data.message);
                      setEtat(false);
                    } else if (error.config) {
                      NotifyError(error.config);
                      setEtat(false);
                    } else {
                      NotifyError(error.message);
                      setEtat(false);
                    }
                  });
              } else {
                dataformat.append("idevenement", dataUpdate.idevenement);
                dataformat.append("idimage", dataUpdate.timage.idimage);
                dataformat.append("file", selectedImage);
                requestFD
                  .put("image/update", dataformat)
                  .then((ex) => {
                    NotifySuccess(ex.data.message);
                    fonct();
                    setEtat(false);
                  })
                  .catch((error) => {
                    if (error.request) {
                      NotifyError(error.request);
                      setEtat(false);
                    } else if (error.response) {
                      NotifyError(error.response.data.message);
                      setEtat(false);
                    } else if (error.config) {
                      NotifyError(error.config);
                      setEtat(false);
                    } else {
                      NotifyError(error.message);
                      setEtat(false);
                    }
                  });
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          valudation.handleSubmit();
          return false;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid item md={12} sm={12} lg={12}>
          <Stack
            sx={{
              mt: 5,
              // mb: 0.2,
            }}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            {UploadImage()}
          </Stack>
          <Grid container>
            <Grid item xs={12} md={5} sm={12} mr={2}>
              <Stack>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Comboboxs
                    data={datatypevulnerable}
                    value={valutypevulnerabilite}
                    onChange={(e) => setTypevulnerabilte(e)}
                    itcos={
                      <Dangerous
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                    // label={"Type de vulnerabilité"}
                    placeholder="Risque associé"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Comboboxs
                    placeholder="lieu de l'évènement"
                    value={valuteemlacement}
                    onChange={(e) => setValueemplacement(e)}
                    data={dataemplacement}
                    itcos={
                      <Map
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
                <Comboboxs
                  itcos={
                    <Dangerous
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  placeholder="Partie prenante/Cooperative/Negociant impliqué(e)"
                  value={valuepartieprenant}
                  onChange={(e) => setValuePartiePrenante(e)}
                  data={datapartieprenante}
                />
                <Comboboxs
                  itcos={
                    <Dangerous
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  placeholder="Type d'évènement"
                  value={valuetypeevenement}
                  onChange={(e) => setValueTYpeEvenement(e)}
                  data={datatypeevenemt}
                />
                <Comboboxs
                  data={dataSource}
                  value={datasorces}
                  onChange={(e) => setDataSorces(e)}
                  itcos={
                    <Dangerous
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  // label={"Type de vulnerabilité"}
                  placeholder="Source d'information"
                />
                <TextFields
                  value={valudation.values.titreev}
                  id={"titreev"}
                  onChange={valudation.handleChange}
                  onBlur={valudation.handleBlur}
                  error={
                    valudation.touched.titreev && valudation.errors.titreev
                      ? true
                      : false
                  }
                  placeholder="Titre de l'evenement"
                  label={"Titre de l'evenement"}
                  icons={
                    <Description
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                <p
                  style={{
                    fontSize: 9,
                    color: "red",
                    marginTop: -1,
                  }}
                >
                  {valudation.touched.titreev && valudation.errors.titreev
                    ? valudation.errors.titreev
                    : false}
                </p>
                <TextFields
                  value={valudation.values.nomporteurInsident}
                  id={"nomporteurInsident"}
                  onChange={valudation.handleChange}
                  onBlur={valudation.handleBlur}
                  error={
                    valudation.touched.nomporteurInsident &&
                    valudation.errors.nomporteurInsident
                      ? true
                      : false
                  }
                  placeholder="Auteur de l'évènement"
                  label={"Auteur de l'évènement"}
                  icons={
                    <Description
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                <p
                  style={{
                    fontSize: 9,
                    color: "red",
                    marginTop: -1,
                  }}
                >
                  {valudation.touched.nomporteurInsident &&
                  valudation.errors.nomporteurInsident
                    ? valudation.errors.nomporteurInsident
                    : false}
                </p>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} sm={12} mr={-5} mt={-1}>
              <TextFields
                value={valudation.values.descryptionev}
                id={"descryptionev"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.descryptionev &&
                  valudation.errors.descryptionev
                    ? true
                    : false
                }
                multiline={true}
                placeholder="Brève description de l'évènement"
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <p
                style={{
                  fontSize: 9,
                  color: "red",
                  marginTop: -1,
                }}
              >
                {valudation.touched.descryptionev &&
                valudation.errors.descryptionev
                  ? valudation.errors.descryptionev
                  : false}
              </p>
              <TextFields
                id={"personnevunerable"}
                value={valudation.values.personnevunerable}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.personnevunerable &&
                  valudation.errors.personnevunerable
                    ? true
                    : false
                }
                placeholder="Nom des personnées touchées"
                multiline={true}
                icons={
                  <Person
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <TextFields
                value={valudation.values.date}
                id={"date"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.date && valudation.errors.date
                    ? true
                    : false
                }
                type={"date"}
                placeholder="Titre de l'evenement"
                label={"Titre de l'evenement"}
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <p
                style={{
                  fontSize: 9,
                  color: "red",
                  marginTop: -1,
                }}
              >
                {valudation.touched.date && valudation.errors.date
                  ? valudation.errors.date
                  : false}
              </p>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                  // ml: 5,
                }}
              >
                {dataUpdate === "" ? (
                  <Boutons
                    type={"submit"}
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    width={"100%"}
                    title="Rapporter"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                ) : (
                  <Boutons
                    width={"100%"}
                    type={"submit"}
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Modification"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default InputEvenement;
