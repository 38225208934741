import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import { request2 } from "../url/request";
import { Edit, VerifiedUser } from "@mui/icons-material";

function Profil() {
  let imtmdatalogin = JSON.parse(localStorage.getItem("data"));
  document.title = "Profile | EFFICACITY SYSTEM ";
  //   console.log(imtmdatalogin);
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Profil
        </h4>

        <Stack
          sx={{
            bgcolor: "#409EFF",
            height: "30%",
            p: 5,
            borderRadius: 2,
          }}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <div>
            <img
              src={`${request2}${imtmdatalogin.identite.image}`}
              style={{
                width: 250,
                height: 250,
                borderRadius: "100%",
                // borderColor: "black",
                objectFit: "cover",
                borderWidth: 5,
              }}
              onResize={"contain"}
              alt
            />
          </div>
          <div
            style={{
              marginTop: "5%",
            }}
          >
            <IconButton
              sx={{
                bgcolor: "#FFF",
                ":hover": {
                  bgcolor: "wheat",
                },
              }}
            >
              <Edit sx={{ color: "#013266" }} />
            </IconButton>
          </div>
        </Stack>
        <Stack
          sx={{
            mt: 10,
          }}
        >
          <Typography fontWeight={"bold"} fontSize={20}>
            Identité
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography>
            {imtmdatalogin.identite.nom.toUpperCase() +
              " " +
              imtmdatalogin.identite.postnom.toUpperCase() +
              " " +
              imtmdatalogin.identite.prenom.toUpperCase()}
          </Typography>
          <Typography>{imtmdatalogin.identite.genre.toUpperCase()}</Typography>
          <Typography>{imtmdatalogin.identite.phone.toUpperCase()}</Typography>
          <Typography>
            {imtmdatalogin.identite.datenaiss.toUpperCase()}
          </Typography>
          <Typography>
            {imtmdatalogin.identite.adresse.toUpperCase()}
          </Typography>
          <Typography mt={3} fontWeight={"bold"} fontSize={20}>
            Admin
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Stack direction={"row"} spacing={2}>
            <Typography>
              {imtmdatalogin.agent.username.toUpperCase()}
            </Typography>
            <Typography>
              {imtmdatalogin.agent.etat ? (
                <VerifiedUser color="primary" />
              ) : (
                <VerifiedUser color="secondary" />
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default Profil;
