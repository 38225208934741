import React, { useEffect, useState } from "react";
import Mtables from "../controller/Mtables";
import { Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import NotifyError from "../Notification/NotifyError";
import { request } from "../url/request";
import { Check, Print, Edit, Search } from "@mui/icons-material";
import TextFields from "../controller/TextFields";
import NotifySuccess from "../Notification/NotifySuccess";
import Boutons from "../controller/Boutons";
import { useNavigate } from "react-router-dom";

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Centre de négoce
      </Typography>
    ),
    selector: (row) => row.structure,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Minerai
      </Typography>
    ),
    selector: (row) => row.minerai,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Quantité
      </Typography>
    ),
    selector: (row) => row.quantite + " KG",
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Site Minier
      </Typography>
    ),
    selector: (row) => row.site,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Designation
      </Typography>
    ),
    selector: (row) => row.designation,
    sortable: true,
    // cell: (row) => {
    //   let img = "http://localhost:8888/" + row.image;
    //   return (
    //     <Stack direction={"row"} spacing={1}>
    //       <Avatar src={img} />
    //     </Stack>
    //   );
    // },
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Etat
      </Typography>
    ),
    selector: (row) =>
      row?.etat === false ? (
        <Chip
          label="non vérifié"
          sx={{
            bgcolor: "red",
            color: "#FFF",
          }}
        />
      ) : (
        <Chip
          label="Vérifié"
          sx={{
            bgcolor: "blue",
            color: "#FFF",
          }}
        />
      ),
    sortable: true,
    // cell: (row) => {
    //   let img = "http://localhost:8888/" + row.image;
    //   return (
    //     <Stack direction={"row"} spacing={1}>
    //       <Avatar src={img} />
    //     </Stack>
    //   );
    // },
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function ListeFraudeCentreNegoce() {
  const [data2, setData] = React.useState([]);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [etat, setEtat] = useState(false);
  const [value, setValue] = useState(null);
  const nav = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataFraudeCentreNegoce = () => {
    // setEtat(false);
    request
      .get("fraude/centrenegoce")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.slice(0, 10).map((e) => {
          // console.log(e);
          return {
            structure: e?.structure,
            minerai: e?.minerai,
            quantite: e?.quantite,
            site: e?.typedesignation,
            designation: e?.designation,
            etat: e?.etat,
            date: e?.datecreate,
            action: (
              <Stack flexDirection={"row"}>
                <IconButton onClick={() => Verification(e)}>
                  <Check
                    sx={{
                      color: "blue",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() =>
                    nav("/fraude-imprimer", {
                      state: {
                        item: e,
                      },
                    })
                  }
                >
                  <Print
                    sx={{
                      color: "red",
                    }}
                  />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        // setEtat(true);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  useEffect(() => {
    getDataFraudeCentreNegoce();
  }, []);
  const dataFiltre = data2.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.structure &&
        itemx.structure.toUpperCase().includes(searchQuery.toUpperCase())
  );
  const Verification = (e) => {
    setEtat(!etat);
    setValue(e);
  };

  const Confirm = () => {
    // NotifyError(value.idfraude);
    request
      .put("fraude/verifiefraude", {
        idfraude: value.idfraude,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getDataFraudeCentreNegoce();
        setEtat(!etat);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <div
      style={{
        minHeight: "100%",
      }}
    >
      <Grid container flexDirection={"column"} minHeight={"100%"}>
        {etat === true ? (
          <Stack>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >
              Vous êtes au point d'archive la fraude
            </Typography>
            <Typography>Voullez-vous confirmé ?</Typography>
            <Stack flexDirection={"row"} mb={5} mt={2}>
              <div
                style={{
                  marginRight: 10,
                }}
              >
                <Boutons
                  title={"confirmer"}
                  bgcolor={"blue"}
                  color={"#FFF"}
                  onClick={() => Confirm()}
                />
              </div>
              <Boutons
                title={"annuller"}
                bgcolor={"red"}
                color={"#FFF"}
                onClick={() => setEtat(!etat)}
              />
            </Stack>
          </Stack>
        ) : (
          ""
        )}
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <h1></h1>
          <div>
            <TextFields
              value={searchQuery}
              onChange={(e) => {
                Recherche(e.target.value);
              }}
              icons={
                <Search
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
              placeholder="search..."
            />
          </div>
        </Stack>
        <Mtables
          title="Liste de fraude effectue dans le centre de negoce"
          data={dataFiltre}
          columns={column}
        />
      </Grid>
    </div>
  );
}

export default ListeFraudeCentreNegoce;
