import { Chip, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Mtables from "../controller/Mtables";
import { Edit, Search } from "@mui/icons-material";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import ErrorConnection from "../../outher/ErrorConnection";
import InputAchatUpdate from "./InputAchatUpdate";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Minerai
      </Typography>
    ),
    selector: (row) => row.minerai.toUpperCase(),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Quantité venu
      </Typography>
    ),
    selector: (row) => row.quantitevenu + " KG",
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Quantité reçu
      </Typography>
    ),
    selector: (row) => row.quantiterecu + " KG",
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Etat
      </Typography>
    ),
    selector: (row) =>
      row.prixachat === 0 ? (
        <Chip
          label="Non payer"
          sx={{
            color: "white",
            bgcolor: "red",
            borderRadius: 2,
          }}
        />
      ) : (
        <Chip
          label="Payer"
          sx={{
            color: "white",
            bgcolor: "blue",
            borderRadius: 2,
          }}
        />
      ),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Prix D'achat
      </Typography>
    ),
    selector: (row) => row.prixachat + " USD",
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.datecreate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function AchatListe() {
  document.title = "Liste de marchandise enregistrer | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataEmplacement = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("achat")
      .then((e) => {
        const uniqueData = e.data.data.reduce((acc, current) => {
          if (
            !acc.find(
              (item) =>
                item.idachatentitetraitement === current.idachatentitetraitement
            )
          ) {
            acc.push(current);
          }
          return acc;
        }, []);
        let itm = uniqueData.map((e) => {
          return {
            minerai:
              e?.tcentrenegoce?.tproduction?.tpontsiteminetypemine?.tminerai
                ?.designation,
            quantitevenu: e?.tcentrenegoce?.quantitevendu,
            quantiterecu: e?.quantiterecu,
            prixachat: e?.prixachat,
            datecreate: e?.datecreate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => Update(e)}>
                  <Edit color="info" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  React.useEffect(() => {
    getDataEmplacement();
  }, []);

  const dataFiltre = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.minerai &&
        itemx.minerai.toUpperCase().includes(searchQuery.toUpperCase())
  );

  // const nouveauVictiome = () => {
  //   setDataUpdate("");
  //   setOpenDialogue(!opendialigue);
  //   setTitle("Enregistrement");
  // };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Patientez</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: 2,
            // ml: -1,
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <h1></h1>
            <div>
              <TextFields
                value={searchQuery}
                onChange={(e) => {
                  Recherche(e.target.value);
                }}
                icons={
                  <Search
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                  />
                }
                placeholder="search..."
              />
            </div>
          </Stack>
          <Mtables
            title="Marchandise Reçu"
            data={dataFiltre}
            columns={column}
          />
        </Stack>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="sm"
      >
        <InputAchatUpdate
          dataUpdate={dataupdate}
          foncget={getDataEmplacement}
        />
      </Dialogs>
    </div>
  );
}

export default AchatListe;
