import { Grid, Stack } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import { Man2, Person, Save } from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneNumbers from "../controller/PhoneNumber";
import NotifyError from "../Notification/NotifyError";
import UploadAndDisplayImage from "../controller/UploadImage";
import NotifySuccess from "../Notification/NotifySuccess";
import { requestFD } from "../url/requesFD";
var Spinkit = require("react-spinkit");

const datasexe = [
  { value: "EI", label: "Entreprise individuelle (EI)" },
  {
    value: "SCOOP",
    label: "Coopérative",
  },
  { value: "SARL", label: "Société à responsabilité limitéé (SARL)" },
  { value: "ONG", label: "ONG nationale" },
  { value: "ONG", label: "ONG Internationale" },
  { value: "GOUV", label: "Agence du gouvernement" },
  { value: "SCOP", label: "Société coopérative de production (SCOP)" },
  {
    value: "SCA/SCS",
    label:
      "Société en commandite par action (SCA) et Société en commandite simple",
  },
];

function InputEntreprise({ fonct, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [phone, setPhone] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.phone
  );
  const [genre, setGenre] = React.useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      designation: dataUpdate === "" ? "" : dataUpdate.designaionp,
      adresse: dataUpdate === "" ? "" : dataUpdate.adresse,
      email: dataUpdate === "" ? "" : dataUpdate.email,
      phone: phone,
      datedebutfonctionnement: dataUpdate?.datedebutfonctionnement,
      //   type: "",
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Ce champs est requis"),
    }),

    onSubmit: (values) => {
      // console.log("==>:", values);
      const personefdata = new FormData();
      if (genre === "" || genre === undefined || genre === null) {
        NotifyError("genre est obligatoire");
      } else {
        if (dataUpdate === "") {
          //nouvelle enregistrement
          personefdata.append("designaionp", values.designation);
          personefdata.append("adresse", values.adresse);
          personefdata.append("phone", values.phone);
          personefdata.append("email", values.email);
          personefdata.append("type", genre ? genre.value : "");
          personefdata.append("file", selectedImage);
          personefdata.append(
            "datedebutfonctionnement",
            values.datedebutfonctionnement
          );
          setEtat(true);
          requestFD
            .post("partieprenante/save", personefdata)
            .then((e) => {
              // console.log(e.data.data);
              NotifySuccess(e.data.message);
              fonct();
              setEtat(false);
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          // modification;
          let idpartieprenante = dataUpdate.idpartieprenante;
          personefdata.append("designaionp", values.designation);
          personefdata.append("adresse", values.adresse);
          personefdata.append("phone", values.phone);
          personefdata.append("email", values.email);
          personefdata.append("type", genre ? genre.value : "");
          personefdata.append("file", selectedImage);
          personefdata.append("idpartieprenante", idpartieprenante);
          personefdata.append(
            "datedebutfonctionnement",
            values.datedebutfonctionnement
          );
          setEtat(true);
          requestFD
            .put("partieprenante/update", personefdata)
            .then((e) => {
              //   console.log(e.data.data);
              NotifySuccess(e.data.message);
              fonct();
              setEtat(false);
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Stack
            sx={{
              mt: 5,
              // mb: 5,
            }}
          >
            {UploadImage()}
          </Stack>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack direction={"column"}>
                <div>
                  <TextFields
                    // label="Nom"
                    placeholder="Designation"
                    id={"designation"}
                    onBlur={validation.handleBlur}
                    value={validation.values.designation}
                    onChange={validation.handleChange}
                    error={
                      validation.touched.designation &&
                      validation.errors.designation ? (
                        <h6 type="invalid">{validation.errors.designation}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.designation &&
                  validation.errors.designation ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.designation}
                    </h6>
                  ) : null}
                </div>
                <div>
                  <TextFields
                    // label="Postnom"
                    placeholder="adresse"
                    id={"adresse"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.adresse}
                    error={
                      validation.touched.adresse &&
                      validation.errors.adresse ? (
                        <h6 type="invalid">{validation.errors.adresse}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.adresse && validation.errors.adresse ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.adresse}
                    </h6>
                  ) : null}
                </div>
              </Stack>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <PhoneNumbers value={phone} onChange={(e) => setPhone(e)} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} ml={5}>
              <div>
                <TextFields
                  // label="Prenom"
                  placeholder="email"
                  id={"email"}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.email}
                  error={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                  icons={
                    <Person
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.email}
                  </h6>
                ) : null}
              </div>
              <div>
                <Comboboxs
                  value={genre}
                  onChange={(e) => setGenre(e)}
                  itcos={
                    <Man2
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  data={datasexe}
                  placeholder="Type"
                />
              </div>
              <div>
                <TextFields
                  // label="Nom"
                  placeholder="Date"
                  type={"date"}
                  id={"datedebutfonctionnement"}
                  onBlur={validation.handleBlur}
                  value={validation.values.datedebutfonctionnement}
                  onChange={validation.handleChange}
                  error={
                    validation.touched.datedebutfonctionnement &&
                    validation.errors.datedebutfonctionnement ? (
                      <h6 type="invalid">
                        {validation.errors.datedebutfonctionnement}
                      </h6>
                    ) : null
                  }
                  icons={
                    <Person
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.datedebutfonctionnement &&
                validation.errors.datedebutfonctionnement ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "30%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.datedebutfonctionnement}
                  </h6>
                ) : null}
              </div>
            </Grid>
          </Grid>

          <Stack
            sx={{
              // width: "93%",
              mt: 2,
              // ml: 5,
            }}
          >
            {dataUpdate === "" ? (
              <Boutons
                type="submit"
                icons={
                  etat === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title="Enregistrement"
                disable={etat}
                // onClick={() => Login()}
              />
            ) : (
              <Boutons
                type="submit"
                icons={
                  etat === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title="Modification"
                disable={etat}
                // onClick={() => Login()}
              />
            )}
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputEntreprise;
