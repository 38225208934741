import { DataGrid } from "@mui/x-data-grid";
import React from "react";

export default function MTableGrid({ rows, columns }) {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
    </div>
  );
}
