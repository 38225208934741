import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Emplacement from "./Emplacement";
import MiniDrawer from "../navigation/Drawers";
import Puits from "./Puits";
import Minerai from "./Minerai";
import PontMine from "./PontMine";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabsSiteMiner() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        // minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: 5,
            padding: 10,
            // marginTop: 40,
          }}
        >
          <Typography variant="h5" fontWeight={"Bolder"}>
            Site Minier
          </Typography>
          <Typography variant="h5" fontSize={14}>
            Complete toute le information du site minier
          </Typography>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Site Minier" {...a11yProps(0)} />
              <Tab label="Puits" {...a11yProps(1)} />
              <Tab label="Minerai" {...a11yProps(2)} />
              <Tab label="Minerai avec leur site" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Emplacement />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Puits />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Minerai />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <PontMine />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}
