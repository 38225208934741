import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Mtables from "../controller/Mtables";
import { Add, Edit, Search } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import ErrorConnection from "../../outher/ErrorConnection";
import InputEntiteDeTraitement from "./InputEntiteDeTraitement";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Entite
      </Typography>
    ),
    selector: (row) => row.identite,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Adresse
      </Typography>
    ),
    selector: (row) => row.genre,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Phone
      </Typography>
    ),
    selector: (row) => row.phone,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function EntiteDeTraitement() {
  document.title = "Entite de Traitement | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const getDataVunerable = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("entitedetraitement")
      .then((e) => {
        // console.log(e.data.data);
        let itm = e.data.data.map((e) => {
          return {
            identite: e?.designatio,
            genre: e?.adresse,
            phone: e?.phone,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => Update(e)}>
                  <Edit color="info" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  React.useEffect(() => {
    getDataVunerable();
  }, []);

  const dataFiltrage = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.identite &&
        itemx?.identite.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Nouveau");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Veuillez patiente</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: 2,
            // ml: -1,
          }}
        >
          <div>
            <Boutons
              onClick={() => nouveauVictiome()}
              icons={
                <Add
                  sx={{
                    mr: 2,
                  }}
                />
              }
              title="Nouveau"
            />
          </div>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <h1></h1>
            <div>
              <TextFields
                value={searchQuery}
                onChange={(e) => Recherche(e)}
                icons={
                  <Search
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                  />
                }
                placeholder="search..."
              />
            </div>
          </Stack>
          <Mtables
            title="Entite de traitement"
            data={dataFiltrage}
            columns={column}
          />
        </Stack>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputEntiteDeTraitement
          dataUpdate={dataupdate}
          fonct={getDataVunerable}
        />
      </Dialogs>
    </div>
  );
}

export default EntiteDeTraitement;
