import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputAchat({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const datax = JSON.parse(localStorage.getItem("data"));
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      quantiterecu: "",
      prixachat: "",
    },

    validationSchema: Yup.object({
      quantiterecu: Yup.string().required("la quatnite est obligatoire"),
    }),
    onSubmit: (values) => {
      setEtat(true);
      request
        .post("achat/save", {
          idagenentiteprod: datax.agent.idagent,
          idcentrenegoce: dataUpdate?.tages,
          quantiterecu: values.quantiterecu,
          prixachat: values.prixachat ? values.prixachat : 0,
        })
        .then((e) => {
          if (e.status === 200) {
            foncget();
            NotifySuccess(e.data.message);
            setEtat(false);
          }
        })
        .catch((error) => {
          if (error.code) {
            NotifyError(error.response.data.message);
            setEtat(false);
          } else {
            NotifyError(error.response.data.message);
            setEtat(false);
          }
        });
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextFields
            value={validation.values.quantiterecu}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"quantiterecu"}
            error={
              validation.touched.quantiterecu && validation.errors.quantiterecu
                ? true
                : false
            }
            placeholder="Quantité receptionnée"
            type={"number"}
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.quantiterecu && validation.errors.quantiterecu
              ? validation.errors.quantiterecu
              : false}
          </p>
          <TextFields
            type={"number"}
            value={validation.values.prixachat}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"prixachat"}
            error={
              validation.touched.prixachat && validation.errors.prixachat
                ? true
                : false
            }
            placeholder="prix d'achat"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.prixachat && validation.errors.prixachat
              ? validation.errors.prixachat
              : false}
          </p>
          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={"Enregistrer"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputAchat;
