import { Visibility } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";

function TextFields({
  label,
  id,
  value,
  onChange,
  multiline,
  placeholder,
  type,
  onBlur,
  invalide,
  error,
  visi,
  labelError,
  onChangeVisibility,
}) {
  return (
    <Box width={"100%"}>
      <div
        style={{
          width: "100%",
        }}
      >
        <p
          style={{
            color: "gray",
            // fontSize: 14,
            marginTop: 5,
            marginBottom: -10,
            // fontFamily: "Montserrat",
          }}
        >
          {label}
        </p>
        <OutlinedInput
          id={id}
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#FFF",
            marginTop: -5,
            // color: "#FFF",
            mt: 2,
          }}
          // label={label}
          value={value}
          onChange={onChange}
          size="small"
          multiline={multiline}
          rows={3}
          // variant="outlined"
          type={type}
          placeholder={placeholder}
          endAdornment={
            visi === true ? (
              <InputAdornment position="end">
                <IconButton onClick={onChangeVisibility}>
                  <Visibility
                    sx={{
                      color: "#192C56",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            )
          }
          onBlur={onBlur}
          onInvalid={invalide}
          {...(error && { error: true, heelperText: error })}
        />
        <p
          style={{
            fontSize: 9,
            color: "red",
            marginTop: -1,
          }}
        >
          {labelError}
        </p>
      </div>
    </Box>
  );
}

export default TextFields;
