import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Pagination,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Error,
  Grid3x3,
  List,
  Search,
  UpdateOutlined,
  Verified,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import InputEvenement from "./InputEvenement";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
import Comboboxs from "../controller/Comboboxs";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Evenement
      </Typography>
    ),
    selector: (row) => row.evenement,
    sortable: false,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Risque associé
      </Typography>
    ),
    selector: (row) => row.typevlnerable,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Type d'évènement
      </Typography>
    ),
    selector: (row) => row.tyevenemt,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Validiter
      </Typography>
    ),
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      return (
        <Stack direction={"row"} spacing={1} sx={{ p: 1 }}>
          {row.status === true ? (
            <Alert icon={<Verified />} severity="success">
              Verifier
            </Alert>
          ) : (
            <Alert icon={<Error />} severity="warning">
              Non verifier
            </Alert>
          )}
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}>
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

const dataChoix = [
  {
    value: "Tableau",
    label: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}>
        <Grid3x3 />
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 18,
          }}>
          Tableau
        </Typography>
      </div>
    ),
  },
  {
    value: "Liste",
    label: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}>
        <List />
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 18,
          }}>
          Liste
        </Typography>
      </div>
    ),
  },
];

function Evenement() {
  document.title = "Evenement | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [data2, setData2] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [dataUpdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [choixAffichage, setChoixAffichage] = React.useState("Liste");
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 6; // Nombre d'éléments par page

  const paginatedData = data2.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const totalPages = Math.ceil(data2.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // console.log("choix:)", choixAffichage);

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
    // console.log(e);
  };

  const getEvenement = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("evenement")
      .then((e) => {
        setData2(e.data.data);
        let itm = e.data.data.map((e) => {
          return {
            evenement: e?.titreev,
            description: e?.descryptionev,
            typevlnerable: e?.ttypevulnerabilite?.designationv,
            tyevenemt: e?.ttypeevenement?.designationtypeev,
            emplacement: e?.templacement?.designationsite,
            partieprenamte: e?.tpartiprenante?.designaionp,
            agent:
              e?.tagent?.tpersonne?.nom + "" + e?.tagent?.tpersonne?.postnom,
            date: e?.date,
            // dateupdate: e.dateupdate,
            status: e?.etat_evenement,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => upDate(e)}>
                  <Edit fontSize="medium" />
                </IconButton>

                <IconButton
                  size="medium"
                  color="success"
                  onClick={() =>
                    nav("/evenement-detaille", {
                      state: {
                        itemv: e,
                      },
                    })
                  }>
                  <Visibility fontSize="medium" />
                </IconButton>

                <IconButton
                  size="medium"
                  color="warning"
                  onClick={() => ConfirmInformation(e)}>
                  <UpdateOutlined fontSize="medium" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  const dataFilter = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : (itemx &&
          itemx.evenement &&
          itemx.evenement.toUpperCase().includes(searchQuery.toUpperCase())) ||
        itemx.typevlnerable.toUpperCase().includes(searchQuery.toUpperCase())
  );
  React.useEffect(() => {
    getEvenement();
  }, []);

  const nouveauevenement = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Enregistrement");
  };

  const upDate = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };
  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("evenement/validate", {
        idevenement: dataUpdate.idevenement,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getEvenement();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        // minHeight: "100vh",
        maxWidth: "100%",
        flexWrap: "wrap",
        overflowX: "hidden",
      }}>
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 5,
          pl: 1,
          // mr: 3,
        }}>
        <div
          style={{
            background: "white",
            borderRadius: 5,
            padding: 10,
            marginTop: 40,
          }}>
          <Typography variant="h5" fontWeight={"Bolder"}>
            Evenements
          </Typography>
          <Typography variant="h5" fontSize={14}>
            Ici vous trouverez les évènements enregistrés
          </Typography>
        </div>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Patienter</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              // bgcolor: "red",
              p: 3,
              mt: 2,
              // ml: -1,
            }}>
            <div
              style={{
                position: "absolute",
                flexWrap: "wrap",
                width: "80%",
                marginTop: "-2%",
                marginLeft: -30,
                marginBottom: 90,
                background: "#E3EAEF",
                padding: 10,
                borderRadius: 5,
              }}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 40,
                }}>
                <Boutons
                  onClick={() => nouveauevenement()}
                  icons={
                    <Add
                      sx={{
                        mr: 2,
                      }}
                    />
                  }
                  title="Nouvel évènement"
                />
                <div
                  style={{
                    // marginLeft:20,
                    minWidth: "25%",
                  }}>
                  <Comboboxs
                    label={"Affichage"}
                    data={dataChoix}
                    value={choixAffichage}
                    placeholder={"Un aperçu détaillé."}
                    onChange={(e) => setChoixAffichage(e)}
                  />
                </div>
              </div>
              {etatv === false ? (
                ""
              ) : (
                <Stack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E3EAEF",
                    marginTop: 20,
                    borderRadius: 5,
                    margin: 10,
                  }}>
                  <Typography fontWeight={"bold"} fontSize={20}>
                    Information
                  </Typography>
                  <Divider />
                  <Typography fontWeight={"bold"} fontSize={16}>
                    Etes-vous sur de valider ?
                  </Typography>
                  <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                    <IconButton
                      sx={{
                        bgcolor: "#013266",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => ValidationDataUpdateEtat()}>
                      <Check
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: "red",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => setEtatV(!etatv)}>
                      <Close
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
              <div>
                {choixAffichage?.value === "Liste" ? (
                  <div
                    style={{
                      // background: "#fff",
                      padding: 20,
                    }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "end",
                      }}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                      />
                    </div>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      // mt={-5}
                    >
                      <h1></h1>

                      <div
                        style={{
                          marginRight: 1,
                          // marginBottom: 10,
                        }}>
                        <TextFields
                          value={searchQuery}
                          onChange={(e) => {
                            Recherche(e);
                          }}
                          icons={
                            <Search
                              fontSize="large"
                              sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                            />
                          }
                          placeholder="Rechercher"
                        />
                      </div>
                    </Stack>
                    {paginatedData
                      .filter((itemx) =>
                        searchQuery.toUpperCase() === null ||
                        searchQuery.toUpperCase() === "" ||
                        searchQuery.toUpperCase() === undefined
                          ? itemx
                          : itemx?.titreev
                              .toUpperCase()
                              .toUpperCase()
                              .indexOf(searchQuery.toUpperCase()) > -1 ||
                            itemx?.titreev
                              .toUpperCase()
                              .indexOf(searchQuery.toUpperCase()) > -1
                      )
                      .map((e, index) => {
                        return (
                          <Card
                            key={index}
                            style={{
                              margin: 20,
                              padding: 20,
                            }}>
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "end",
                                marginBottom: 10,
                              }}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 18,
                                }}>
                                {e?.date}
                              </Typography>
                            </div>
                            <div>
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <div>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      textDecoration: "underLine",
                                    }}>
                                    Evenement
                                  </Typography>
                                </div>
                                <div>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    // sx={{ p: 1 }}
                                  >
                                    {e?.etat_evenement === true ? (
                                      <Alert
                                        icon={<Verified />}
                                        severity="success">
                                        Verifier
                                      </Alert>
                                    ) : (
                                      <Alert
                                        icon={<Error />}
                                        severity="warning">
                                        Non verifier
                                      </Alert>
                                    )}
                                  </Stack>
                                </div>
                              </div>

                              <Typography
                                sx={{
                                  fontSize: 18,
                                  // mr:-50
                                }}>
                                {e?.titreev}
                              </Typography>

                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                  textDecoration: "underLine",
                                  mt: 2,
                                }}>
                                Risque associé
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 18,
                                }}>
                                {e?.ttypevulnerabilite?.designationv}
                              </Typography>

                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                  textDecoration: "underLine",
                                  mt: 2,
                                }}>
                                Type d'évènement
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 18,
                                }}>
                                {e?.ttypeevenement?.designationtypeev}
                              </Typography>

                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                  textDecoration: "underLine",
                                  mt: 2,
                                }}>
                                Brève descrisption de l'évènement
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 18,
                                  mb: 3,
                                }}>
                                {e?.descryptionev === "" ||
                                e?.descryptionev === undefined ||
                                e?.descryptionev === ""
                                  ? "Aucune descrisption"
                                  : e?.descryptionev}
                              </Typography>
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: 10,
                                }}>
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    size="medium"
                                    color="primary"
                                    onClick={() => upDate(e)}
                                    startIcon={<Edit fontSize="medium" />}>
                                    Modifier
                                  </Button>

                                  <Button
                                    size="medium"
                                    color="success"
                                    startIcon={<Visibility fontSize="medium" />}
                                    variant="outlined"
                                    onClick={() =>
                                      nav("/evenement-detaille", {
                                        state: {
                                          itemv: e,
                                        },
                                      })
                                    }>
                                    Imprimer
                                  </Button>

                                  <Button
                                    size="medium"
                                    color="warning"
                                    startIcon={
                                      <UpdateOutlined fontSize="medium" />
                                    }
                                    variant="outlined"
                                    onClick={() => ConfirmInformation(e)}>
                                    Verifier
                                  </Button>
                                </Stack>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "end",
                      }}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      mt={-5}>
                      <h1></h1>

                      <div
                        style={{
                          marginRight: 1,
                          marginBottom: 10,
                        }}>
                        <TextFields
                          value={searchQuery}
                          onChange={(e) => {
                            Recherche(e);
                          }}
                          icons={
                            <Search
                              fontSize="large"
                              sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                            />
                          }
                          placeholder="Rechercher"
                        />
                      </div>
                    </Stack>
                    <Mtables
                      title="Liste des évènements"
                      data={dataFilter}
                      columns={column}
                    />
                  </div>
                )}
              </div>
            </div>
          </Stack>
        )}
      </Box>
      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md">
        <InputEvenement dataUpdate={dataUpdate} fonct={getEvenement} />
      </Dialogs>
    </Box>
  );
}

export default Evenement;

