import {
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import InputTerritoire from "./InputTerritoire";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Territoire
      </Typography>
    ),
    selector: (row) => row.designation,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Province
      </Typography>
    ),
    selector: (row) => row.province,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Status
      </Typography>
    ),
    // selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      //   console.log(row);
      return (
        <Stack direction={"row"} spacing={1}>
          <Switch
            checked={row.status.etatteritoire === true ? true : false}
            // onChange={() => ChangeTable()}
            inputProps={{
              "aria-label": "controlled",
            }}
            color={row.status.etatteritoire === true ? "primary" : "warning"}
          />
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date de la création
      </Typography>
    ),
    selector: (row) => row.datecreate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date modification
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Territoire() {
  document.title = "Territoire | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  //   const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataTerritoire = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("territoire")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.map((e) => {
          return {
            designation: e.designationter,
            province: e.tprovince.designationprv,
            status: e,
            datecreate: e.datecreate,
            date: e.dateupdate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => Update(e)}>
                  <Edit color="info" />
                </IconButton>

                <IconButton onClick={() => ConfirmInformation(e)}>
                  <Delete color="error" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  React.useEffect(() => {
    getDataTerritoire();
  }, []);

  const dataFiltrage = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.designation &&
        itemx.designation.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Nouveau");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("territoire/delete", {
        idteritoire: dataupdate.idteritoire,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getDataTerritoire();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <Box>
      <h4
        style={{
          background: "white",
          padding: 5,
          borderRadius: 5,
          fontWeight: "bold",
          fontSize: 18,
          marginTop: -10,
          marginLeft: 20,
        }}
      >
        Territoire
      </h4>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Veuillez patiente</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: -5,
            // ml: -1,
          }}
        >
          <div>
            <Boutons
              onClick={() => nouveauVictiome()}
              icons={
                <Add
                  sx={{
                    mr: 2,
                    // mt: 10,
                  }}
                />
              }
              title="Nouveau Territoire"
            />
          </div>
          {etatv === false ? (
            ""
          ) : (
            <Stack
              style={{
                justifyContent: "center",
                alignItems: "center",
                background: "#E3EAEF",
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              <Typography fontWeight={"bold"} fontSize={20}>
                Information
              </Typography>
              <Divider />
              <Typography fontWeight={"bold"} fontSize={16}>
                Etes-vous sur de valider ?
              </Typography>
              <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                <IconButton
                  sx={{
                    bgcolor: "#013266",
                    ":hover": {
                      bgcolor: "#1976D2",
                    },
                  }}
                  onClick={() => ValidationDataUpdateEtat()}
                >
                  <Check
                    sx={{
                      color: "#FFF",
                    }}
                    fontSize="large"
                  />
                </IconButton>
                <IconButton
                  sx={{
                    bgcolor: "red",
                    ":hover": {
                      bgcolor: "#1976D2",
                    },
                  }}
                  onClick={() => setEtatV(!etatv)}
                >
                  <Close
                    sx={{
                      color: "#FFF",
                    }}
                    fontSize="large"
                  />
                </IconButton>
              </Stack>
            </Stack>
          )}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <h1></h1>
            <div>
              <TextFields
                value={searchQuery}
                onChange={(e) => {
                  Recherche(e.target.value);
                }}
                icons={
                  <Search
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                  />
                }
                placeholder="search..."
              />
            </div>
          </Stack>
          <Mtables
            title="Liste des territoires"
            data={dataFiltrage}
            columns={column}
          />
        </Stack>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputTerritoire dataUpdate={dataupdate} foncget={getDataTerritoire} />
      </Dialogs>
    </Box>
  );
}

export default Territoire;
