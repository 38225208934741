import { Alert, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import Mtables from "../controller/Mtables";
import { Search } from "@mui/icons-material";
import TextFields from "../controller/TextFields";
import Dialogs from "../controller/Dialogs";
import ErrorConnection from "../../outher/ErrorConnection";
import InputVente from "./InputVente";
import useVente from "../hooks/vente/useVente";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Négociant
      </Typography>
    ),
    selector: (row) => row.identite.toUpperCase(),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Minerai
      </Typography>
    ),
    selector: (row) => row.minerai.toUpperCase(),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Quantité
      </Typography>
    ),
    selector: (row) => row.quantite + " KG",
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Prix
      </Typography>
    ),
    selector: (row) => (
      <Alert
        icon={false}
        severity="success"
        sx={{
          fontWeight: "bold",
        }}
      >
        {row.prix}
      </Alert>
    ),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Statut
      </Typography>
    ),
    selector: (row) => (
      <Chip
        label={
          row.titretat === "vente" ? "Encours pour centre de negoce" : "Vendu"
        }
        sx={{
          borderRadius: 1,
        }}
        color={row.titretat === "vente" ? "error" : "primary"}
      />
    ),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 13,
          fontWeight: "bold",
        }}
      >
        Tag
      </Typography>
    ),
    selector: (row) => (
      <Alert
        icon={false}
        severity="warning"
        sx={{
          m: 0.5,
          p: -5,
        }}
      >
        {row.fraude}
      </Alert>
    ),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function VenteListe() {
  document.title = "Liste de Vente| EFFICACITY SYSTEM";
  const {
    dataFiltre,
    getDataVenteProduct,
    etat,
    etatcheckconn,
    opendialigue,
    searchQuery,
    setOpenDialogue,
    setSearchQuery,
    title,
    dataupdate,
  } = useVente();

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };
  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Veuillez patiente</Typography>
        </Stack>
      ) : (
        <div style={{ marginTop: 20, marginLeft: -20 }}>
          <div style={{ maxWidth: "95%", overflowX: "auto" }}>
            <div
              style={{
                // width: "100%",
                overflow: "auto",
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
                <h1></h1>
                <div>
                  <TextFields
                    value={searchQuery}
                    onChange={(e) => {
                      Recherche(e.target.value);
                    }}
                    icons={
                      <Search
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                      />
                    }
                    placeholder="search..."
                  />
                </div>
              </Stack>
              <Mtables
                title="Production vente"
                data={dataFiltre}
                columns={column}
              />
            </div>
          </div>
        </div>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputVente
          title={title}
          dataUpdate={dataupdate}
          foncget={getDataVenteProduct}
          foncget2={getDataVenteProduct}
        />
      </Dialogs>
    </div>
  );
}

export default VenteListe;
