import React, { useRef } from "react";
import MTableGrid from "../controller/MTableGrid";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import Comboboxs from "../controller/Comboboxs";
import TextFields from "../controller/TextFields";
import {
  CheckCircle,
  Verified,
  VerticalAlignBottom,
} from "@mui/icons-material";
import { requestFD } from "../url/requesFD";
import ErrorConnection from "../../outher/ErrorConnection";
import { useReactToPrint } from "react-to-print";
import Info from "../../outher/Info";
import Boutons from "../controller/Boutons";

const dataFilter = [
  { value: "Autre", label: "Autre" },
  { value: "date", label: "Date" },
];

function ImprimerMesure() {
  const [etat, setEtat] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [date2, setDate2] = React.useState("");
  const [valuex, setValue] = React.useState("");
  const [etatx, setEtatx] = React.useState(false);
  const prints = useRef();

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: "liste victime",
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });
  const clomun = [
    { field: "id", headerName: "ID", width: 20 },
    ,
    { field: "titre", headerName: "Titre", width: 400 },
    {
      field: "partieprenamte",
      headerName: "Partie Responsable",
      width: 120,
    },
    { field: "typevuln", headerName: "Risque", width: 180 },

    { field: "date", headerName: "Date", width: 100 },
    { field: "dateup", headerName: "Date Modif", width: 100 },
  ];

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const selectAction = (e) => {
    setValue(e);
    // console.log(e);
    if (e.value === "Autre") {
      setEtatx(false);
    } else if (e.value === "date") {
      setEtatx(true);
    }
  };

  const dataBetween = () => {
    if (date1 === "" || date2 === "") {
      NotifyError("complete tous le champs ");
    } else {
      setEtat(false);
      setEtatChecConn(false);

      request
        .post("mesure/getAllgetByDateBetween", {
          date1x: date1,
          date2x: date2,
        })
        .then((e) => {
          let itm = e.data.data.map((e, key) => {
            return {
              id: key,
              titre: e.titrem,
              partieprenamte: e.tpartiprenante.designaionp,
              typevuln: e.ttypevulnerabilite.designationv,
              date: e.datecreate,
              dateup: e.dateupdate,
            };
          });
          setData(itm);
          setEtat(true);
          setEtatChecConn(false);
        })
        .catch((error) => {
          if (error.code) {
            NotifyError(error.message);
            // setDisable(false);
            setEtat(false);
            setEtatChecConn(true);
          } else {
            NotifyError(error.response.data.message);
            setEtat(false);
          }
        });
    }
  };

  const getDataVunerable = (ex) => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("mesure")
      .then((e) => {
        let itm = e.data.data.map((e, key) => {
          return {
            id: key,
            titre: e.titrem,
            //   descryptionm: e.descryptionm,
            //   nomagent: e.descryptionm,
            partieprenamte: e.tpartiprenante.designaionp,
            typevuln: e.ttypevulnerabilite.designationv,
            date: e.datecreate,
            dateup: e.dateupdate,
            //   etat: e.etat_mesure,
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          setEtat(false);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
          setEtatChecConn(false);
        }
      });
  };
  React.useEffect(() => {
    getDataVunerable();
  }, []);

  const dataFiltrage = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.titre &&
        itemx.titre.toUpperCase().includes(searchQuery.toUpperCase())
  );

  return (
    <div
      style={{
        background: "#FFF",
        padding: 15,
        // position: "absolute",
        // flexWrap: "wrap",
        // width: "100%",
        marginBottom: 10,
        borderRadius: 5,
      }}
    >
      <Stack mb={5} direction={"row"} justifyContent={"space-between"}>
        {etatx === false ? (
          <Stack direction={"row"} spacing={2}>
            <div>
              <TextFields
                value={searchQuery}
                onChange={(e) => Recherche(e)}
                placeholder="search..."
              />
            </div>
            {/*
          <div>
          <IconButton
            onClick={() => RechercheV()}
            size="large"
            sx={{ mt: 1 }}
          >
            <CheckCircle sx={{ color: "#013266" }} fontSize="large" />
          </IconButton>
        </div>
          */}
          </Stack>
        ) : (
          <Stack direction={"row"} spacing={2}>
            <div>
              <TextFields
                value={date1}
                onChange={(e) => setDate1(e.target.value)}
                placeholder="2023-06-15"
              />
            </div>
            <div style={{ marginTop: 25 }}>
              <Typography fontWeight={"bold"}>To</Typography>
            </div>
            <div>
              <TextFields
                value={date2}
                onChange={(e) => setDate2(e.target.value)}
                placeholder="2023-06-15"
              />
            </div>
            <div>
              <IconButton
                onClick={() => dataBetween()}
                size="large"
                sx={{ mt: 1 }}
                // type=""
              >
                <CheckCircle sx={{ color: "#013266" }} fontSize="large" />
              </IconButton>
            </div>
          </Stack>
        )}

        <div style={{ width: "20%" }}>
          <Comboboxs
            value={valuex}
            onChange={(e) => selectAction(e)}
            data={dataFilter}
            placeholder="Filter"
          />
        </div>
      </Stack>
      <div ref={prints}>
       {/* <Info />*/}
        <h1>Mesure </h1>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : (
          <MTableGrid columns={clomun} rows={dataFiltrage} />
        )}
      </div>
      <Grid container justifyContent={"right"} mb={2} mt={2}>
        <Boutons title="Imprimer" onClick={() => Imprimer()} />
      </Grid>
    </div>
  );
}

export default ImprimerMesure;
