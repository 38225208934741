import React from "react";
import DataTable from "react-data-table-component";

function Mtables({ columns, data, title }) {
  return (
    <DataTable
      fixedHeader
      fixedHeaderScrollHeight="300px"
      columns={columns}
      data={data}
      pagination
      title={title}
    />
  );
}

export default Mtables;
