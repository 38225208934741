import { Grid } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputVulnerabilite({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  //
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designationv: dataUpdate === "" ? "" : dataUpdate.designationv,
      idtypevulnerabilite:
        dataUpdate === "" ? "" : dataUpdate.idtypevulnerabilite,
    },

    validationSchema: Yup.object({
      designationv: Yup.string().required("le champs est obligatoire"),
    }),
    onSubmit: (values) => {
      //   console.log(values);

      if (dataUpdate === "") {
        //enregistrement
        setEtat(true);
        request
          .post("typevunerabilite/save", values)
          .then((e) => {
            // console.log(e.data);
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      } else {
        //modification
        setEtat(true);
        request
          .put("typevunerabilite/update", values)
          .then((e) => {
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextFields
            label={"Type de risque"}
            value={validation.values.designationv}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"designationv"}
            error={
              validation.touched.designationv && validation.errors.designationv
                ? true
                : false
            }
            placeholder="Type de risque"
          />

          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.designationv && validation.errors.designationv
              ? validation.errors.designationv
              : false}
          </p>

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputVulnerabilite;
