import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

function Info() {
  return (
    <div
    // style={{
    //   display: aff,
    // }}
    >
      <Typography textAlign={"center"} fontWeight={"bold"} fontSize={20}>
        <img
          src={require("../components/assets/ES.png")}
          style={{
            width: 100,
            height: 100,
          }}
          alt="image"
        />
      </Typography>
      <Stack justifyContent={"center"} direction={"row"} spacing={2}>
        <Typography textAlign={"center"}>RCCM: 129-3364-hsg23-35</Typography>
        <Typography textAlign={"center"}>Id nat: 129-3364-hsg23-35</Typography>
      </Stack>
      <Typography textAlign={"center"}>
        Avenu Présidentielle, Quartier Himbi, Commune de Goma, No 93
      </Typography>
      <Typography textAlign={"center"}>
        Tel: +243 990256555 / 990088288
      </Typography>
      <Typography textAlign={"center"}>ifeddrdc@gmail.com</Typography>
      <Divider sx={{ m: 2 }} />
    </div>
  );
}

export default Info;
