import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ItemDrawer, datalogin } from "../data/Data";
import { useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Stack, Typography } from "@mui/material";
import { request, request2 } from "../url/request";
import Popovers from "../controller/Popovers";
import { KeyboardArrowDown, NotificationAdd } from "@mui/icons-material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const drawerWidth = 300;
let imtmdatalogin = JSON.parse(localStorage.getItem("data"));
// console.log(
//   "==>:)",
//   imtmdatalogin?.identite?.nom +
//     " " +
//     imtmdatalogin?.identite?.postnom +
//     " " +
//     imtmdatalogin?.identite?.prenom
// );
const url = request2 + imtmdatalogin?.identite?.image;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [sbmenu, setSubMenu] = React.useState("none");
  const nav = useNavigate();
  const [open1, setOpen1] = React.useState(false);
  const refs = React.useRef();
  const [totalmessage, setTotalMessage] = React.useState(0);
  const [selectItem, setSelectItem] = React.useState(null);

  const navigation = () => {
    nav("/message");
  };

  const getMessage = () => {
    request
      .post("production/counts")
      .then((e) => {
        // console.log("==>====>::", e.data);
        setTotalMessage(e.data.data === null ? 0 : e.data.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    getMessage();
    // Définir l'intervalle
    const intervalId = setInterval(getMessage, 5000);
    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const ShowNavigation = (e, index) => {
    setSelectItem(index);
    if (!e) {
      if (sbmenu === "none") {
        setSubMenu("block");
        setSelectItem(index);
      } else {
        setSubMenu("none");
        setSelectItem(index);
      }
    } else {
      nav(e);
      setSubMenu("none");
      setSelectItem(index);
    }
  };

  const Deconnexion = (e) => {
    if (e.title === "Déconnexion") {
      localStorage.removeItem("data");
      // localStorage.removeItem("username");
      // localStorage.removeItem("password");
      nav(e.link);
    } else {
      // NotifyError("Service no dispobible");
      nav(e.link);
    }
  };

  const ShowSubNavigation = (e) => {
    nav(e);
    setSubMenu("none");
  };

  return (
    <Box sx={{ display: "flex", mb: 5 }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="absolute"
        open={open}
        sx={{
          bgcolor: "#013062",
          mb: 5,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              left: 0,
              right: 0,
              top: 0,
            }}
            justifyContent={"space-between"}
          >
            <img
              src={require("../assets/ESB.png")}
              style={{
                width: 50,
                height: 50,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
            {/*
             <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mt: 1 }}
              noWrap
              component="div"
            >
              EFFICACITY SYSTEM
            </Typography>
            */}
            <Stack direction={"row"}>
              <IconButton onClick={() => navigation()} color="inherit">
                <Badge
                  badgeContent={totalmessage}
                  color="error"
                  sx={{
                    mt: 3,
                  }}
                >
                  <NotificationAdd color="inherit" />
                </Badge>
              </IconButton>
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen1(true)}
                >
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <IconButton>
                        <Avatar src={url} />
                      </IconButton>
                    </StyledBadge>
                    <Typography>
                      {
                        // imtmdatalogin?.identite?.nom +
                        //   " " +
                        imtmdatalogin?.identite?.postnom +
                          " " +
                          imtmdatalogin?.identite?.prenom
                      }
                    </Typography>
                  </Stack>
                </div>
                <Popovers
                  ref={refs}
                  open={open1}
                  setOpen={() => setOpen1(!open1)}
                >
                  <List
                    sx={{
                      bgcolor: "#f5f5f5",
                    }}
                  >
                    {datalogin.map((e) => {
                      return (
                        <ListItem
                          onClick={() => Deconnexion(e)}
                          id={e.id}
                          sx={{
                            ":hover": {
                              bgcolor: "#f5f5f5",
                            },
                          }}
                        >
                          <ListItemButton style={{ fontSize: 12 }}>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                                color: "#000",
                              }}
                            >
                              {e.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={e.title}
                              sx={{ opacity: open ? 1 : 0, color: "#000" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Popovers>
              </div>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        // sx={{
        //   background: "red",
        // }}
        variant="permanent"
        open={open}
      >
        <Box sx={{ background: "#013266", minHeight: "100vh" }}>
          <DrawerHeader
            sx={{
              bgcolor: "#013266",
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon
                  sx={{
                    color: "#FFF",
                  }}
                />
              ) : (
                <ChevronLeftIcon
                  sx={{
                    color: "#FFF",
                  }}
                />
              )}
            </IconButton>
          </DrawerHeader>
          <List
            sx={{
              mt: -2,
            }}
          >
            {ItemDrawer.map((text, index) => (
              <ListItem
                onSelect={true}
                classes={{
                  selected: {
                    background: "red",
                  },
                }}
                onClick={() => ShowNavigation(text.link, index)}
                key={text.id}
                disablePadding
                sx={{
                  display: "block",
                  bgcolor: selectItem === index ? "#1976D2" : "",
                  ":hover": {
                    bgcolor: "#1976D2",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    // minHeight: 30,
                    justifyContent: open ? "initial" : "center",
                    px: 1,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#FFF",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  {text.title === "Paramètres" ? (
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <ListItemText
                        primary={text.title}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "#FFF",
                          fontSize: 12,
                        }}
                      />
                      <div style={{ marginTop: 5 }}>
                        {sbmenu === "none" ? (
                          <ChevronRightIcon sx={{ color: "#FFF" }} />
                        ) : (
                          <KeyboardArrowDown sx={{ color: "#FFF" }} />
                        )}
                      </div>
                    </Stack>
                  ) : (
                    <ListItemText
                      primary={text.title}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#FFF",
                        fontSize: 12,
                      }}
                    />
                  )}
                </ListItemButton>
                <div
                  style={{
                    maxHeight: "300px",
                    overflow: "auto",
                  }}
                >
                  {text.subMenu &&
                    text.subMenu.map((e) => {
                      return (
                        <ListItem
                          onClick={() => ShowSubNavigation(e.link)}
                          key={e.id}
                          disablePadding
                          sx={{
                            display: sbmenu,
                            background: "#013062",
                            ":hover": {
                              bgcolor: "#1976D2",
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{
                              minHeight: 18,
                              justifyContent: open ? "initial" : "center",
                              px: 1,
                            }}
                          >
                            <ListItemText
                              primary={e.subtitle}
                              sx={{
                                opacity: open ? 1 : 0,
                                ml: 6,
                                fontSize: 10,
                                color: "#FFF",
                              }}
                              // id={e.id}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </div>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
