import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputPont({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);

  const [datasite, setDataSite] = React.useState([]);
  const [labelSite, setLabelSite] = React.useState("");

  const [datamine, setDataMine] = React.useState([]);
  const [labelmine, setLabelMine] = React.useState("");

  //get emplacement
  const getEmplacement = () => {
    request
      .get("emplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idemplacement,
            label: ex.designationsite,
          };
        });
        setDataSite(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get minerai
  const getMinerai = () => {
    request
      .get("mine")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idminerai,
            label: ex.designation,
          };
        });
        setDataMine(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  useEffect(() => {
    getEmplacement();
    getMinerai();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      idemplacement: labelSite.value
        ? labelSite.value
        : dataUpdate?.templacement?.idemplacement,
      //    dataUpdate?.templacement?.idemplacement,
      idminerai: labelmine.value
        ? labelmine.value
        : dataUpdate?.tminerai?.idminerai,
      //    dataUpdate?.tminerai?.idminerai,
    },

    // validationSchema: Yup.object({
    //   designation: Yup.string().required("le nom du puits est obligatoire"),
    // }),
    onSubmit: (values) => {
      //   console.log("====>", values);
      if (dataUpdate === "") {
        if (labelSite === "" || labelSite === null || labelSite === undefined) {
          NotifyError("L'emplacementest obligatoire");
        } else if (
          labelmine === "" ||
          labelmine === null ||
          labelmine === undefined
        ) {
          NotifyError("selectionner le minerai");
        } else {
          //enregistrement
          setEtat(true);
          request
            .post("pontmine/save", values)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      } else {
        //modification
        setEtat(true);
        request
          .put("puit/update", values)
          .then((e) => {
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Comboboxs
            data={datasite}
            value={labelSite}
            onChange={(e) => setLabelSite(e)}
            placeholder="Site Minier"
          />
          <Comboboxs
            data={datamine}
            value={labelmine}
            onChange={(e) => setLabelMine(e)}
            placeholder="Minerai"
          />

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputPont;
