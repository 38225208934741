import {
  Alert,
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Edit,
  Error,
  Search,
  UpdateOutlined,
  Verified,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import Dialogs from "../controller/Dialogs";
import MesureInput from "./MesureInput";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Mesures d'attenuation
      </Typography>
    ),
    selector: (row) => row.titrem,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Partie prenante
      </Typography>
    ),
    selector: (row) => row.partitprenante,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Risque
      </Typography>
    ),
    selector: (row) => row.typevuln,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Validiter
      </Typography>
    ),
    // selector: (row) => row.etat_vulnerable,
    sortable: true,
    cell: (row) => {
      return (
        <Stack direction={"row"} spacing={1} sx={{ p: 1 }}>
          {row.etat === true ? (
            <Alert icon={<Verified />} severity="success">
              Verifier
            </Alert>
          ) : (
            <Alert icon={<Error />} severity="warning">
              Non verifier
            </Alert>
          )}
        </Stack>
      );
    },
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.datecreate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Mesure() {
  document.title = "Mesure | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [dataUpdate, setDataUpdate] = React.useState("");
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const nav = useNavigate();

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getMesure = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("mesure")
      .then((e) => {
        let itm = e.data.data.map((e) => {
          return {
            titrem: e.titrem,
            descryptionm: e.descryptionm,
            nomagent: e.descryptionm,
            partitprenante: e.tpartiprenante.designaionp,
            typevuln: e.ttypevulnerabilite.designationv,
            datecreate: e.datecreate,
            // dateupdate: e.dateupdate,
            etat: e.etat_mesure,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => upDate(e)}
                >
                  <Edit fontSize="medium" />
                </IconButton>

                <IconButton
                  onClick={() =>
                    nav("/mesure-detail", {
                      state: {
                        itemv: e,
                      },
                    })
                  }
                  size="medium"
                  color="success"
                >
                  <Visibility fontSize="medium" />
                </IconButton>
                <IconButton
                  size="medium"
                  color="warning"
                  onClick={() => ConfirmInformation(e)}
                >
                  <UpdateOutlined fontSize="medium" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  React.useEffect(() => {
    getMesure();
  }, []);

  const dataFiltre = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : (itemx &&
          itemx.titrem &&
          itemx.titrem.toUpperCase().includes(searchQuery.toUpperCase())) ||
        itemx.partitprenante
          .toUpperCase()
          .includes(searchQuery.toUpperCase()) ||
        itemx.typevuln.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauevenement = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Enregistrement");
  };

  const upDate = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("mesure/valider", {
        idmesure: dataUpdate.idmesure,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getMesure();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Mesures
        </h4>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Patientez</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <div>
              <Boutons
                onClick={() => nouveauevenement()}
                icons={
                  <Add
                    sx={{
                      mr: 2,
                    }}
                  />
                }
                title="Nouvelle mesure d'attenuation"
              />
            </div>
            {etatv === false ? (
              ""
            ) : (
              <Stack
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E3EAEF",
                  marginTop: 20,
                  borderRadius: 5,
                }}
              >
                <Typography fontWeight={"bold"} fontSize={20}>
                  Information
                </Typography>
                <Divider />
                <Typography fontWeight={"bold"} fontSize={16}>
                  Etes-vous sur de valider ?
                </Typography>
                <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                  <IconButton
                    sx={{
                      bgcolor: "#013266",
                      ":hover": {
                        bgcolor: "#1976D2",
                      },
                    }}
                    onClick={() => ValidationDataUpdateEtat()}
                  >
                    <Check
                      sx={{
                        color: "#FFF",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      bgcolor: "red",
                      ":hover": {
                        bgcolor: "#1976D2",
                      },
                    }}
                    onClick={() => setEtatV(!etatv)}
                  >
                    <Close
                      sx={{
                        color: "#FFF",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                </Stack>
              </Stack>
            )}
            <Stack direction={"row"} justifyContent={"space-between"}>
              <h1></h1>
              <div
                style={{
                  marginRight: 20,
                }}
              >
                <TextFields
                  value={searchQuery}
                  onChange={(e) => {
                    Recherche(e.target.value);
                  }}
                  icons={
                    <Search
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                    />
                  }
                  placeholder="search..."
                />
              </div>
            </Stack>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <Mtables
                title="Liste des mesures"
                data={dataFiltre}
                columns={column}
              />
            </div>
          </Stack>
        )}
      </Box>
      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="sm"
      >
        <MesureInput dataUpdate={dataUpdate} foncget={getMesure} />
      </Dialogs>
    </Box>
  );
}

export default Mesure;
