import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Add, Dangerous, Search } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import Dialogs from "../controller/Dialogs";
import ErrorConnection from "../../outher/ErrorConnection";
import InputProduction from "./InputProduction";
import InputValiderArriveProduit from "./InputValiderArriveProduit";
import useProduction from "../hooks/production/useProduction";
var Spink = require("react-spinkit");

function Production() {
  document.title = "Production| EFFICACITY SYSTEM";
  const [page, setPage] = useState(1);
  const itemsPerPage = 2; // Nombre d'éléments par page
  const {
    data,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
    setDataUpdate,
    etat,
    etatcheckconn,
    aff,
    setAffi,
    datax,
    setDatax,
    getDataProduction,
  } = useProduction();

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const Valider = (e) => {
    setAffi(!aff);
    setDatax(e);
  };

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Enregitrement");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculer les données à afficher pour la page courante
  const paginatedData = data.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Patienter</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: 2,
            // ml: -1,
          }}
        >
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <div>
              <Boutons
                onClick={() => nouveauVictiome()}
                icons={
                  <Add
                    sx={{
                      mr: 2,
                    }}
                  />
                }
                title="Nouvelle Production"
              />
            </div>
            <div>
              <Alert
                icon={false}
                severity="info"
                style={{
                  fontWeight: "bold",
                }}
              >
                Total : {data.length}
              </Alert>
            </div>
          </Stack>

          <Stack>
            <div style={{ margin: 10 }}>
              <TextFields
                value={searchQuery}
                onChange={(e) => Recherche(e)}
                icons={
                  <Search
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                  />
                }
                placeholder="Rechercher"
              />
            </div>
          </Stack>
          {/**
           * <Mtables title="Agent du gouvernement" data={data} columns={column} />
           */}
          <Grid container justifyContent={"center"}>
            {!(data || []).length ? (
              <div>
                <Typography align="center">
                  aucune activité n'a été enregistrée
                </Typography>
              </div>
            ) : (
              paginatedData
                .filter((itemx) =>
                  searchQuery.toUpperCase() === null ||
                  searchQuery.toUpperCase() === "" ||
                  searchQuery.toUpperCase() === undefined
                    ? itemx
                    : itemx?.nompuit
                        .toUpperCase()
                        .toUpperCase()
                        .indexOf(searchQuery.toUpperCase()) > -1 ||
                      itemx?.nompuit
                        .toUpperCase()
                        .indexOf(searchQuery.toUpperCase()) > -1 ||
                      itemx?.taget.indexOf(searchQuery.toUpperCase()) > -1 ||
                      itemx?.taget.indexOf(searchQuery.toUpperCase()) > -1
                )
                .map((e) => (
                  <Card sx={{ minWidth: 400, m: 1 }}>
                    <Stack flexDirection={"row"} justifyContent={"end"}>
                      {e?.alertediplication ? (
                        <Alert
                          icon={
                            <Dangerous
                              sx={{
                                // fontSize: 50,
                                color: "red",
                              }}
                            />
                          }
                          severity="error"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Fraude
                        </Alert>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <CardContent>
                      <Stack>
                        <Typography
                          style={{
                            fontSize: 15,
                            fontWeight: "bolder",
                            textAlign: "justify",
                            marginTop: -10,
                            textDecoration: "underLine",
                          }}
                        >
                          Site minier:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "bolder",
                            textAlign: "justify",
                            color: "blue",
                          }}
                        >
                          {e?.tpontsiteminetypemine?.templacement?.designationsite.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "bolder",
                            textAlign: "justify",
                          }}
                        >
                          Date et heure d'extration:{" "}
                          {`${e?.datecreate} ${e?.heuresortie}`}
                        </Typography>
                        <Divider style={{ margin: 10 }} />
                      </Stack>

                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Stack>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              textDecoration: "underLine",
                            }}
                          >
                            Minerais:
                          </Typography>
                          <Typography
                            fontWeight={"bolder"}
                            fontSize={12}
                            textAlign={"justify"}
                            color={"blue"}
                            marginBottom={2}
                          >
                            {e?.tpontsiteminetypemine?.tminerai?.designation.toUpperCase()}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography
                        style={{
                          fontSize: 15,
                          fontWeight: "bolder",
                          textDecoration: "underLine",
                          marginBottom: 10,
                        }}
                      >
                        Puit d'origine:
                      </Typography>
                      <Typography
                        mt={-2}
                        fontWeight={"bolder"}
                        gutterBottom
                        sx={{
                          color: "blue",
                          fontSize: 15,
                          // mt: 1,
                        }}
                      >
                        {e?.nompuit}
                      </Typography>
                      <Divider />
                      <Typography
                        style={{
                          fontSize: 15,
                          fontWeight: "bolder",
                          textAlign: "justify",
                          textDecoration: "underLine",
                        }}
                      >
                        Tag mine:
                      </Typography>
                      <Typography
                        textAlign={"justify"}
                        fontWeight={"bolder"}
                        color={"blue"}
                        // mt={1}
                        fontSize={12}
                      >
                        {e?.taget}
                      </Typography>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        sx={{
                          bgcolor: "#F0F0F0",
                          p: 0.5,
                          borderRadius: 1,
                          mt: 2,
                          mb: 2,
                        }}
                      >
                        <Stack>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textDecoration: "underLine",
                            }}
                          >
                            Quantité produite:
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textDecoration: "underLine",
                              textAlign: "justify",
                              color: "blue",
                            }}
                          >
                            {e?.quantitebruite}Kg
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textDecoration: "underLine",
                            }}
                          >
                            Emballage:
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textDecoration: "underLine",
                              textAlign: "justify",
                              color: "blue",
                            }}
                          >
                            {e?.embale}
                          </Typography>
                        </Stack>
                      </Stack>
                      {/*
                        <Stack
                        sx={{
                          bgcolor: "#FFCBB5",
                          p: 0.5,
                          mb: 1,
                          borderRadius: 1,
                          fontWeight: "bolder",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: "bolder",
                            textDecoration: "underLine",
                          }}
                        >
                          Quantité Arrivé
                        </Typography>
                        <Typography>{e?.quantitearrive}Kg</Typography>
                      </Stack>
                        */}
                      <Typography textAlign={"end"}>
                        <Chip
                          label={e?.etat === true ? "En cours..." : "Arrivé..."}
                          color={e?.etat === true ? "error" : "primary"}
                          sx={{
                            borderRadius: 2,
                          }}
                        />
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => Update(e)}
                      >
                        Modifier
                      </Button>
                      <Button size="small" onClick={() => Valider(e)}>
                        Stockez dans un centre de negoce
                      </Button>
                    </CardActions>
                  </Card>
                ))
            )}
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Stack>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputProduction dataUpdate={dataupdate} foncget={getDataProduction} />
      </Dialogs>
      <Dialogs
        title={"Reception du produit"}
        open={aff}
        setOpen={setAffi}
        size="sm"
      >
        <InputValiderArriveProduit
          datax={datax}
          getDataVunerable={getDataProduction}
        />
      </Dialogs>
    </div>
  );
}

export default Production;
