import { Grid, Stack } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import { Person, Save } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneNumbers from "../controller/PhoneNumber";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import UploadAndDisplayImage from "../controller/UploadImage";
import NotifySuccess from "../Notification/NotifySuccess";
var Spinkit = require("react-spinkit");

function InputEntiteDeTraitement({ fonct, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [phone, setPhone] = React.useState(dataUpdate?.phone);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: dataUpdate?.designatio,
      postnom: dataUpdate?.adresse,
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("L'entité est obligatoire"),
      //   postnom: Yup.string().required("Ce champs est requis"),
    }),

    onSubmit: (values) => {
      // console.log("==>:", values);
      const personefdata = new FormData();

      if (dataUpdate === "") {
        setEtat(true);
        request
          .post("entitedetraitement/save", {
            phone: phone,
            designatio: values.nom,
            adresse: values.postnom,
          })
          .then((e) => {
            //   console.log("===>", e.data.data.idpersonne);
            NotifySuccess(e.data.message);
            fonct();
            setEtat(false);
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      } else {
        // modification;
        let identite = dataUpdate?.identite;

        setEtat(true);
        request
          .put("entitedetraitement/update", {
            phone: phone,
            designatio: values.nom,
            adresse: values.postnom,
            identite: identite,
          })
          .then((e) => {
            NotifySuccess(e.data.message);
            fonct();
            setEtat(false);
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Stack direction={"column"}>
            <div
              style={{
                width: "100%",
              }}
            >
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    // label="Nom"
                    placeholder="Entité de traitement"
                    id={"nom"}
                    onBlur={validation.handleBlur}
                    value={validation.values.nom}
                    onChange={validation.handleChange}
                    error={
                      validation.touched.nom && validation.errors.nom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.nom && validation.errors.nom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        // marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.nom}
                    </h6>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    // label="Postnom"
                    placeholder="Adresse Physique"
                    id={"postnom"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.postnom}
                    error={
                      validation.touched.postnom &&
                      validation.errors.postnom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.postnom && validation.errors.postnom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        // marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.postnom}
                    </h6>
                  ) : null}
                </div>
              </Stack>

              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <PhoneNumbers value={phone} onChange={(e) => setPhone(e)} />
              </div>
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  // width: "93%",
                  mt: 2,
                  // ml: 5,
                }}
              >
                {dataUpdate === "" ? (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Enregistrer"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                ) : (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Update"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                )}
              </Stack>
            </div>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputEntiteDeTraitement;
