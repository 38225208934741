import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../url/request";
import NotifyError from "../../Notification/NotifyError";
import NotifySuccess from "../../Notification/NotifySuccess";
import { requestFD } from "../../url/requesFD";
import UploadAndDisplayImage2 from "../../controller/UploaderImageDeux";
import UploadAndDisplayImage from "../../controller/UploadImage";

function useProduction() {
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [aff, setAffi] = React.useState(false);
  const [datax, setDatax] = React.useState(null);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { UploadImage: UploadImage2, selectedImage: selectedImage2 } =
    UploadAndDisplayImage2();
  const [labelSite, setLabelSite] = React.useState("");

  const [labelmine, setLabelMine] = React.useState("");
  const [imgageseamap, setImageSAMAP] = React.useState(false);
  const [imgagedivimine, setDivimine] = React.useState(false);

  //getDataProduction
  const getDataProduction = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("production/interface-produit")
      .then((e) => {
        const uniqueData = e.data.data.reduce((acc, current) => {
          if (!acc.find((item) => item.idproduction === current.idproduction)) {
            acc.push(current);
          }
          return acc;
        }, []);
        setData(uniqueData);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //validation Arive produit
  const validationArriveProduction = ({ data }) => {
    setEtat(true);
    request
      .put("production/update", data)
      .then((e) => {
        getDataProduction();
        NotifySuccess(e.data.message);
        setEtat(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //Enregistrement de la production

  const saveProduction = ({ data, foncget }) => {
    setDisable(true);
    requestFD
      .post("production/save1", data)
      .then((e) => {
        if (e.status === 200) {
          foncget();
          NotifySuccess(e.data.message);
          setDisable(false);
        }
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //update production

  const upDateProduction = ({ data, foncget }) => {
    setDisable(true);
    requestFD
      .put("production/updatex", data)
      .then((e) => {
        if (e.status === 200) {
          foncget();
          NotifySuccess(e.data.message);
          setDisable(false);
        }
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  useEffect(() => {
    getDataProduction();
  }, []);

  return {
    data,
    title,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    setTitle,
    dataupdate,
    setDataUpdate,
    nav,
    etat,
    setEtat,
    etatv,
    setEtatV,
    etatcheckconn,
    setEtatChecConn,
    aff,
    setAffi,
    datax,
    setDatax,
    validationArriveProduction,
    getDataProduction,
    saveProduction,
    upDateProduction,

    UploadImage,
    selectedImage,
    UploadImage2,
    selectedImage2,
    labelSite,
    setLabelSite,
    labelmine,
    setLabelMine,
    imgageseamap,
    setImageSAMAP,
    imgagedivimine,
    setDivimine,
    setDisable,
    disable,
  };
}

export default useProduction;
