import { Grid, Typography } from "@mui/material";
import React from "react";

function Error() {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      // spacing={10}
      direction={"column"}
    >
      <img
        src={require("../assets/errors404.png")}
        style={{
          width: "25%",
          height: "25%",
        }}
        onResize="contain"
        alt="photo"
      />
      <Typography
        sx={{
          fontSize: 100,
          fontWeight: "bold",
        }}
      >
        404
      </Typography>
      <Typography
        sx={{
          fontSize: 25,
          fontWeight: "bold",
          mt: -2,
        }}
      >
        Page Not Fund
      </Typography>
    </Grid>
  );
}

export default Error;
