import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  Alert,
  Pagination,
} from "@mui/material";
import React, { useState } from "react";
import { Add, Dangerous, Search } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import Dialogs from "../controller/Dialogs";
import InputVente from "./InputVente";
import InputFraude from "./InputFraude";
import useCentreNegoce from "../hooks/centrenegoce/useCentreNegoce";
import useVente from "../hooks/vente/useVente";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

function Vente() {
  document.title = "Vente| EFFICACITY SYSTEM";
  const [page, setPage] = useState(1);
  const itemsPerPage = 2; // Nombre d'éléments par page
  const {
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
    setDataUpdate,
    opendialiguex,
    setOpenDialoguex,
    getDataVenteProduct,
  } = useCentreNegoce();

  const { getDataVunerable, data, etat, etatcheckconn } = useVente();

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Vente");
  };

  const nouveauVictiomex = () => {
    setDataUpdate("");
    setOpenDialoguex(!opendialigue);
    setTitle("Enregitrement");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculer les données à afficher pour la page courante
  const paginatedData = data.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      {etatcheckconn === true ? (
        <ErrorConnection />
      ) : etat === false ? (
        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Spink name="wave" color="#409EFF" />
          <Typography>Patientez</Typography>
        </Stack>
      ) : (
        <div>
          <Stack
            sx={{
              bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <div>
                <Boutons
                  onClick={() => nouveauVictiomex()}
                  icons={
                    <Add
                      sx={{
                        mr: 2,
                      }}
                    />
                  }
                  title="Acheter le minerai"
                />
              </div>
              <div>
                <Alert
                  icon={false}
                  severity="info"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Total : {data.length}
                </Alert>
              </div>
            </Stack>
            <Stack>
              <div style={{ margin: 10 }}>
                <TextFields
                  value={searchQuery}
                  onChange={(e) => Recherche(e)}
                  icons={
                    <Search
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                    />
                  }
                  placeholder="Rechercher"
                />
              </div>
            </Stack>
            {/**
             * <Mtables title="Agent du gouvernement" data={data} columns={column} />
             */}
            <Grid container justifyContent={"center"}>
              {!(data || []).length ? (
                <div>
                  <Typography align="center">
                    aucune activité pour l'instant qui a été enregistré
                  </Typography>
                </div>
              ) : (
                paginatedData
                  .filter((itemx) =>
                    searchQuery.toUpperCase() === null ||
                    searchQuery.toUpperCase() === "" ||
                    searchQuery.toUpperCase() === undefined
                      ? itemx
                      : itemx?.nompuit
                          .toUpperCase()
                          .toUpperCase()
                          .indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.nompuit
                          .toUpperCase()
                          .indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.taget.indexOf(searchQuery.toUpperCase()) > -1 ||
                        itemx?.taget.indexOf(searchQuery.toUpperCase()) > -1
                  )
                  .map((e) => (
                    <Card sx={{ minWidth: 400, m: 1 }}>
                      <Stack flexDirection={"row"} justifyContent={"end"}>
                        {e?.alertediplication ? (
                          <Alert
                            icon={
                              <Dangerous
                                sx={{
                                  // fontSize: 50,
                                  color: "red",
                                }}
                              />
                            }
                            severity="error"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Fraude
                          </Alert>
                        ) : (
                          ""
                        )}
                      </Stack>
                      <CardContent>
                        <Stack>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              marginTop: -10,
                              textDecoration: "underLine",
                            }}
                          >
                            Site minier d'origine:
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              color: "blue",
                            }}
                          >
                            {
                              e?.tpontsiteminetypemine?.templacement
                                ?.designationsite
                            }
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textAlign: "justify",
                            }}
                          >
                            Date: {`${e?.datecreate} ${e?.heuresortie}`}
                          </Typography>
                          <Divider style={{ margin: 10 }} />
                        </Stack>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                textDecoration: "underLine",
                              }}
                            >
                              Minerai:
                            </Typography>
                            <Typography
                              fontWeight={"bolder"}
                              fontSize={12}
                              color={"blue"}
                              textAlign={"justify"}
                              marginBottom={2}
                            >
                              {e?.tpontsiteminetypemine?.tminerai?.designation.toUpperCase()}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: "bolder",
                                textAlign: "center",
                                textDecoration: "underLine",
                              }}
                            >
                              Heure d'arrivé:
                            </Typography>
                            <Typography
                              fontWeight={"bolder"}
                              gutterBottom
                              sx={{
                                color: "blue",
                                fontSize: 12,
                                // mt: 1,
                              }}
                            >
                              {e?.heurearrive}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Typography
                          style={{
                            fontSize: 15,
                            fontWeight: "bolder",
                            textDecoration: "underLine",
                            textAlign: "justify",
                            marginBottom: 10,
                          }}
                        >
                          Puits d'origine:
                        </Typography>
                        <Typography
                          mt={-2}
                          textAlign={"justify"}
                          fontWeight={"bolder"}
                          color={"blue"}
                          // mt={1}
                          fontSize={12}
                        >
                          {e?.nompuit}
                        </Typography>
                        <Divider />
                        <Typography
                          style={{
                            fontSize: 15,
                            fontWeight: "bolder",
                            textAlign: "justify",
                            textDecoration: "underLine",
                          }}
                        >
                          Tag mine:
                        </Typography>
                        <Typography
                          textAlign={"justify"}
                          fontWeight={"bolder"}
                          color={"blue"}
                          // mt={1}
                          fontSize={12}
                        >
                          {e?.taget}
                        </Typography>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            p: 0.5,
                            borderRadius: 1,
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                textDecoration: "underLine",
                              }}
                            >
                              Quantité brute
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                color: "blue",
                              }}
                            >
                              {e?.quantitebruite}Kg
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                textDecoration: "underLine",
                              }}
                            >
                              Emballage:
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: "bolder",
                                textAlign: "justify",
                                color: "blue",
                              }}
                            >
                              {e?.embale.toUpperCase()}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          sx={{
                            p: 0.5,
                            mb: 1,
                            borderRadius: 1,
                            fontWeight: "bolder",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              textDecoration: "underLine",
                            }}
                          >
                            Quantité Arrivée:
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: "bolder",
                              textAlign: "justify",
                              color: "blue",
                            }}
                          >
                            {e?.quantitearrive}Kg
                          </Typography>
                        </Stack>

                        <Typography textAlign={"end"}>
                          <Chip
                            label={
                              e?.etat === true ? "En cours..." : "Arrivé..."
                            }
                            color={e?.etat === true ? "error" : "primary"}
                            sx={{
                              borderRadius: 2,
                            }}
                          />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => Update(e)}
                        >
                          Vente
                        </Button>
                      </CardActions>
                    </Card>
                  ))
              )}
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Stack>
        </div>
      )}

      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputVente
          title={title}
          dataUpdate={dataupdate}
          foncget={getDataVunerable}
          foncget2={getDataVenteProduct}
        />
      </Dialogs>
      <Dialogs
        title={title}
        open={opendialiguex}
        setOpen={setOpenDialoguex}
        size="sm"
      >
        <InputFraude
          title={title}
          dataUpdate={dataupdate}
          label={"Centre de negoce"}
        />
      </Dialogs>
    </div>
  );
}

export default Vente;
