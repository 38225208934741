import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import MiniDrawer from "../navigation/Drawers";
import { useLocation, useNavigate } from "react-router-dom";
// import { request2 } from "../url/request";
import { ArrowBack } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import { useReactToPrint } from "react-to-print";
import Info from "../../outher/Info";

function MesureDetail() {
  const localrouter = useLocation();
  const nav = useNavigate();
  const prints = useRef();

  // console.log(localrouter);

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: localrouter.state.itemv.titrem.toUpperCase(),
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div ref={prints}>
          <Info />
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            Mesure d'attenuation
          </h4>

          {/*
             <Divider sx={{ mt: 1, mb: 2 }} />
            <div
            style={{
              background: "#FFF",
              padding: 10,
              borderRadius: 5,
              margin: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Agent
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Typography
              sx={{
                //   fontWeight: "bold",
                fontSize: 18,
                textAlign: "center",
              }}
            >
              <Stack direction={"row"}>
                <div
                  style={{
                    //   marginTop: 80,
                    marginLeft: 20,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {localrouter.state.itemv.nomagent}
                  </Typography>
                </div>
              </Stack>
            </Typography>
          </div>
            */}
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid
            container
            sx={{
              bgcolor: "#FFF",
              p: 1,
              borderRadius: 2,
            }}
            direction={"column"}
            //   spacing={2}
          >
            <Typography
              sx={{
                fontSize: 20,
                textAlign: "justify",
                fontWeight: "bold",
                ml: 2,
                mt: 2,
              }}
            >
              Titre
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                textAlign: "justify",
                // fontWeight: "bold",
                ml: 2,
                // mt: 2,
              }}
            >
              {localrouter.state.itemv.titrem.toUpperCase()}
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                textAlign: "justify",
                fontWeight: "bold",
                ml: 2,
                mt: 2,
              }}
            >
              Brève descrition de la mesure
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                //   mt: 1,
                textAlign: "justify",
                ml: 2,
              }}
            >
              {localrouter.state.itemv.descryptionm}
            </Typography>
            {/*
            <Typography
            sx={{
              fontSize: 16,
              //   mt: 1,
              textAlign: "center",
            }}
          >
            {localrouter.state.itemv.datecreate}
          </Typography>
          <Divider sx={{ mt: 2 }} />
            */}

            <Grid item>
              <Stack
                containe
                direction={"column"}
                spacing={2}
                //   bgcolor={"red"}
                //   width={"100%"}
                mt={2}
              >
                <Grid item lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Partie responsable de la mesure
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpartiprenante.designaionp}`}
                  </Typography>
                </Grid>

                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Risque associé à la mesure
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.ttypevulnerabilite.designationv}`}
                  </Typography>
                </Grid>

                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Date
                  </Typography>
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.dateupdate}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"right"} mb={2} mt={2}>
          <Boutons title="Imprimer" onClick={() => Imprimer()} />
        </Grid>
      </Box>
    </Box>
  );
}

export default MesureDetail;
