import React from "react";
import { Typography } from "@mui/material";

function UploadAndDisplayImage2() {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [etat, setEta] = React.useState(false);

  const RemoveImage = () => {
    setSelectedImage(null);
    setEta(false);
  };

  const selectImage = (event) => {
    setSelectedImage(event.target.files[0]);
    setEta(true);
  };

  const UploadImage = () => {
    return (
      <div style={{ marginTop: -35, marginLeft: 20 }}>
        <Typography
          variant="body2"
          //   sx={{
          //     marginBottom: 3,
          //   }}
        >
          Selectionner l'image
        </Typography>

        {selectedImage && (
          <div>
            <img
              alt="not fount"
              style={{
                width: 200,
                height: 100,
                // borderRadius: 5,
                marginTop: 10,
                // marginBottom: 20,
              }}
              src={URL.createObjectURL(selectedImage)}
            />

            <br />
            <button onClick={() => RemoveImage()}>Supprimer L'Image</button>
          </div>
        )}
        {etat === false ? (
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              selectImage(event);
            }}
            style={{ marginTop: -50 }}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return {
    selectedImage,
    UploadImage,
  };
}

export default UploadAndDisplayImage2;
