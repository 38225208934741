import { Grid } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputRoleGouv({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  //
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designation: dataUpdate?.designation,
      idrole: dataUpdate?.idrole,
    },

    validationSchema: Yup.object({
      designation: Yup.string().required("Le champs est obligatoire"),
    }),
    onSubmit: (values) => {
      if (dataUpdate === "") {
        //enregistrement
        setEtat(true);
        request
          .post("rolegouv/save", values)
          .then((e) => {
            // console.log(e.data);
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      } else {
        //modification
        setEtat(true);
        request
          .put("rolegouv/update", values)
          .then((e) => {
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextFields
            value={validation.values.designation}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"designation"}
            error={
              validation.touched.designation && validation.errors.designation
                ? true
                : false
            }
            placeholder="Role"
          />

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputRoleGouv;
