import {
  DataThresholding,
  Diamond,
  Event,
  Home,
  Logout,
  Person2,
  PersonAdd,
  Settings,
  ShoppingBag,
} from "@mui/icons-material";

export const ItemDrawer = [
  {
    title: "Accueil",
    icon: (
      <Home
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "1",
    link: "/dashboard",
  },
  {
    title: "Production",
    icon: (
      <Diamond
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "12",
    link: "/production",
  },
  {
    title: "Entite de traitement",
    icon: (
      <ShoppingBag
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "121",
    link: "/achat",
  },
  {
    title: "Individus",
    icon: (
      <PersonAdd
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "2",
    link: "/victime",
  },
  {
    title: "Evènements",
    icon: (
      <Event
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "3",
    link: "/evenement",
  },
  {
    title: "Mesures",
    icon: (
      <DataThresholding
        fontSize="medium"
        sx={{
          color: "#FFF",
        }}
      />
    ),
    id: "4",
    link: "/mesure",
  },
//  {
  //  title: "Paramètres",
    //icon: (
      //<Settings
        //fontSize="medium"
       // sx={{
         // color: "#FFF",
        //}}
      // />
    //),
    //id: "5",
    // link: "/parametre",
    //subMenu: [
      //{
        //subtitle: "Agent de Gouv.",
        //link: "/agent-gouv",
        //id: "19",
      //},
      //{
        //subtitle: "Admin",
        //link: "/agent",
        //id: "9",
      //},
      //{
        //subtitle: "Entite de traitement",
        //link: "/entite-de-traitement",
        //id: "91",
     // },
      //{
        //subtitle: "Partie-Prenantes",
        //link: "/partie-prenate",
        //id: "1",
      //},
      //{
        //subtitle: "Risques",
        //link: "/type-Vulnerabilite",
       // id: "7",
      //},
      //{
       // subtitle: "Emplacements",
        //link: "/emplacemant",
       // id: "6",
      //},
      //{
        //subtitle: "Fraude",
        //link: "/fraude",
        //id: "60",
      //},
      //{
        //subtitle: "Types d'emplacements",
        //link: "/type-emplacement",
       // id: "3",
      //},
      //{
        //subtitle: "Types d'évènements",
        //link: "/type-evenement",
        //id: "4",
      //},

      //{
        //subtitle: "Province",
        //link: "/region",
       // id: "5",
      //},

      //{
        //subtitle: "Origines",
        //link: "/origine",
        //id: "2",
     // },
      //{
        //subtitle: "Imprimer",
        //link: "/imprimer",
        //id: "8",
      //},
    //],
  //},
];

export const datalogin = [
  {
    title: "Profil",
    icon: (
      <Person2
        fontSize="medium"
        sx={{
          color: "#000",
        }}
      />
    ),
    id: "1",
    link: "/Profil",
  },
  {
    title: "Déconnexion",
    icon: (
      <Logout
        fontSize="medium"
        sx={{
          color: "#000",
        }}
      />
    ),
    id: "2",
    link: "/",
  },
];
