import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import {
  Accessibility,
  Dangerous,
  DateRange,
  Description,
  Man,
  Man2,
  Map,
  Person,
  Phone,
  Save,
} from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { Form, useFormik } from "formik";
import PhoneNumbers from "../controller/PhoneNumber";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import UploadAndDisplayImage from "../controller/UploadImage";
import NotifySuccess from "../Notification/NotifySuccess";
import { requestFD } from "../url/requesFD";
var Spinkit = require("react-spinkit");

const datasexe = [
  { value: "Masculin", label: "Masculin" },
  { value: "Féminin", label: "Féminin" },
];

function InputVictime({ fonct, dataUpdate }) {
  // variable

  const [nom, setNom] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.tpersonne.nom
  );
  const [pnom, setPnom] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.tpersonne.postnom
  );
  const [prnom, setPrnom] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.tpersonne.prenom
  );
  const [dateN, setDateN] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.tpersonne.datenaiss
  );
  const [adress, setAdress] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.tpersonne.adresse
  );
  const [descr, setDscrp] = React.useState(
    dataUpdate === "" ? "" : dataUpdate.designationvul
  );
  //
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [phone, setPhone] = React.useState(
    dataUpdate ? dataUpdate.tpersonne.phone : ""
  );
  const [genre, setGenre] = React.useState("");
  //declaretion type vulnerabilte
  const [datatypevulnerable, setDataTypevulnerable] = React.useState([]);
  const [valutypevulnerabilite, setTypevulnerabilte] = React.useState();
  //declareation emplacement
  const [dataemplacement, setDataemplacement] = React.useState([]);
  const [valuteemlacement, setValueemplacement] = React.useState();
  // declaration origine
  const [dataorigine, setDataOrigine] = React.useState([]);
  const [valueorigine, setValueOrigine] = React.useState();

  // console.log("==>:", dataUpdate ? dataUpdate.tpersonne.idpersonne : "");

  //gert origine
  const getOrigine = () => {
    request
      .get("origine/active")
      .then((e) => {
        let item = e.data.data.map((ex) => {
          return {
            value: ex.idorigine,
            label: ex.designationor,
          };
        });
        setDataOrigine(item);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          setEtat(false);
        }
      });
  };

  //get emplacement
  const getCmplacement = () => {
    request
      .get("emplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          console.log(ex);
          return {
            value: ex.idemplacement,
            label: ex.designationsite,
          };
        });
        setDataemplacement(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get type type vulnerabilite
  const getTypeVulnerable = () => {
    request
      .get("typevunerabilite/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          return {
            value: ex.idtypevulnerabilite,
            label: ex.designationv,
          };
        });
        // console.log(itm);
        setDataTypevulnerable(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  React.useEffect(() => {
    getTypeVulnerable();
    getCmplacement();
    getOrigine();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: dataUpdate === "" ? "" : dataUpdate.tpersonne.prenom,
      postnom: dataUpdate === "" ? "" : dataUpdate.tpersonne.postnom,
      prenom: dataUpdate === "" ? "" : dataUpdate.tpersonne.prenom,
      adresse: dataUpdate === "" ? "" : dataUpdate.tpersonne.adresse,
      xdt: dataUpdate === "" ? "" : dataUpdate.tpersonne.datenaiss,
      description: dataUpdate === "" ? "" : dataUpdate.designationvul,
    },
    // validationSchema: Yup.object({
    //   nom: Yup.string().required("Ce champs est requis"),
    //   postnom: Yup.string().required("Ce champs est requis"),
    //   prenom: Yup.string().required("Ce champs est requis"),
    //   xdt: Yup.string().required("Ce champs est requis"),
    //   adresse: Yup.string().required("Ce champs est requis"),
    //   description: Yup.string().required("Ce champs est requis"),
    // }),

    onSubmit: (values) => {
      // console.log("==>:", values);
      const personefdata = new FormData();
      if (genre === "" || genre === undefined || genre === null) {
        NotifyError("genre est obligatoire");
      } else if (valutypevulnerabilite === undefined) {
        NotifyError("type de vunnerabilte est obligatoire");
      } else if (valuteemlacement === undefined) {
        NotifyError("l'emplacement est obligatoire");
      } else if (valueorigine === undefined) {
        NotifyError("origine est pbligatoire");
      } else {
        if (dataUpdate === "") {
          //nouvelle enregistrement
          personefdata.append("nom", nom);
          personefdata.append("postnom", pnom);
          personefdata.append("prenom", prnom);
          personefdata.append("adresse", adress);
          personefdata.append("datenaiss", dateN === "" ? "2000-01-01" : dateN);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          setEtat(true);
          requestFD
            .post("personne/save", personefdata)
            .then((e) => {
              // console.log(e.data.data);
              let jsonPerson = {
                idpersonne: e.data.data.idpersonne,
                designationvul: descr,
                idtypevulnerabilite: valutypevulnerabilite.value,
                idemplacement: valuteemlacement.value,
                idorigine: valueorigine.value,
              };
              request
                .post("vulnerable/save", jsonPerson)
                .then((ex) => {
                  NotifySuccess(ex.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.request) {
                    NotifyError(error.request);
                    setEtat(false);
                  } else if (error.response) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else if (error.config) {
                    NotifyError(error.config);
                    setEtat(false);
                  } else {
                    NotifyError(error.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          // modification;
          let idperso = dataUpdate.tpersonne.idpersonne;
          personefdata.append("nom", nom);
          personefdata.append("postnom", pnom);
          personefdata.append("prenom", prnom);
          personefdata.append("adresse", adress);
          personefdata.append("datenaiss", dateN === "" ? "2000-01-01" : dateN);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          personefdata.append("idpersonne", idperso);
          setEtat(true);
          requestFD
            .put("personne/update", personefdata)
            .then((e) => {
              // console.log(e.data.data);
              let jsonPerson = {
                idpersonne: idperso,
                designationvul: values.description,
                idtypevulnerabilite: valutypevulnerabilite.value,
                idemplacement: valuteemlacement.value,
                idorigine: valueorigine.value,
                // idpersonne
              };
              request
                .put("vulnerable/update", jsonPerson)
                .then((ex) => {
                  NotifySuccess(ex.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.request) {
                    NotifyError(error.request);
                    setEtat(false);
                  } else if (error.response) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else if (error.config) {
                    NotifyError(error.config);
                    setEtat(false);
                  } else {
                    NotifyError(error.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Stack
            sx={{
              mt: 5,
              mb: 5,
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {UploadImage()}
          </Stack>
          <Stack direction={"row"} spacing={5}>
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                Coordonnées de l'individu
              </Typography>
              <Divider
                sx={{
                  m: 1,
                }}
              />
              <Stack direction={"row"} spacing={2}>
                <div>
                  <TextFields
                    // label="Nom"
                    placeholder="Nom"
                    // id={"nom"}
                    // onBlur={validation.handleBlur}
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    error={
                      validation.touched.nom && validation.errors.nom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.nom && validation.errors.nom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.nom}
                    </h6>
                  ) : null}
                </div>
                <div>
                  <TextFields
                    // label="Postnom"
                    placeholder="Post-nom"
                    // id={"postnom"}
                    onChange={(e) => setPnom(e.target.value)}
                    // onBlur={validation.handleBlur}
                    value={pnom}
                    error={
                      validation.touched.postnom &&
                      validation.errors.postnom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.postnom && validation.errors.postnom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.postnom}
                    </h6>
                  ) : null}
                </div>
              </Stack>
              <div>
                <TextFields
                  // label="Prenom"
                  placeholder="Prénom"
                  // id={"prenom"}
                  // onBlur={validation.handleBlur}
                  onChange={(e) => setPrnom(e.target.value)}
                  value={validation.values.prenom}
                  error={
                    validation.touched.prenom && validation.errors.prenom
                      ? true
                      : false
                  }
                  icons={
                    <Person
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.prenom && validation.errors.prenom ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.prenom}
                  </h6>
                ) : null}
              </div>
              <div>
                <Comboboxs
                  value={genre}
                  onChange={(e) => setGenre(e)}
                  itcos={
                    <Man2
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  data={datasexe}
                  placeholder="Genre"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <PhoneNumbers value={phone} onChange={(e) => setPhone(e)} />
              </div>
              <div>
                <TextFields
                  // id={"xdt"}
                  // onBlur={validation.handleBlur}
                  onChange={(e) => setDateN(e.target.value)}
                  value={dateN}
                  error={
                    validation.touched.xdt && validation.errors.xdt
                      ? true
                      : false
                  }
                  placeholder="Date de naissance"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.xdt && validation.errors.xdt ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.xdt}
                  </h6>
                ) : null}
              </div>
              <div>
                <TextFields
                  // label="Adresse"
                  // id="adresse"
                  // onBlur={validation.handleBlur}
                  onChange={(e) => setAdress(e.target.value)}
                  value={adress}
                  error={
                    validation.touched.adresse && validation.errors.adresse
                      ? true
                      : false
                  }
                  placeholder="Adresse Physique"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.adresse && validation.errors.adresse ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.adresse}
                  </h6>
                ) : null}
              </div>
            </div>
            <Divider
              sx={{
                borderWidth: 1,
              }}
            />
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              ></Typography>
              <Divider
                sx={{
                  m: 1,
                }}
              />
              <div>
                <Comboboxs
                  itcos={
                    <Dangerous
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  data={datatypevulnerable}
                  value={valutypevulnerabilite}
                  onChange={(e) => setTypevulnerabilte(e)}
                  placeholder="Risque lié à l'individu"
                />
              </div>
              <div>
                <Comboboxs
                  itcos={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  value={valuteemlacement}
                  onChange={(e) => setValueemplacement(e)}
                  data={dataemplacement}
                  placeholder="Emplacement"
                />
              </div>
              <div>
                <Comboboxs
                  itcos={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  value={valueorigine}
                  onChange={(e) => setValueOrigine(e)}
                  data={dataorigine}
                  placeholder="Origine"
                />
              </div>
              <div>
                <TextFields
                  // label="Description"
                  placeholder="Descrisption"
                  // id={"description"}
                  multiline={true}
                  value={descr}
                  // onBlur={validation.handleBlur}
                  onChange={(e) => setDscrp(e.target.value)}
                  error={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                  icons={
                    <Description
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.description}
                  </h6>
                ) : null}
              </div>
            </div>
          </Stack>
          <Stack
            sx={{
              // width: "93%",
              mt: 2,
              // ml: 5,
            }}
          >
            {dataUpdate === "" ? (
              <Boutons
                type="submit"
                icons={
                  etat === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title="Enregistrer"
                // onClick={() => Login()}
              />
            ) : (
              <Boutons
                type="submit"
                icons={
                  etat === true ? (
                    <Spinkit color="#FFF" name="ball-beat" size={18} />
                  ) : (
                    <Save
                      sx={{
                        mr: 2,
                      }}
                    />
                  )
                }
                title="Update"
                disable={etat}
                // onClick={() => Login()}
              />
            )}
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputVictime;
