import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotifyError from "../../Notification/NotifyError";
import { request } from "../../url/request";
import { IconButton, Stack } from "@mui/material";
import { Edit } from "@mui/icons-material";

function useCentreNegoce() {
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  // const [aff, setAffi] = useState(false);
  const [datax, setDatax] = useState(null);
  const [labelbton, setLabelBton] = useState("Voir Liste de Vente");
  const [opendialiguex, setOpenDialoguex] = React.useState(false);

  //get Data vendu production
  const getDataVenteProduct = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("vente")
      .then((e) => {
        // console.log(e.data.data);
        let itm = e.data.data
          .filter((itemx) =>
            searchQuery.toUpperCase() === null ||
            searchQuery.toUpperCase() === "" ||
            searchQuery.toUpperCase() === undefined
              ? itemx
              : itemx?.tnegocieant?.tpersonne?.prenom
                  .toUpperCase()
                  .indexOf(searchQuery.toUpperCase()) > -1 ||
                itemx?.tnegocieant?.tpersonne?.postnom
                  .toUpperCase()
                  .indexOf(searchQuery.toUpperCase()) > -1
          )
          .map((e) => {
            return {
              identite:
                e?.tnegocieant?.tpersonne?.prenom +
                " " +
                e?.tnegocieant?.tpersonne?.postnom,
              minerai:
                e?.tproduction?.tpontsiteminetypemine?.tminerai?.designation,
              quantite: e?.quantitevendu,
              prix: e?.prixvente + "$",
              date: e?.datacreate,
              action: (
                <Stack direction={"row"} spacing={0}>
                  <IconButton onClick={() => nouveauVictiome(e)}>
                    <Edit color="info" />
                  </IconButton>
                </Stack>
              ),
            };
          });
        setDatax(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  const nouveauVictiome = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  React.useEffect(() => {
    getDataVenteProduct();
  }, []);

  return {
    data,
    setData,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
    setDataUpdate,
    nav,
    etat,
    setEtat,
    etatv,
    setEtatV,
    etatcheckconn,
    setEtatChecConn,
    datax,
    setDatax,
    labelbton,
    setLabelBton,
    opendialiguex,
    setOpenDialoguex,
    nouveauVictiome,
    getDataVenteProduct,
  };
}

export default useCentreNegoce;
