import { Box, Stack } from "@mui/material";
import React from "react";
import Select from "react-select";

export default function Comboboxs({
  icons,
  data,
  placeholder,
  value,
  onChange,
  label,
}) {
  return (
    <Box
      width={"100%"}
      // border={2}
      // borderColor={"GrayText"}
      // borderRadius={2}
      sx={{ display: "flex", alignItems: "flex-end" }}
    >
      {icons}
      <Stack
        sx={{
          mt: 2,
          width: "100%",
          // background: "red",
        }}
        direction={"row"}
        spacing={0}
      >
        {
          // props.itcos
        }
        <div
          style={{
            width: "100%",
          }}
        >
          <p
            style={{
              color: "gray",
              // fontSize: 14,
              marginTop: -15,
              marginBottom: -5,
              // fontFamily: "Montserrat",
            }}
          >
            {label}
          </p>
          <Select
            options={data}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            isSearchable
            error={true}
            position="top"
            styles={{
              option: (styles) => {
                return {
                  ...styles,
                  color: "#000",
                  fontSize: 18,
                  backgroundColor: "#FFF",
                };
              },
            }}
          />
        </div>
      </Stack>
    </Box>
  );
}
