import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import { useFormik } from "formik";
import * as Yup from "yup";
import Boutons from "../controller/Boutons";
import NotifySuccess from "../Notification/NotifySuccess";
import { Save } from "@mui/icons-material";
var Spinkit = require("react-spinkit");

function InputPuits({ foncget, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const [datasite, setDataSite] = React.useState([]);
  const [labelSite, setLabelSite] = React.useState("");
  //get emplacement
  const getEmplacement = () => {
    request
      .get("emplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idemplacement,
            label: ex.designationsite,
          };
        });
        setDataSite(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  useEffect(() => {
    getEmplacement();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designation: dataUpdate?.designation,
      fkidemplencements: labelSite.value
        ? labelSite.value
        : dataUpdate?.fkidemplencements,
      idpuits: dataUpdate?.idpuits,
    },

    validationSchema: Yup.object({
      designation: Yup.string().required("le nom du puits est obligatoire"),
    }),
    onSubmit: (values) => {
      //   console.log("====>", values);
      if (dataUpdate === "") {
        if (labelSite === "" || labelSite === null || labelSite === undefined) {
          NotifyError("Type d'emplacement est obligatoire");
        } else {
          //enregistrement
          setEtat(true);
          request
            .post("puit/save", values)
            .then((e) => {
              if (e.status === 200) {
                foncget();
                NotifySuccess(e.data.message);
                setEtat(false);
              }
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      } else {
        //modification
        setEtat(true);
        request
          .put("puit/update", values)
          .then((e) => {
            if (e.status === 200) {
              foncget();
              NotifySuccess(e.data.message);
              setEtat(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              NotifyError(error.request);
              setEtat(false);
            } else if (error.response) {
              NotifyError(error.response.data.message);
              setEtat(false);
            } else if (error.config) {
              NotifyError(error.config);
              setEtat(false);
            } else {
              NotifyError(error.message);
              setEtat(false);
            }
          });
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={"auto"} md={12} sm={"auto"} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Comboboxs
            data={datasite}
            value={labelSite}
            onChange={(e) => setLabelSite(e)}
            placeholder="Source"
          />
          <TextFields
            value={validation.values.designation}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            id={"designation"}
            error={
              validation.touched.designation && validation.errors.designation
                ? true
                : false
            }
            placeholder="Designation type emplacement"
          />
          <p
            style={{
              fontSize: 9,
              color: "red",
              marginTop: -1,
            }}
          >
            {validation.touched.designation && validation.errors.designation
              ? validation.errors.designation
              : false}
          </p>

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Boutons
              type={"submit"}
              icons={
                etat === true ? (
                  <Spinkit color="#FFF" name="ball-beat" size={18} />
                ) : (
                  <Save
                    sx={{
                      mr: 2,
                    }}
                  />
                )
              }
              title={dataUpdate === "" ? "Enregistrer" : "Modification"}
              disable={etat}
            />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputPuits;
