import "./App.css";
import Router from "./components/router/indexs";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div>
      <Router />
      <ToastContainer />
    </div>
  );
}

export default App;
