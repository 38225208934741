import React, { useState } from "react";
import { request } from "../../url/request";
import NotifyError from "../../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";

function useVente() {
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const [etat, setEtat] = React.useState(false);
  const [data, setData] = React.useState([]);
  const nav = useNavigate();
  const [datax, setDatax] = useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");

  //
  const getDataVunerable = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .post("production/vente")
      .then((e) => {
        // console.log("data use cnsole==>:)", e.data.data);
        const uniqueData = e.data.data.reduce((acc, current) => {
          if (!acc.find((item) => item.idproduction === current.idproduction)) {
            acc.push(current);
          }
          return acc;
        }, []);
        setData(uniqueData);
        setEtat(true);
        setEtatChecConn(false);
        console.log(e.data.data);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  //vente liste action
  const getDataVenteProduct = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("vente")
      .then((e) => {
        // console.log(e.data.data);
        const uniqueData = e.data.data.reduce((acc, current) => {
          if (
            !acc.find((item) => item.idcentrenegoce === current.idcentrenegoce)
          ) {
            acc.push(current);
          }
          return acc;
        }, []);
        let itm = uniqueData.map((e) => {
          return {
            identite:
              e?.tnegocieant?.tpersonne?.prenom +
              " " +
              e?.tnegocieant?.tpersonne?.postnom,
            minerai:
              e?.tproduction?.tpontsiteminetypemine?.tminerai?.designation,
            quantite: e?.quantitevendu,
            prix: e?.prixvente + "$",
            titretat: e?.titretat,
            fraude: e.tages,
            date: e?.datacreate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton
                  color="success"
                  size="medium"
                  onClick={() => nouveauVictiome(e)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  color="warning"
                  size="medium"
                  onClick={() => Visibiliys(e)}
                >
                  <Visibility />
                </IconButton>
              </Stack>
            ),
          };
        });
        setDatax(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  React.useEffect(() => {
    getDataVenteProduct();
  }, []);

  const dataFiltre = datax.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : (itemx &&
          itemx.identite &&
          itemx.identite.toUpperCase().includes(searchQuery.toUpperCase())) ||
        itemx.minerai.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauVictiome = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  const Visibiliys = (e) => {
    nav("/chiane-de-la-production", {
      state: {
        itemv: e,
      },
    });
  };

  React.useEffect(() => {
    getDataVunerable();
  }, []);

  return {
    getDataVunerable,
    data,
    etat,
    etatcheckconn,
    datax,
    dataFiltre,
    getDataVenteProduct,
    etat,
    opendialigue,
    setOpenDialogue,
    searchQuery,
    setSearchQuery,
    title,
    setTitle,
    dataupdate,
  };
}

export default useVente;
