import {
  Alert,
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import InputAgent from "./InputAgent";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
import useMessage from "../hooks/message/useMessage";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Personne impliquée
      </Typography>
    ),
    selector: (row) => row.identite,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Minerais
      </Typography>
    ),
    selector: (row) => row.nommine,
    sortable: true,
  },
  // {
  //   name: (
  //     <Typography
  //       sx={{
  //         fontSize: 14,
  //         fontWeight: "bold",
  //       }}
  //     >
  //       Site
  //     </Typography>
  //   ),
  //   selector: (row) => row.nomsitemine,
  //   sortable: true,
  // },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Message
      </Typography>
    ),
    selector: (row) => (
      <Typography style={{ fontWeight: "bolder" }}>
        {row.description}
      </Typography>
    ),
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Statut
      </Typography>
    ),
    // selector: (row) => row.etat?<Chip,
    cell: (row) => {
      return (
        <Chip
          label={row?.etat === true ? "non lu" : "lu"}
          color={row?.etat === true ? "error" : "info"}
          sx={{
            borderRadius: 2,
            width: "100%",
          }}
        />
      );
    },
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.datecreate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Message() {
  document.title = "Message | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);
  const { data: dataMProduction, datar, datac } = useMessage();

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
  };

  const getDataVunerable = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .post("production/getallmessage")
      .then((e) => {
        // console.log(e.data.data);
        let itm = e.data.data
          .filter((itemx) =>
            searchQuery.toUpperCase() === null ||
            searchQuery.toUpperCase() === "" ||
            searchQuery.toUpperCase() === undefined
              ? itemx
              : itemx.tpersonne.prenom
                  .toUpperCase()
                  .indexOf(searchQuery.toUpperCase()) > -1 ||
                itemx.tpersonne.postnom
                  .toUpperCase()
                  .indexOf(searchQuery.toUpperCase()) > -1
          )
          .map((e) => {
            return {
              identite: e?.tpersonne.prenom + " " + e.tpersonne.postnom,

              nommine: e?.nommine,
              // nomsitemine: e?.nomsitemine,
              description: e?.typemessage,
              etat: e?.etat,
              datecreate: e?.datecreate,
              action: (
                <Stack direction={"row"} spacing={0}>
                  <IconButton onClick={() => GotoDetailMessage(e)}>
                    <Visibility color="error" />
                  </IconButton>
                </Stack>
              ),
            };
          });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  const GotoDetailMessage = (e) => {
    nav("/detail-message", {
      state: {
        item: e,
      },
    });
  };

  React.useEffect(() => {
    getDataVunerable();
  }, [searchQuery]);

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Message
        </h4>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Patientez</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Alert icon={false} severity="info">
                Secteur de la production
                <Divider
                  style={{
                    margin: 5,
                  }}
                />
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <div>Général :{dataMProduction?.data}</div>
                  <div
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    vérifié :{dataMProduction?.verifie}
                  </div>
                  <div>Non vérifié :{dataMProduction?.nonverify}</div>
                </Stack>
              </Alert>
              <Alert icon={false} severity="error">
                Secteur de réception de production
                <Divider
                  style={{
                    margin: 5,
                  }}
                />
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <div>Général :{datar?.data}</div>
                  <div>vérifié :{datar?.verifie}</div>
                  <div>Non vérifié :{datar?.nonverify}</div>
                </Stack>
              </Alert>
              <Alert icon={false} severity="warning">
                Secteur de vente centre de Negoce
                <Divider
                  style={{
                    margin: 5,
                  }}
                />
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <div>Général :{datac?.data}</div>
                  <div>vérifié :{datac?.verifie}</div>
                  <div>Non vérifié :{datac?.nonverify}</div>
                </Stack>
              </Alert>
            </Stack>
            <Mtables title="Message" data={data} columns={column} />
          </Stack>
        )}
        <Dialogs
          title={title}
          open={opendialigue}
          setOpen={setOpenDialogue}
          size="md"
        >
          <InputAgent dataUpdate={dataupdate} fonct={getDataVunerable} />
        </Dialogs>
      </Box>
    </Box>
  );
}

export default Message;
