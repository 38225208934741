import {
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
// import { useNavigate } from "react-router-dom";
import InputOrigine from "./InputOrigine";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Designation
      </Typography>
    ),
    selector: (row) => row.designation,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Status
      </Typography>
    ),
    // selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      //   console.log(row);
      return (
        <Stack direction={"row"} spacing={1}>
          <Switch
            checked={row.status.etatorigine === true ? true : false}
            // onChange={() => ChangeTable()}
            inputProps={{
              "aria-label": "controlled",
            }}
            color={row.status.etatorigine === true ? "primary" : "warning"}
          />
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date de la création
      </Typography>
    ),
    selector: (row) => row.datecreate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date modification
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Origine() {
  document.title = "Origine | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [dataupdate, setDataUpdate] = React.useState("");
  //   const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e);
    // console.log(e);
  };

  const getDataOrigine = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("origine")
      .then((e) => {
        // console.log(e.data);
        let itm = e.data.data.map((e) => {
          return {
            designation: e.designationor,
            status: e,
            datecreate: e.datecreate,
            date: e.dateupdate,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton onClick={() => Update(e)}>
                  <Edit color="info" />
                </IconButton>

                <IconButton onClick={() => ConfirmInformation(e)}>
                  <Delete color="error" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          setEtat(false);
          setEtatChecConn(true);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  React.useEffect(() => {
    getDataOrigine();
  }, []);

  const dataFiltrage = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : itemx &&
        itemx.designation &&
        itemx.designation.toUpperCase().includes(searchQuery.toUpperCase())
  );

  const nouveauVictiome = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Nouveau");
  };

  const Update = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };

  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("origine/delete", {
        idorigine: dataupdate.idorigine,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getDataOrigine();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Origine
        </h4>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Veuillez patiente</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              bgcolor: "#FFF",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <div>
              <Boutons
                onClick={() => nouveauVictiome()}
                icons={
                  <Add
                    sx={{
                      mr: 2,
                    }}
                  />
                }
                title="Nouvelle Origine"
              />
            </div>
            {etatv === false ? (
              ""
            ) : (
              <Stack
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E3EAEF",
                  marginTop: 20,
                  borderRadius: 5,
                }}
              >
                <Typography fontWeight={"bold"} fontSize={20}>
                  Information
                </Typography>
                <Divider />
                <Typography fontWeight={"bold"} fontSize={16}>
                  Etes-vous d'effectuer cette action
                </Typography>
                <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                  <IconButton
                    sx={{
                      bgcolor: "#013266",
                      ":hover": {
                        bgcolor: "#1976D2",
                      },
                    }}
                    onClick={() => ValidationDataUpdateEtat()}
                  >
                    <Check
                      sx={{
                        color: "#FFF",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      bgcolor: "red",
                      ":hover": {
                        bgcolor: "#1976D2",
                      },
                    }}
                    onClick={() => setEtatV(!etatv)}
                  >
                    <Close
                      sx={{
                        color: "#FFF",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                </Stack>
              </Stack>
            )}
            <Stack direction={"row"} justifyContent={"space-between"}>
              <h1></h1>
              <div>
                <TextFields
                  value={searchQuery}
                  onChange={(e) => {
                    Recherche(e.target.value);
                  }}
                  icons={
                    <Search
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                    />
                  }
                  placeholder="search..."
                />
              </div>
            </Stack>
            <Mtables
              title="Liste des victimes"
              data={dataFiltrage}
              columns={column}
            />
          </Stack>
        )}

        <Dialogs
          title={title}
          open={opendialigue}
          setOpen={setOpenDialogue}
          size="md"
        >
          <InputOrigine dataUpdate={dataupdate} foncget={getDataOrigine} />
        </Dialogs>
      </Box>
    </Box>
  );
}

export default Origine;
