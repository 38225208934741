import { Box, Stack } from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import FullWidthTabs from "../controller/TabNavigation";
var Spink = require("react-spinkit");

function Region() {
  document.title = "Region | EFFICACITY SYSTEM";

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: 25,
          }}
        >
          Paramettrez les Régions
        </h4>

        <Stack
          sx={{
            bgcolor: "#FFF",
            p: 3,
            mt: 2,
            // ml: -1,
          }}
        >
          <FullWidthTabs />
        </Stack>
      </Box>
    </Box>
  );
}

export default Region;
