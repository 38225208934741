import { Grid, Stack } from "@mui/material";
import React from "react";
import TextFields from "../controller/TextFields";
import { Dangerous, Description, Map, Person, Save } from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { useFormik } from "formik";
import NotifySuccess from "../Notification/NotifySuccess";
import UploadAndDisplayImage from "../controller/UploadImage";
import { requestFD } from "../url/requesFD";
var Spinkit = require("react-spinkit");

function InputEmplacement({ fonct, dataUpdate }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [etat, setEtat] = React.useState(false);
  //declaretion type vulnerabilte
  const [datatypeemplacement, setDataTypeEmplcament] = React.useState([]);
  const [valutypeemplacement, setValueTypeEmplcament] = React.useState();

  //declareation emplacement
  const [datateritoire, setDataTeritoire] = React.useState([]);
  const [valuteritoire, setValueTeritoire] = React.useState();

  //declaration de la partie prenante
  const [datapartieprenante, setDataPartiePrenante] = React.useState([]);
  const [valuepartieprenant, setValuePartiePrenante] = React.useState("");

  const getTypeEmplacement = () => {
    request
      .get("typeemplacement/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          //   console.log(ex);
          return {
            value: ex.idtypeemplacement,
            label: ex.designationtype,
          };
        });
        setDataTypeEmplcament(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  //get partie prenante
  const getPartiePrenante = () => {
    request
      .get("partieprenante")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          console.log(ex);
          return {
            value: ex.idpartieprenante,
            label: ex.designaionp,
          };
        });
        setDataPartiePrenante(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };
  //get territoire
  const getTerritoire = () => {
    request
      .get("territoire/active")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          console.log(ex);
          return {
            value: ex.idteritoire,
            label: ex.designationter,
          };
        });
        setDataTeritoire(itm);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
        }
      });
  };

  React.useEffect(() => {
    getPartiePrenante();
    getTypeEmplacement();
    getTerritoire();
    // console.log("itmdata: ", imtmdatalogin);
  }, []);

  const valudation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designationsite: dataUpdate?.designationsite,
      logitude: dataUpdate?.logitude,
      latitule: dataUpdate?.latitule,
      idemplacement: dataUpdate?.idemplacement,
      idpartieprenante: valuepartieprenant ? valuepartieprenant.value : "",
      idteritoire: valuteritoire ? valuteritoire.value : "",
      idtypeemplacement: valutypeemplacement ? valutypeemplacement.value : "",
      type_mine: dataUpdate?.type_mine,
      nombre_creuseur: dataUpdate?.nombre_creuseur,
      nobre_travailleur: dataUpdate?.nobre_travailleur,
      nombrepuit: dataUpdate?.nombrepuit,
      chefferie: dataUpdate?.chefferie,
    },
    validationSchema: Yup.object({
      designationsite: Yup.string().required("Le nom du site est obligatoire"),
      // type_mine: Yup.string().required("Type miner est obligatoire"),
    }),
    onSubmit: (value) => {
      const formdata = new FormData();
      if (
        valutypeemplacement === "" ||
        valutypeemplacement === null ||
        valutypeemplacement === undefined
      ) {
        NotifyError("Type d'emplacement est obligatoire");
      } else if (
        valuteritoire === "" ||
        valuteritoire === null ||
        valuteritoire === undefined
      ) {
        NotifyError("Le territoire est obligatoire");
      } else if (
        valuepartieprenant === "" ||
        valuepartieprenant === null ||
        valuepartieprenant === undefined
      ) {
        NotifyError("La partie prenante est obligatoire");
      } else {
        // enregistrement
        if (dataUpdate === "") {
          setEtat(true);
          formdata.append("designationsite", value.designationsite);
          formdata.append("logitude", value.logitude);
          formdata.append("latitule", value.latitule);
          formdata.append("idemplacement", value.idemplacement);
          formdata.append("idpartieprenante", valuepartieprenant.value);
          formdata.append("idteritoire", valuteritoire.value);
          formdata.append("idtypeemplacement", valutypeemplacement.value);
          formdata.append("type_mine", "-");
          formdata.append("nombre_creuseur", value.nombre_creuseur);
          formdata.append("nobre_travailleur", value.nobre_travailleur);
          formdata.append("nombrepuit", value.nombrepuit);
          formdata.append("chefferie", value.chefferie);
          formdata.append("file", selectedImage);

          requestFD
            .post("emplacement/save", formdata)
            .then((e) => {
              NotifySuccess(e.data.message);
              fonct();
              setEtat(false);
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        } else {
          //modification
          setEtat(true);
          formdata.append("designationsite", value.designationsite);
          formdata.append("logitude", value.logitude);
          formdata.append("latitule", value.latitule);
          formdata.append("idemplacement", dataUpdate?.idemplacement);
          formdata.append("idpartieprenante", valuepartieprenant.value);
          formdata.append("idteritoire", valuteritoire.value);
          formdata.append("idtypeemplacement", valutypeemplacement.value);
          formdata.append("type_mine", "-");
          formdata.append("nombre_creuseur", value.nombre_creuseur);
          formdata.append("nobre_travailleur", value.nobre_travailleur);
          formdata.append("nombrepuit", value.nombrepuit);
          formdata.append("chefferie", value.chefferie);
          formdata.append("file", selectedImage);
          requestFD
            .put("emplacement/update", formdata)
            .then((e) => {
              NotifySuccess(e.data.message);
              fonct();
              setEtat(false);
            })
            .catch((error) => {
              if (error.request) {
                NotifyError(error.request);
                setEtat(false);
              } else if (error.response) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else if (error.config) {
                NotifyError(error.config);
                setEtat(false);
              } else {
                NotifyError(error.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          valudation.handleSubmit();
          return false;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid item md={12} sm={12} lg={12}>
          <Stack
            sx={{
              mt: 5,
              // mb: 0.2,
            }}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            {UploadImage()}
          </Stack>
          <Grid container>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <Stack direction={"row"} spacing={2}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Comboboxs
                    data={datatypeemplacement}
                    value={valutypeemplacement}
                    onChange={(e) => setValueTypeEmplcament(e)}
                    itcos={
                      <Dangerous
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                    placeholder="Type d'emplacement"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Comboboxs
                    placeholder="Territoire"
                    value={valuteritoire}
                    onChange={(e) => setValueTeritoire(e)}
                    data={datateritoire}
                    itcos={
                      <Map
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
              </Stack>
              <Comboboxs
                itcos={
                  <Dangerous
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
                placeholder="Partie-Prenante"
                value={valuepartieprenant}
                onChange={(e) => setValuePartiePrenante(e)}
                data={datapartieprenante}
              />

              <TextFields
                value={valudation.values.designationsite}
                id={"designationsite"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.designationsite &&
                  valudation.errors.designationsite
                    ? true
                    : false
                }
                placeholder="Nom de l'emplacement"
                label={"Site"}
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <p
                style={{
                  fontSize: 9,
                  color: "red",
                  marginTop: -1,
                }}
              >
                {valudation.touched.designationsite &&
                valudation.errors.designationsite
                  ? valudation.errors.designationsite
                  : false}
              </p>
              <Stack direction={"row"} spacing={2}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    value={valudation.values.logitude}
                    id={"logitude"}
                    onChange={valudation.handleChange}
                    onBlur={valudation.handleBlur}
                    error={
                      valudation.touched.logitude && valudation.errors.logitude
                        ? true
                        : false
                    }
                    // multiline={true}
                    placeholder="Longitude"
                    icons={
                      <Description
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    id={"latitule"}
                    value={valudation.values.latitule}
                    onChange={valudation.handleChange}
                    onBlur={valudation.handleBlur}
                    error={
                      valudation.touched.latitule && valudation.errors.latitule
                        ? true
                        : false
                    }
                    placeholder="Latitude"
                    // multiline={true}
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} md={5} lg={5} ml={5} mt={-1}>
              {/*
                
                <TextFields
                value={valudation.values.type_mine}
                id={"type_mine"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.type_mine && valudation.errors.type_mine
                    ? true
                    : false
                }
                // multiline={true}
                placeholder="mine extrait"
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
                */}
              <TextFields
                value={valudation.values.nombre_creuseur}
                id={"nombre_creuseur"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.nombre_creuseur &&
                  valudation.errors.nombre_creuseur
                    ? true
                    : false
                }
                // multiline={true}
                placeholder="Nombre des creuseurs"
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <Stack direction={"row"} spacing={2}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    value={valudation.values.nombrepuit}
                    id={"nombrepuit"}
                    onChange={valudation.handleChange}
                    onBlur={valudation.handleBlur}
                    error={
                      valudation.touched.nombrepuit &&
                      valudation.errors.nombrepuit
                        ? true
                        : false
                    }
                    // multiline={true}
                    placeholder="Nombre des puits"
                    icons={
                      <Description
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    id={"chefferie"}
                    value={valudation.values.chefferie}
                    onChange={valudation.handleChange}
                    onBlur={valudation.handleBlur}
                    error={
                      valudation.touched.chefferie &&
                      valudation.errors.chefferie
                        ? true
                        : false
                    }
                    placeholder="Chefferie"
                    // multiline={true}
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                </div>
              </Stack>
              <TextFields
                value={valudation.values.nobre_travailleur}
                id={"nobre_travailleur"}
                onChange={valudation.handleChange}
                onBlur={valudation.handleBlur}
                error={
                  valudation.touched.nobre_travailleur &&
                  valudation.errors.nobre_travailleur
                    ? true
                    : false
                }
                // multiline={true}
                placeholder="Nombre des travailleurs"
                icons={
                  <Description
                    fontSize="large"
                    sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                  />
                }
              />
              <Stack
                sx={{
                  // width: "93%",
                  mt: 2,
                  // ml: 5,
                }}
              >
                {dataUpdate === "" ? (
                  <Boutons
                    type={"submit"}
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Enregistrer"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                ) : (
                  <Boutons
                    type={"submit"}
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Modification"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default InputEmplacement;
