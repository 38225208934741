import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";

function PhoneNumbers(props) {
  return (
    <PhoneInput
      onBlur={props.onBlur}
      id={props.id}
      country={"cd"}
      value={props.value}
      onChange={props.onChange}
      {...(props.error && { error: true, heelperText: props.error })}
      inputStyle={{
        width: "100%",
      }}
    />
  );
}

export default PhoneNumbers;
