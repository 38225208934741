import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import MiniDrawer from "../navigation/Drawers";
import { useLocation, useNavigate } from "react-router-dom";
import { request2 } from "../url/request";
import { ArrowBack } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import { useReactToPrint } from "react-to-print";
import Info from "../../outher/Info";

function EvenemenetDetaille() {
  const localrouter = useLocation();
  const nav = useNavigate();
  const prints = useRef();

  const Imprimer = useReactToPrint({
    content: () => prints.current,
    documentTitle: localrouter.state.itemv.titreev.toUpperCase(),
    // onBeforePrint: () => setAff("contents"),
    // onAfterPrint: () => setAff("none"),
  });

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <div>
          <IconButton
            size="medium"
            onClick={() => nav(-1)}
            sx={{
              background: "#013266",
            }}
          >
            <ArrowBack
              fontSize="medium"
              sx={{
                color: "#FFF",
              }}
            />
          </IconButton>
        </div>
        <div ref={prints}>
        {/*  <Info />*/}
          <h4
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            Evenement
          </h4>
          {localrouter.state.itemv.timage === null ? (
            ""
          ) : (
            <Stack
              sx={{
                mt: -2,
                bgcolor: "#FFF",
                p: 2,
              }}
              direction={"column"}
              // justifyContent={"center"}
              // alignItems={"center"}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  textAlign: "justify",
                  fontWeight: "bold",
                  // ml: 2,
                  // mt: 2,
                }}
              >
                Image de l'évènement
              </Typography>
              <Divider sx={{ m: 2 }} />
              <img
                src={`${request2}${localrouter.state.itemv.timage.image}`}
                style={{
                  width: "70%",
                  height: 300,
                }}
                onResize={"contain"}
                alt="image"
              />
            </Stack>
          )}

          {/*
          <Divider sx={{ m: 2 }} />
            <div
            style={{
              background: "#FFF",
              padding: 10,
              borderRadius: 5,
              margin: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Agent
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Typography
              sx={{
                //   fontWeight: "bold",
                fontSize: 18,
                textAlign: "center",
              }}
            >
              <Stack direction={"row"}>
                <div
                  style={{
                    position: "absolute",
                    marginLeft: "2%",
                  }}
                >
                  <img
                    src={`${request2}${localrouter.state.itemv.tagent.tpersonne.image}`}
                    style={{
                      width: 200,
                      height: 200,
                      borderRadius: "100%",
                      borderColor: "black",
                      borderWidth: 5,
                    }}
                    onResize={"contain"}
                    alt
                  />
                </div>
                <div
                  style={{
                    marginTop: 50,
                    marginLeft: "30%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {localrouter.state.itemv.tagent.tpersonne.nom.toUpperCase()}
                    -
                    {localrouter.state.itemv.tagent.tpersonne.postnom.toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {localrouter.state.itemv.tagent.tpersonne.phone}
                  </Typography>
                </div>
              </Stack>
            </Typography>
          </div>

            */}
          <Grid
            container
            sx={{
              bgcolor: "#FFF",
              p: 1,
              borderRadius: 2,
              mt: 5,
              mb: 2,
            }}
            direction={"column"}
            //   spacing={2}
          >
            {/*
            <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              // mt: -5,
              textAlign: "center",
            }}
          >
            Titre
          </Typography>
          <Divider sx={{ mt: 1 }} />
            */}
            <Divider sx={{ m: 2 }} />
            <Typography
              sx={{
                fontSize: 16,
                textAlign: "justify",
                fontWeight: "bold",
                ml: 2,
                mt: 2,
              }}
            >
              Titre de l'évènement
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                textAlign: "justify",
                // fontWeight: "bold",
                ml: 2,
                mb: 3,
              }}
            >
              {localrouter.state.itemv.titreev.toUpperCase()}
            </Typography>
            <Divider />
            <Typography
              sx={{
                fontSize: 16,
                textAlign: "justify",
                fontWeight: "bold",
                ml: 2,
                // mt: 2,
              }}
            >
              Brève descrisption de l'évènement
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                //   mt: 1,
                textAlign: "justify",
                m: 2,
              }}
            >
              {localrouter.state.itemv.descryptionev}
            </Typography>

            <Grid item>
              <Stack
                containe
                direction={"column"}
                spacing={2}
                //   bgcolor={"red"}
                //   width={"100%"}
              >
                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                    mt={2}
                  >
                    Type d'évenement
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.ttypeevenement.designationtypeev}`}
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Risque Associé à l'évenement
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.ttypevulnerabilite.designationv}`}
                  </Typography>
                </Grid>

                <Grid item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Partie prenante impliquée
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.tpartiprenante.designaionp}`}
                  </Typography>
                </Grid>
                <Grid mt={2} item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Lieu de l'évènement
                  </Typography>
                  <Divider />
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.templacement.designationsite}`}
                  </Typography>
                </Grid>

                <Grid mt={2} item md={3} sm={12} lg={12}>
                  <Typography
                    textAlign={"justify"}
                    fontSize={16}
                    fontWeight={"bold"}
                    ml={2}
                  >
                    Date de l'évènement
                  </Typography>
                  <Typography ml={2} textAlign={"justify"} fontSize={16}>
                    {`${localrouter.state.itemv.date}`}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"right"} mb={2}>
          <Boutons title="Imprimer" onClick={() => Imprimer()} />
        </Grid>
      </Box>
    </Box>
  );
}

export default EvenemenetDetaille;
