import { Popover } from "@mui/material";
import React from "react";

export default function Popovers({ open, setOpen, children, ref }) {
  return (
    <Popover
      ref={ref}
      id="simple-popover"
      open={open}
      onClose={setOpen}
      anchorEl={open}
      anchorOrigin={{
        vertical: "top", //bottom
        horizontal: "right", //left
      }}
      sx={{
        mt: 7,
        mr: 20,
        // background: "#013266",
      }}
      elevation={0}
    >
      {children}
    </Popover>
  );
}
