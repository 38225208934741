import React, { useEffect } from "react";
import { request } from "../../url/request";
import NotifyError from "../../Notification/NotifyError";

function useMinerai() {
  const [datamine, setDataMine] = React.useState([]);

  //get minerai
  const getMinerai = () => {
    request
      .get("mine")
      .then((e) => {
        let itm = e.data.data.map((ex) => {
          // console.log(ex);
          return {
            value: ex.idminerai,
            label: ex.designation,
          };
        });
        setDataMine(itm);
      })
      .catch((error) => {
        if (error.code) {
          NotifyError(error.message);
          // setDisable(false);
          // setEtat(false);
        } else {
          NotifyError(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getMinerai();
  }, []);

  return {
    datamine,
    setDataMine,
  };
}

export default useMinerai;
