import axios from "axios";

const urlbase = "https://api.efficacity-system.net/";

const requestFD = axios.create({
  baseURL: urlbase,
  timeout: 5000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export { requestFD };
