import { NetworkCell, NetworkCheck } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";

function ErrorConnection() {
  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <NetworkCheck sx={{ color: "#013266" }} fontSize="large" />
      <Typography fontSize={20} fontWeight={"bold"}>
        Error de la connextion
      </Typography>
      <Typography>
        Veuillez Verifier si vous êtes belle bien connecter, après veuillez
        actualiser la pasge svp
      </Typography>
    </Grid>
  );
}

export default ErrorConnection;
