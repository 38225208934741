import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFields from "../controller/TextFields";
import {
  Accessibility,
  Dangerous,
  DateRange,
  Description,
  Man,
  Man2,
  Map,
  Person,
  Phone,
  Save,
} from "@mui/icons-material";
import Comboboxs from "../controller/Comboboxs";
import Boutons from "../controller/Boutons";
import * as Yup from "yup";
import { Form, useFormik } from "formik";
import PhoneNumbers from "../controller/PhoneNumber";
import { request } from "../url/request";
import NotifyError from "../Notification/NotifyError";
import UploadAndDisplayImage from "../controller/UploadImage";
import NotifySuccess from "../Notification/NotifySuccess";
import { requestFD } from "../url/requesFD";
var Spinkit = require("react-spinkit");

const datasexe = [
  { value: "Masculin", label: "Masculin" },
  { value: "Féminin", label: "Féminin" },
];

function InputNegociant({ fonct, dataUpdate }) {
  const [etat, setEtat] = React.useState(false);
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const [phone, setPhone] = React.useState(
    dataUpdate ? dataUpdate.tpersonne.phone : ""
  );
  const [genre, setGenre] = React.useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: dataUpdate === "" ? "" : dataUpdate.tpersonne.prenom,
      postnom: dataUpdate === "" ? "" : dataUpdate.tpersonne.postnom,
      prenom: dataUpdate === "" ? "" : dataUpdate.tpersonne.prenom,
      adresse: dataUpdate === "" ? "" : dataUpdate.tpersonne.adresse,
      xdt: dataUpdate === "" ? "" : dataUpdate.tpersonne.datenaiss,
      description: dataUpdate === "" ? "" : dataUpdate.fonction,
      pwd: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Ce champs est requis"),
      postnom: Yup.string().required("Ce champs est requis"),
    }),

    onSubmit: (values) => {
      // console.log("==>:", values);
      const personefdata = new FormData();
      if (genre === "" || genre === undefined || genre === null) {
        NotifyError("genre est obligatoire");
      } else {
        if (dataUpdate === "") {
          //nouvelle enregistrement
          personefdata.append("nom", values.nom);
          personefdata.append("postnom", values.postnom);
          personefdata.append("prenom", values.prenom);
          personefdata.append("adresse", values.adresse);
          personefdata.append("datenaiss", values.xdt);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          setEtat(true);
          requestFD
            .post("personne/save", personefdata)
            .then((e) => {
              //   console.log("===>", e.data.data.idpersonne);
              let jsonPerson = {
                idpersonne: e.data.data.idpersonne,
              };
              request
                .post("negociant/save", jsonPerson)
                .then((ex) => {
                  NotifySuccess(ex.data.message);
                  fonct();
                  setEtat(false);
                })
                .catch((error) => {
                  if (error.code) {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  } else {
                    NotifyError(error.response.data.message);
                    setEtat(false);
                  }
                });
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.response.data.message);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        } else {
          // modification;
          let idperso = dataUpdate.tpersonne.idpersonne;
          personefdata.append("nom", values.nom);
          personefdata.append("postnom", values.postnom);
          personefdata.append("prenom", values.prenom);
          personefdata.append("adresse", values.adresse);
          personefdata.append("datenaiss", values.xdt);
          personefdata.append("phone", phone);
          personefdata.append("genre", genre.value);
          personefdata.append("file", selectedImage);
          personefdata.append("idpersonne", idperso);
          setEtat(true);
          requestFD
            .put("personne/update", personefdata)
            .then((e) => {
              NotifySuccess(e.data.message);
              fonct();
              setEtat(false);
              // console.log(e.data.data);
              //   let jsonPerson = {
              //     idpersonne: idperso,
              //     fonction: values.description,
              //     password: values.pwd,
              //     idpartieprenate: labelcooperative.value,
              //   };
              //   request
              //     .put("individue/update", jsonPerson)
              //     .then((ex) => {
              //       NotifySuccess(ex.data.message);
              //       fonct();
              //       setEtat(false);
              //     })
              //     .catch((error) => {
              //       if (error.code) {
              //         NotifyError(error.response.data.message);
              //         // setDisable(false);
              //         setEtat(false);
              //       } else {
              //         NotifyError(error.response.data.message);
              //         setEtat(false);
              //       }
              //     });
            })
            .catch((error) => {
              if (error.code) {
                NotifyError(error.message);
                // setDisable(false);
                setEtat(false);
              } else {
                NotifyError(error.response.data.message);
                setEtat(false);
              }
            });
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Stack
            sx={{
              mt: 5,
              mb: 1,
            }}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            {UploadImage()}
          </Stack>
          <Stack direction={"column"}>
            <div
              style={{
                width: "100%",
              }}
            >
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    // label="Nom"
                    placeholder="nom"
                    id={"nom"}
                    onBlur={validation.handleBlur}
                    value={validation.values.nom}
                    onChange={validation.handleChange}
                    error={
                      validation.touched.nom && validation.errors.nom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.nom && validation.errors.nom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        // marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.nom}
                    </h6>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFields
                    // label="Postnom"
                    placeholder="postnom"
                    id={"postnom"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.postnom}
                    error={
                      validation.touched.postnom &&
                      validation.errors.postnom ? (
                        <h6 type="invalid">{validation.errors.nom}</h6>
                      ) : null
                    }
                    icons={
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                      />
                    }
                  />
                  {validation.touched.postnom && validation.errors.postnom ? (
                    <h6
                      style={{
                        marginTop: -2,
                        color: "red",
                        // marginLeft: "30%",
                        marginBottom: -5,
                      }}
                    >
                      {validation.errors.postnom}
                    </h6>
                  ) : null}
                </div>
              </Stack>
              <div>
                <TextFields
                  // label="Prenom"
                  placeholder="prenom"
                  id={"prenom"}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.prenom}
                  error={
                    validation.touched.prenom && validation.errors.prenom
                      ? true
                      : false
                  }
                  icons={
                    <Person
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.prenom && validation.errors.prenom ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.prenom}
                  </h6>
                ) : null}
              </div>
              <div>
                <Comboboxs
                  value={genre}
                  onChange={(e) => setGenre(e)}
                  itcos={
                    <Man2
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                  data={datasexe}
                  placeholder="genre"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <PhoneNumbers value={phone} onChange={(e) => setPhone(e)} />
              </div>
              <div>
                <TextFields
                  id={"xdt"}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.xdt}
                  error={
                    validation.touched.xdt && validation.errors.xdt
                      ? true
                      : false
                  }
                  placeholder="Date de naissance"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.xdt && validation.errors.xdt ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.xdt}
                  </h6>
                ) : null}
              </div>
              <div>
                <TextFields
                  // label="Adresse"
                  id="adresse"
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values.adresse}
                  error={
                    validation.touched.adresse && validation.errors.adresse
                      ? true
                      : false
                  }
                  placeholder="adresse"
                  icons={
                    <Map
                      fontSize="large"
                      sx={{ mr: 1, my: 0.5, color: "#409EFF" }}
                    />
                  }
                />
                {validation.touched.adresse && validation.errors.adresse ? (
                  <h6
                    style={{
                      marginTop: -2,
                      color: "red",
                      marginLeft: "10%",
                      marginBottom: -5,
                    }}
                  >
                    {validation.errors.adresse}
                  </h6>
                ) : null}
              </div>
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  // width: "93%",
                  mt: 2,
                  // ml: 5,
                }}
              >
                {dataUpdate === "" ? (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Enregistrer"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                ) : (
                  <Boutons
                    type="submit"
                    icons={
                      etat === true ? (
                        <Spinkit color="#FFF" name="ball-beat" size={18} />
                      ) : (
                        <Save
                          sx={{
                            mr: 2,
                          }}
                        />
                      )
                    }
                    title="Update"
                    disable={etat}
                    // onClick={() => Login()}
                  />
                )}
              </Stack>
            </div>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default InputNegociant;
